import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/Membership.css";
import { Link } from "react-router-dom";
import MembershipEditMOdal from "../Components/MembershipEditModal"
import MembershipEditMOdalfs from "../Components/MembershipEditModalfs"
import MembershipEditMOdalts from "../Components/MembershipEditModalts"
import MembershipEditMOdalgp from "../Components/MembershipEditModalgp"
import { useGetmembershipdataceQuery, useGetmembershipdatafsQuery, useGetmembershipdatatsQuery} from "../Services/UsersApi";
import { useGetGroupsessionQuery } from "../Services/SecondaryApi";

function Membership() {
  const [showMembershipMOdal, setShowmembershipMOdal] = useState(false)
  const [showMembershipMOdalfs, setShowmembershipMOdalfs] = useState(false)
  const [showMembershipMOdalts, setShowmembershipMOdalts] = useState(false)
  const [showMembershipMOdalgp, setShowmembershipMOdalgp] = useState(false)
  const { data:ce , refetch:refetchce} = useGetmembershipdataceQuery();
  const { data:fs, refetch:refetchfs } = useGetmembershipdatafsQuery();
  const { data:ts, refetch:refetchts } = useGetmembershipdatatsQuery();
  const { data:gp, refetch:refetchgp } = useGetGroupsessionQuery();

  const handleShowEditModal = ()=>{
    setShowmembershipMOdal(true);
  }
  const handleCloseEditMOdal= ()=>{
    setShowmembershipMOdal(false);
  }

  const handleShowEditModalfs = ()=>{
    setShowmembershipMOdalfs(true);
  }
  const handleCloseEditMOdalfs= ()=>{
    setShowmembershipMOdalfs(false);
  }

  const handleShowEditModalts = ()=>{
    setShowmembershipMOdalts(true);
  }
  const handleCloseEditMOdalts= ()=>{
    setShowmembershipMOdalts(false);
  }

  const handleShowEditModalgp = ()=>{
    setShowmembershipMOdalgp(true);
  }
  const handleCloseEditMOdalgp= ()=>{
    setShowmembershipMOdalgp(false);
  }

  return (
    <div>
      <h3>Memberships</h3>
      <div className="membership-div">
        <h5>Listing</h5>
        <div className="table-div">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" className="w-50">Cafe Entrepreneurship</th>
                <th scope="col" className="w-30">Price</th>
                <th cope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Lite</td>
                <td>Free</td>
                <td>
                  {/* <button className="btn-outline" onClick={handleShowEditModal}>Edit</button>{" "} */}
                </td>
              </tr>
              <tr>
                <td>Pro</td>
                <td>${ce?.amount}</td>
                <td>
                  <button className="btn-outline" onClick={handleShowEditModal}
                  >Edit</button>{" "}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" className="w-50">Foodservice</th>
                <th scope="col" className="w-30">Price</th>
                <th cope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pro</td>
                <td>${fs?.amount}</td>
                <td>
                  <button className="btn-outline" 
                  onClick={handleShowEditModalfs}
                  >Edit</button>{" "}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" className="w-50">Trades & services</th>
                <th scope="col" className="w-30">Price</th>
                <th cope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pro</td>
                <td>${ts?.amount}</td>
                <td>
                  <button className="btn-outline"
                   onClick={handleShowEditModalts}
                  >Edit</button>{" "}
                </td>
              </tr>
            </tbody>
          </table>
          <h5>Mentorship</h5>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" className="w-50">Group sessions</th>
                <th scope="col" className="w-30">Price</th>
                <th cope="col"></th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>Lite</td>
                {gp && gp.length > 0 ? (
                 <td>
                  ${gp[0].lite_price}  
                 </td>
                 ) : (
                 <td>No price available</td>  
                    )}

                <td>
                  <button className="btn-outline"
                  onClick={handleShowEditModalgp}
                  >
                    Edit
                    </button>{" "}
                </td>
              </tr>
              <tr>
                <td>Pro</td>
                <td>Free</td>
                <td>
                  {/* <button className="btn-outline">Edit</button>{" "} */}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" className="w-50">1:1 session</th>
                <th scope="col" className="w-30">Price</th>
                <th cope="col"></th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>Lite</td>
                <td>$119.99</td>
                <td>
                  {/* <button className="btn-outline">Edit</button>{" "} */}
                </td>
              </tr>
              <tr>
                <td>Pro</td>
                <td>$79.99</td>
                <td>
                  {/* <button className="btn-outline">Edit</button>{" "} */}
                </td>
              </tr>
            </tbody>
          </table>
          <MembershipEditMOdal show={showMembershipMOdal} handleCloseEditMOdal={handleCloseEditMOdal}  refetch={refetchce}/>
          <MembershipEditMOdalfs show={showMembershipMOdalfs} handleCloseEditMOdalfs={handleCloseEditMOdalfs}  refetch={refetchfs}/>
          <MembershipEditMOdalts show={showMembershipMOdalts} handleCloseEditMOdalts={handleCloseEditMOdalts}  refetch={refetchts}/>
          <MembershipEditMOdalgp show={showMembershipMOdalgp} handleCloseEditMOdalgp={handleCloseEditMOdalgp}  refetch={refetchgp}/>
        </div>
      </div>
    </div>
  );
}

export default Membership;
