import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import "../Styles/RoleManagement.css";
import "../Styles/Artcle.css";
import { useAddHuddlesessionMutation } from "../Services/SecondaryApi";
import Swal from 'sweetalert2';

function AddTopic({ show, handleCloseAddHuddleSessionModal, refetch }) {
  const [formData, setFormData] = useState({
    title: "",
    host_name: "",
    description: "",
    date: "",
    time: "",
    meeting_link: "",
  });

  const [addHuddle] = useAddHuddlesessionMutation();
  const [isLoading, setIsLoading] = useState(false); // New loading state

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
    
  //   setIsLoading(true); // Start loading when form is submitted

  //   try {
  //     const formattedDateTime = `${formData.date} ${formData.time}`;
      
  //     const response = await addHuddle({
  //       session_title: formData.title,
  //       host_name: formData.host_name,
  //       description: formData.description,
  //       formatted_date_time: formattedDateTime,
  //       meeting_link: formData.meeting_link,
  //       date: formData.date,
  //       time: formData.time,
  //     });

  //     if (response?.data?.message === "Huddle session created successfully!") {
  //       alert("Huddle session added successfully!");
  //       refetch();
  //       setFormData({
  //         title: "",
  //         host_name: "",
  //         description: "",
  //         date: "",
  //         time: "",
  //         meeting_link: "",
  //       });

  //       handleCloseAddHuddleSessionModal(); // Close the modal
  //     } else {
  //       alert("Failed to add huddle session.");
  //       refetch();
  //     }
  //   } catch (error) {
  //     console.error("Error adding huddle session:", error);
  //     alert("An error occurred. Please try again.");
  //   } finally {
  //     setIsLoading(false); // Stop loading after response
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setIsLoading(true); // Start loading when form is submitted
  
    try {
      // Create a new Date object using the date and time from formData
      const dateTime = new Date(`${formData.date}T${formData.time}`);
      
      // Format the time as h:i A
      const formattedTime = dateTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
      
      const formattedDateTime = `${formData.date} ${formattedTime}`; // Combine formatted date and time
      
      const response = await addHuddle({
        session_title: formData.title,
        host_name: formData.host_name,
        description: formData.description,
        formatted_date_time: formattedDateTime, // Submit formatted date and time
        meeting_link: formData.meeting_link,
        date: formData.date,
        time: formattedTime, // Submit formatted time in h:i A
      });
  
      if (response?.data?.message === "Huddle session created successfully!") {
        // alert("Huddle session added successfully!");
        Swal.fire({
          icon: 'success',
          title: 'Submission Successful!',
          text: 'Huddle session added successfully!',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
        refetch();
      });
        setFormData({
          title: "",
          host_name: "",
          description: "",
          date: "",
          time: "",
          meeting_link: "",
        });
  
        handleCloseAddHuddleSessionModal(); // Close the modal
      } else {
        // alert("Failed to add huddle session.");
        Swal.fire({
          icon: 'error',
          title: 'Submission Failed',
          text: 'Failed to add huddle session.',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
        refetch();
      });
      }
    } catch (error) {
      // console.error("Error adding huddle session:", error);
      // alert("An error occurred. Please try again.");
      Swal.fire({
        icon: 'error',
        title: 'Submission Failed',
        text: 'An error occurred. Please try again.',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    } finally {
      setIsLoading(false); // Stop loading after response
    }
  };
  

  return (
    <div>
      <Modal
        show={show}
        onHide={handleCloseAddHuddleSessionModal}
        backdrop="true"
        dialogClassName="add-new-modal"
      >
        <Modal.Body>
          <h4>Add Huddle Session</h4>
          <p>
            Please be careful to check all details, including times, dates,
            links, and content before setting the session.
          </p>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <div className="form-control">
                  <label>
                    Session title <span className="red-star">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    name="title"
                    placeholder="Enter title"
                    value={formData.title}
                    onChange={handleChange}
                  />
                </div>
              </Col>

              <Col xs={12}>
                <div className="form-control">
                  <label>
                    Host name<span className="red-star">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    name="host_name"
                    placeholder="Enter host name"
                    value={formData.host_name}
                    onChange={handleChange}
                  />
                </div>
              </Col>

              <Col xs={12}>
                <div className="form-control">
                  <label>
                    Description <span className="red-star">*</span>
                  </label>
                  <br />
                  <textarea
                    placeholder="Add your description"
                    rows={3}
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                  <p className="description-allow-text">
                    Maximum of 200 words
                  </p>
                </div>
              </Col>

              <Col xs={12} md={6}>
                <div className="form-control">
                  <label>
                    Date<span className="red-star">*</span>
                  </label>
                  <br />
                  <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                  />
                </div>
              </Col>

              <Col xs={12} md={6}>
                <div className="form-control">
                  <label>
                    Time<span className="red-star">*</span>
                  </label>
                  <br />
                  <input
                    type="time"
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                  />
                </div>
              </Col>

              <Col xs={12}>
                <div className="form-control">
                  <label>
                    Meeting link<span className="red-star">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    name="meeting_link"
                    placeholder="http://"
                    value={formData.meeting_link}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <div className="btn-action">
                <button
                  type="button"
                  className="btn-outline-black"
                  onClick={handleCloseAddHuddleSessionModal}
                  disabled={isLoading} // Disable button when loading
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={isLoading} // Disable button when loading
                >
                  {isLoading ? "Adding..." : "Add"} {/* Change button text */}
                </button>
              </div>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddTopic;
