import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_SUPPORT_SERVER;

export const supportapi = createApi({
  reducerPath: 'supportapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState, endpointName }) => {
      const token = localStorage.getItem('AdminAccessToken');
      const noAuthEndpoints = [''];

      // Add token only if it's not a no-auth endpoint
      if (token && !noAuthEndpoints.includes(endpointName)) {
        headers.set('authorization', `Bearer ${token}`);
      }
    
      return headers;
    },
  }),
  endpoints: (builder) => ({
    
    getSupportcount: builder.query({
      query: () => `api/tickets-counts`,
    }),
    getSupporttickets: builder.query({
        query: ({selectedStatus, page}) => {
          const baseUrl = `api/all-tickets?page=${page}`;
          const queryParams = selectedStatus ? `&status=${selectedStatus}` : "";
          
          return `${baseUrl}${queryParams}`;
        },
      }),
      getTicketdetailsdisplay: builder.query({
        query: (id) => `api/view-ticket/${id}`,
      }),
      deleteUserbyTicketId: builder.mutation({
        query: (user_id) => ({
          url: `api/delete-ticket/${user_id}`,
          method: 'DELETE',
        }),
      }),

  }),
});

export const {
  useGetSupportcountQuery,useGetSupportticketsQuery,useGetTicketdetailsdisplayQuery, useDeleteUserbyTicketIdMutation,
} = supportapi;
