import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/Login.css";
import { useSuperAdminLoginMutation } from "../Services/AdminApi";
import { useNavigate } from "react-router-dom";

function Login() {
  // const [formData, setFormData] = useState({
  //   email: "",
  //   password: "",
  // });
  // const [errors, setErrors] = useState({});
  // const navigate = useNavigate();

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: "",
  //   }));
  //   setFormData({
  //     ...formData,
  //     [name]: type === "checkbox" ? checked : value,
  //   });
  // };

  // const validateForm = () => {
  //   const formErrors = {};
  //   if (!formData.email) formErrors.email = "Email is required.";
  //   if (!formData.password) formErrors.password = "Password is required.";

  //   setErrors(formErrors);
  //   return Object.keys(formErrors).length === 0;
  // };

  // const [submitForm, { isLoading, isError, isSuccess, error }] = useSubmitLoginMutation();

  // const handleSubmit = async (e) => {
  //   console.log("Form submission initiated");
  //   const un = "serveradmin";
  //   e.preventDefault();
  
  //   if (validateForm()) {
  //     console.log("Form validation passed");
  
  //     const postData = {
  //       email: formData.email,
  //       password: formData.password,
  //       username: un,
  //     };
  
  //     console.log("Post data:", postData);
  
  //     try {
  //       const response = await submitForm(postData).unwrap();
  //       console.log("API Response:", response);
  
  //       if (response) {
  //         alert("Login successful!");
  //         localStorage.setItem("AdminAccessToken", response.access_token);
  
  //         setFormData({
  //           email: "",
  //           password: "",
  //         });
  
  //         navigate("/user-cafe");
  //       }
  //     } catch (err) {
  //       console.error("Failed to submit form:", err);
  //       console.error("Full error:", JSON.stringify(err, null, 2));
  //     }
  //   }
  // };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const formErrors = {};
    if (!formData.email) formErrors.email = "Email is required.";
    if (!formData.password) formErrors.password = "Paasword is required.";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validateForm) {
  //   }
  // };

  const [submitForm, { isLoading, isError, isSuccess, error }] = useSuperAdminLoginMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let postData = {
        email:formData.email,
        password:formData.password,
      }
      try {
        const response = await submitForm(postData).unwrap();
        localStorage.setItem("AdminAccessToken", response.access_token);
       
        setFormData({
          email: "",
          password: "",
        });
        
        const timer = setTimeout(() => {
          navigate("/user-cafe");
        }, 1000);
      } catch (err) {
        console.error("Failed to submit form:", err);
      }

    }
  };
  

  return (
    <div className="login">
      <Row>
        <Col xs={12} md={5} sm={12} lg={5}>
          <div className="login-bg"></div>
        </Col>
        <Col xs={12} md={7} sm={12} lg={7} className="left-div">
          <div className="login-form">
            <h3>Welcome</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-control">
                <label>Email</label>
                <br />
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email id"
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="form-control">
                <label>Password</label>
                <br />
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                />
                {errors.password && <p className="error">{errors.password}</p>}
              </div>
              <div className="form-control">
                <button type="submit" className="login-submit-btn">
                  Login
                </button>
              </div>
              {isError && (
                <p style={{ color: "red" }}>
                  Error: {error?.data?.error || "Login Failed"}
                </p>
              )}
              {isSuccess && (
                <p style={{ color: "green" }} className="isSuceesMsg">
                  Login Successful!
                </p>
              )}
              {isLoading && <div className="loader"></div>}
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
