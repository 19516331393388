import React from "react";
import "../Styles/Ecosystemstatistics.css";
import { Row, Col } from "react-bootstrap";
import AlluserImg from "../Assets/Images/statistics-alluser-img.svg";
import CafeImg from "../Assets/Images/statisctics-cafe.svg"
import FoodImg from "../Assets/Images/statistics-food.svg"
import TradeImg from "../Assets/Images/statistics-trade.svg"
import { useGetUserStatisticsQuery } from "../Services/UsersApi";
import { useGetHuddleStatisticsQuery } from "../Services/SecondaryApi";
import { useGetSupportcountQuery } from "../Services/SupportApi";

function EcosystemStatistics() {
  const { data:count, isLoading, isError } = useGetUserStatisticsQuery();
  const { data:huddleCount } = useGetHuddleStatisticsQuery();
  const { data:supportCount } = useGetSupportcountQuery();
  return (
    <div className="ecosysystem-statistics">
      <h4>Users</h4>
      <Row>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">All Users</p>
                <p className="count">{count?.all_user_counts}</p>
              </div>
              <img src={AlluserImg} alt="AlluserImg" />
            </div>
          </div>
        </Col>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Cafe 
                Entrepreneurship</p>
                <p className="count">{count?.ce_user_counts}</p>
              </div>
              <img src={CafeImg} alt="CafeImg" />
            </div>
          </div>
        </Col>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Foodservice</p>
                <p className="count">{count?.fs_user_counts}</p>
              </div>
              <img src={FoodImg} alt="FoodImg" />
            </div>
          </div>
        </Col>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Trades & Services</p>
                <p className="count">{count?.ts_user_counts}</p>
              </div>
              <img src={TradeImg} alt="TradeImg" />
            </div>
          </div>
        </Col>
      </Row>

      <h4>Listings</h4>
      <Row>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">All Listings</p>
                <p className="count">{count?.all_listing}</p>
              </div>
              <img src={AlluserImg} alt="AlluserImg" />
            </div>
          </div>
        </Col>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Cafe 
                Entrepreneurship</p>
                <p className="count">{count?.ce_branch_count}</p>
              </div>
              <img src={CafeImg} alt="CafeImg" />
            </div>
          </div>
        </Col>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Foodservice</p>
                <p className="count">{count?.fs_branch_count}</p>
              </div>
              <img src={FoodImg} alt="FoodImg" />
            </div>
          </div>
        </Col>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Trades & Services</p>
                <p className="count">{count?.ts_branch_count}</p>
              </div>
              <img src={TradeImg} alt="TradeImg" />
            </div>
          </div>
        </Col>
      </Row>



      <h4>Huddle Sesssion</h4>
      <Row>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Created</p>
                <p className="count">{huddleCount?.huddle_session_created_count}</p>
              </div>
              {/* <img src={AlluserImg} alt="AlluserImg" /> */}
            </div>
          </div>
        </Col>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Conducted</p>
                <p className="count">{huddleCount?.past_huddle_session_count}</p>
              </div>
              {/* <img src={CafeImg} alt="CafeImg" /> */}
            </div>
          </div>
        </Col>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Accepted</p>
                <p className="count">{huddleCount?.user_accepted_invitations_count}</p>
              </div>
              {/* <img src={FoodImg} alt="FoodImg" /> */}
            </div>
          </div>
        </Col>
        {/* <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Uploaded</p>
                <p className="count">{huddleCount?.business_material_records_uploaded_count}</p>
              </div>
            </div>
          </div>
        </Col> */}
      </Row>
      
      
      <h4>Ecosystem matches</h4>
      <Row>
        <Col sm={12} lg={4} md={4}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Cafe 
                Entrepreneurship</p>
                <p className="count">{count?.cafe_eco_matches_count}</p>
              </div>
              <img src={CafeImg} alt="CafeImg" />
            </div>
          </div>
        </Col>
        <Col sm={12} lg={4} md={4}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Foodservice</p>
                <p className="count">{count?.food_eco_matches_count}</p>
              </div>
              <img src={FoodImg} alt="FoodImg" />
            </div>
          </div>
        </Col>
        <Col sm={12} lg={4} md={4}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Trades & Services</p>
                <p className="count">{count?.trade_eco_matches_count}</p>
              </div>
              <img src={TradeImg} alt="TradeImg" />
            </div>
          </div>
        </Col>
      </Row>

      <h4>Enquiries</h4>
      <Row>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Total</p>
                <p className="count">{count?.trade_eco_matches_count + count?.food_eco_matches_count + count?.cafe_eco_matches_count }</p>
              </div>
              <img src={AlluserImg} alt="AlluserImg" />
            </div>
          </div>
        </Col>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Cafe Entrepreneurship</p>
                <p className="count">{count?.cafe_eco_matches_count}</p>
              </div>
              <img src={CafeImg} alt="CafeImg" />
            </div>
          </div>
        </Col>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Foodservice</p>
                <p className="count">{count?.food_eco_matches_count}</p>
              </div>
              <img src={FoodImg} alt="FoodImg" />
            </div>
          </div>
        </Col>
        <Col sm={12} lg={3} md={3}>
          <div className="statistics-card">
            <div className="d-flex align-items-space-between">
              <div>
                <p className="category">Trades & Services</p>
                <p className="count">{count?.trade_eco_matches_count}</p>
              </div>
              <img src={TradeImg} alt="TradeImg" />
            </div>
          </div>
        </Col>
      </Row>


    </div>
  );
}

export default EcosystemStatistics;
