
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_CORE_SERVER;

export const usersapi = createApi({
  reducerPath: 'usersapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('AdminAccessToken');
      // console.log('Access Token:', token); 
      const noAuthEndpoints = ['submitLogin','getOperationalstatus'];

      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitLogin: builder.mutation({
        query: (newPost) => ({
          url: 'api/admin/login',
          method: 'POST',
          body: newPost,
        }),
      }),

      getAllcausers: builder.query({
  query: ({ page, searchTerm, startDate, endDate , selectedStatus}) =>  `external/ce-user/list/branch-type-count/?page=${page}&search=${searchTerm || ''}&start_date=${startDate || ''}&end_date=${endDate || ''}&subscription_type=${selectedStatus}`, // Ensure default page
}),

      getAllfsusers: builder.query({
        query: ({ page, searchTerm ,startDate, endDate, selectedStatus}) => `external/fs-user/list/branch-type-count/?page=${page}&search=${searchTerm || ''}&start_date=${startDate || ''}&end_date=${endDate || ''}&subscription_type=${selectedStatus}`,
      }),
      getAlltsusers: builder.query({
        query: ({ page, searchTerm ,startDate, endDate, selectedStatus}) => `external/ts-user/list/branch-type-count/?page=${page}&search=${searchTerm || ''}&start_date=${startDate || ''}&end_date=${endDate || ''}&subscription_type=${selectedStatus}`,
      }),
       getMediagallery: builder.query({
        query: () => `external/ts-user/list/branch-type-count/`,
      }),
      getMediagalleryts: builder.query({
        query: ({selectedStatus,searchTerm}) => `external/ts-media-gallery/?status=${selectedStatus}&search=${searchTerm}`,
      }),
      getMediagalleryfs: builder.query({
        query: ({selectedStatus,searchTerm}) => `external/fs-media-gallery/?status=${selectedStatus}&search=${searchTerm}`,
      }),
      getAlllistingswithstate: builder.query({
        query: ({id,selectedStatus}) => `external/ce-user/${id}/branches/${selectedStatus ? `?subscription_type=${selectedStatus}` : ''}`,
      }),
      getflagshiplistingswithuserdetails: builder.query({
        query: ({id}) => `/external/ce-user/${id}/flagship-branch/`,
      }),
      getcafeuserdetails: builder.query({
        query: ({id}) => `external/ce-user/branch/${id}/detail/`,
      }),
      getfooduserdetails: builder.query({
        query: ({id}) => `external/fs-user/branch/${id}/detail/`,
      }),

      gettradeuserdetails: builder.query({
        query: ({id}) => `external/ts-user/branch/${id}/detail/`,
      }),

      approveFsmediaGallery: builder.mutation({
        query: ({ id, status }) => ({
          url: `external/fs-media-gallery/${id}/`, 
          method: 'PATCH',  
          body: { status },  
        })
      }),
      approveTsmediaGallery: builder.mutation({
        query: ({ id, status }) => ({
          url: `external/ts-media-gallery/${id}/`, 
          method: 'PATCH',  
          body: { status },  
        }),
      }),
      getAllcalistings: builder.query({
        query: ({ page, searchTerm ,selectedStatus}) => `external/ce-branches/?page=${page}&subscription_type=${selectedStatus}&search=${searchTerm || ''}`,
        // query: () => `external/ce-branches/`,
      }), 
      getSelectAllcalistings: builder.query({
        query: () => `external/ce-branches/without-pagination/`,
        // query: () => `external/ce-branches/`,
      }), 
      getAllfslistings: builder.query({
        query: ({ page, searchTerm ,selectedStatus }) => `external/fs-branches/?page=${page}&subscription_type=${selectedStatus}&search=${searchTerm || ''}`,
      }),  
      getAlltslistings: builder.query({
        query: ({ page, searchTerm ,selectedStatus }) => `external/ts-branches/?page=${page}&subscription_type=${selectedStatus}&search=${searchTerm || ''}`,
      }),
      getUserStatistics: builder.query({
        query: () => `external/counts/`,
      }), 
      getUsertypes: builder.query({
        query: () => `external/user-types/`,
      }), 
      getUseraccounttypes: builder.query({
        query: () => `external/account-types/`,
      }), 
      getOperationalstatus: builder.query({
        query: () => `common/ce-operational-status/`,
      }),
      notificationfromadmin: builder.mutation({
        query: (notificationData) => ({
          url: `external/notifications/`, 
          method: 'POST',  
          body: notificationData ,  
        }),
      }),
      getmembershipdatace: builder.query({
        query: () => `stripe/ce/create-price/`,
      }), 
      getmembershipdatafs: builder.query({
        query: () => `stripe/fs/create-price/`,
      }),  
      getmembershipdatats: builder.query({
        query: () => `stripe/ts/create-price/`,
      }), 
      ceamountedit: builder.mutation({
        query: ({ amount }) => ({
          url: `stripe/ce/create-price/`, 
          method: 'POST',  
          body: { amount },  
        }),
      }),
      fsamountedit: builder.mutation({
        query: ({ amount }) => ({
          url: `stripe/fs/create-price/`, 
          method: 'POST',  
          body: { amount },  
        }),
      }),
      tsamountedit: builder.mutation({
        query: ({ amount }) => ({
          url: `stripe/ts/create-price/`, 
          method: 'POST',  
          body: { amount },  
        }),
      }),
      // 
      getAlllistingswithstatefs: builder.query({
        query: ({ id, selectedStatus }) => 
          `external/fs-user/${id}/branches/${selectedStatus ? `?subscription_type=${selectedStatus}` : ''}`,
      }),
      getflagshiplistingswithuserdetailsfs: builder.query({
        query: ({id}) => `/external/fs-user/${id}/flagship-branch/`,
      }),
   getFsHeadquarterData:builder.query({
    query: ({id}) => `external/fs-user/${id}/headquarter-branch/`,
  }),
  getTsHeadquarterData:builder.query({
    query: ({id}) => `external/ts-user/${id}/headquarter-branch/`,
  }),
    
    
      getAlllistingswithstatets: builder.query({
        query: ({id, selectedStatus}) => `external/ts-user/${id}/branches/${selectedStatus ? `?subscription_type=${selectedStatus}` : ''}`,
      }),
      getflagshiplistingswithuserdetailsts: builder.query({
        query: ({id}) => `/external/ts-user/${id}/flagship-branch/`,
      }),

      userProfileUpdate:builder.mutation({
        query: ({postData,userId}) => ({
          url: `external/user-profile-picture-update/${userId}/`,
          method: 'PATCH',
          body: postData,
        }),
      }),
      
      accountChangePassword: builder.mutation({
        query: ({postdata,userId}) => ({
          url: `external/user/${userId}/password-change/`,
          method: 'Patch',
          body: postdata,
        }),
      }),
      
      accountChangeProfile: builder.mutation({
        query: ({formData,userId}) => ({
          url: `external/user-profile-picture-update/${userId}/`,
          method: 'PATCH',
          body: formData,
        }),
      }),
      
      accountDelete :builder.mutation({
        query: (userId) => ({
          url: `external/user/${userId}/delete/with-subscription-cancel/`,
          method: 'DELETE',
        }),
      }),
      getBranchList:builder.query({
        query: () => `cafe/branches/`,
      }),
      
      
      branchDelete :builder.mutation({
        query: (id) => ({
          url: `stripe/ce-branch/${id}/delete/with-subscription-cancel/`,
          method: 'DELETE',
        }),
      }),
      
      emailNotificationStatus: builder.mutation({
        query: ({Postata , userId}) => ({
          url: `external/user/${userId}/email-notifications/edit/`,
          method: 'PATCH',
          body: Postata,
        }),
      }),
      
      
      getEmailNotificationStatus: builder.query({
        query: (userId) => `external/user/${userId}/email-notifications/list/`,
      }),
      
      ResetPassword: builder.mutation({
        query: (Postata) => ({
          url: `auth/password-reset/`,
          method: 'POST',
          body: Postata,
        }),
      }),
      
      getAccountHolderData: builder.query({
        query: (id) => `external/user-detail/${id}/`,
      }),
      NotificationMarkAllRead :builder.mutation({
        query: () => ({
          url: `external/custom-normal-admin/portal-notifications/mark-all-read/`,
          method: 'POST',
        }),
      }),
     
      UerCafeExport: builder.query({
        // `external/ce-user/list/branch-type-count/?start_date=2024-04-01&end_date=2024-12-31&subscription_type=lite&export=excel`,
        query: ({ searchTerm, startDate, endDate, exportCsv , selectedStatus }) =>  `external/ce-user/list/branch-type-count/?search=${searchTerm || ''}&subscription_type=${selectedStatus}&start_date=${startDate || ''}&end_date=${endDate || ''}&export=${exportCsv || ''}`, // Ensure default page
      }),
      getMediaGallerybyUserIdfs: builder.query({
        query: (id) => `/external/fs-media-gallery/user/${id}/`,
      }),
      getMediaGallerybyUserIdts: builder.query({
        query: (id) => `/external/ts-media-gallery/user/${id}/`,
      }),
      
      suspendUser: builder.mutation({
        query: ({data, id}) => ({
          url: `external/user/${id}/is-active-edit/with-subscription-cancel/`,
          method: 'PATCH',
          body: data,
        }),
      }),
submitPOSuser: builder.mutation({
  query: ({data, userId}) => ({
    url: `external/ts-user/${userId}/update/pos-status/`,
    method: 'PATCH',
    body: data,
  }),
}),

submitPaymentPlatform: builder.mutation({
  query: ({data, userId}) => ({
    url: `external/ts-user/${userId}/update/payment-platforms-status/`,
    method: 'PATCH',
    body: data,
  }),
}),


  }),

  

  
});

export const {
  useSubmitLoginMutation,
  useGetAllcausersQuery,
  useGetAllfsusersQuery,
  useGetAlltsusersQuery,
  useGetMediagallerytsQuery,
  useGetAlllistingswithstateQuery,
  useGetflagshiplistingswithuserdetailsQuery,
  useGetcafeuserdetailsQuery,useGetMediagalleryfsQuery,
  useGetMediagalleryQuery,useApproveFsmediaGalleryMutation,
  useApproveTsmediaGalleryMutation,useGetAllcalistingsQuery,
  useGetAllfslistingsQuery,useGetAlltslistingsQuery,
  useGetUserStatisticsQuery,useGetUsertypesQuery,
  useGetUseraccounttypesQuery,useGetOperationalstatusQuery,
  useNotificationfromadminMutation,useGetmembershipdataceQuery,
  useGetmembershipdatafsQuery,useGetmembershipdatatsQuery,
  useCeamounteditMutation,useFsamounteditMutation,
  useTsamounteditMutation,
  useGetAlllistingswithstatefsQuery,
  useGetAlllistingswithstatetsQuery,
  useGetflagshiplistingswithuserdetailstsQuery,
  useGetflagshiplistingswithuserdetailsfsQuery,
  useGetfooduserdetailsQuery,
  useGettradeuserdetailsQuery,
  useUserProfileUpdateMutation,
  useGetBranchListQuery,
  useBranchDeleteMutation,
  useEmailNotificationStatusMutation,
  useGetEmailNotificationStatusQuery,
  useAccountChangeProfileMutation,
  useGetAccountHolderDataQuery,
  useAccountDeleteMutation,
  useAccountChangePasswordMutation,
  useNotificationMarkAllReadMutation,
  useUerCafeExportQuery,
  useGetMediaGallerybyUserIdfsQuery,
  useGetMediaGallerybyUserIdtsQuery,
  useSuspendUserMutation,
  useGetFsHeadquarterDataQuery,
  useGetTsHeadquarterDataQuery,
   useSubmitPOSuserMutation,
   useSubmitPaymentPlatformMutation,
   useGetSelectAllcalistingsQuery
} = usersapi;
 