import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import "../Styles/RoleManagement.css";
import "../Styles/Artcle.css";
import { useGetAllcategoriesQuery , useSubmitChatTopicMutation} from "../Services/CommunityApi";

function AddTopic({ show, handleCloseTopicModal }) {
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [value, setValue] = useState('');
  const { data, refetch } = useGetAllcategoriesQuery();

  const [addChatTopic, { isLoading, isSuccess, isError ,reset }] = useSubmitChatTopicMutation();

  const handleCloseAddNewModal = () => {
    handleCloseTopicModal();
  };

  const [formData, setFormData] = useState({
    title: '',
    category: '',
    description: '',
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.title.trim()) errors.title = "Title is required.";
    if (!formData.category) errors.category = "Category is required.";
    if (!formData.description.trim()) errors.description = "Description is required.";
    else if (formData.description.split(" ").length > 200) errors.description = "Description cannot exceed 200 words.";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const adminId = localStorage.getItem("AdminId")
    let PostData = {
      topic: formData.title,
      description: formData.description,
      chat_category_id:formData.category,
      admin_id:adminId
    };
    try {
      const response = await addChatTopic(PostData).unwrap();
      if (response) {
        setFormData({
          title: '',
          category: '',
          description: '',
        });
        handleCloseTopicModal();
      }
    } catch (err) {
      console.error("Failed to submit form:", err);
    }
    }
  };
  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess]);
  return (
    <div>
      <Modal
        show={show}
        onHide={handleCloseAddNewModal}
        backdrop="true"
        dialogClassName="add-new-modal"
      >
        <Modal.Body>
          <h4>Add Your Topic</h4>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Topic <span className="red-star">*</span></label>
                  <br />
                  <input
                    type="text"
                    name="title"
                    placeholder="Enter Topic"
                    value={formData.title}
                    onChange={handleChange}
                  />
                  {errors.title && <p className="error-text">{errors.title}</p>}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Categoty <span className="red-star">*</span></label>
                  <br />
                  <select name="category" value={formData.category} onChange={handleChange}>
                    <option value="">Select category</option>
                    {data?.data?.map((category) => (
                      <option key={category.id} value={category.id}>{category.category_name}</option>
                    ))}
                  </select>
                  {errors.category && <p className="error-text">{errors.category}</p>}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Description <span className="red-star">*</span></label>
                  <br />
                  <textarea
                    name="description"
                    placeholder="Add your description"
                    rows={3}
                    value={formData.description}
                    onChange={handleChange}
                  />
                  <p className="description-allow-text">Maximum of 200 words</p>
                  {errors.description && <p className="error-text">{errors.description}</p>}
                </div>
              </Col>
            </Row>
            <Row>
              <div className="btn-action">
                <button type="button" className="btn-outline-black" onClick={handleCloseAddNewModal}>Cancel</button>
                <button type="submit" className="btn-primary" disabled={isLoading}>{isLoading? "Submitting":"Add"}</button>
              </div>
            </Row>
            {isError && <p className="error-message">Failed to add category. Please try again.</p>}
            {isSuccess && <p className="success-message">Post created successfully</p>}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddTopic;
