import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { adminapi } from "../Services/AdminApi";
import { usersapi } from "../Services/UsersApi";
import { articleapi } from "../Services/ArticleApi";
import { secondaryapi } from "../Services/SecondaryApi";
import { communityapi } from "../Services/CommunityApi";
import { supportapi } from "../Services/SupportApi";
export const store = configureStore({
  reducer: {
    [adminapi.reducerPath]: adminapi.reducer,
    [usersapi.reducerPath]: usersapi.reducer,
    [articleapi.reducerPath]: articleapi.reducer,
    [secondaryapi.reducerPath]: secondaryapi.reducer,
    [communityapi.reducerPath]: communityapi.reducer,
    [supportapi.reducerPath]: supportapi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(adminapi.middleware,usersapi.middleware,articleapi.middleware,secondaryapi.middleware,communityapi.middleware,supportapi.middleware),
});

setupListeners(store.dispatch);
