import React, { useState } from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import FilterImg from "../Assets/Images/Filters lines.svg";
import AddHuddleSessionModal from "../Components/AddHuddleSession";
import { Link } from "react-router-dom";
import { useGetMentorshipQuery } from "../Services/SecondaryApi";

function MediaGallery() {
  const { data, isLoading, isError,refetch } = useGetMentorshipQuery();
  const [showAddHuddleSessionModal, setShowAddHuddleSessionModal] = useState(false);

  const handleShowAddHuddleSessionModal = () => {
    setShowAddHuddleSessionModal(true);
  };

  const handleCloseAddHuddleSessionModal = () => {
    setShowAddHuddleSessionModal(false);
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Something went wrong!</p>;
  }

  return (
    <div>
      <h3>Business Mentorship</h3>
      <div className="table-wrapper-div">
        <div className="search-wrapper d-flex align-items-space-between">
          <h5>Huddle Session</h5>
          <button className="btn-primary" onClick={handleShowAddHuddleSessionModal}>
            Add huddle session
          </button>
        </div>
        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Session title</th>
                <th scope="col">Host name</th>
                <th scope="col">Date & time</th>
                <th className="text-center"></th>
              </tr>
            </thead>
            <tbody>
              {data?.huddle_sessions?.map((session) => (
                <tr key={session.id}>
                  <td className="clr-bold">{session.session_title}</td>
                  <td>{session.host_name}</td>
                  <td>{session.formatted_date_time}</td>
                  <td className="text-center">
                    <Link to={`/view-huddle-session/${session.id}`}>
                      <button className="btn-outline">View</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <AddHuddleSessionModal
        show={showAddHuddleSessionModal}
        handleCloseAddHuddleSessionModal={handleCloseAddHuddleSessionModal}
        refetch={refetch}
        
                  />
    </div>
  );
}

export default MediaGallery;
