import React, { useState, useEffect } from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import { Row, Col } from "react-bootstrap";
import { useGetAllcalistingsQuery } from "../Services/UsersApi";
import OrImg from "../Assets/Images/or-img.svg";
import { Link } from "react-router-dom";
import FilterImg from "../Assets/Images/Filters lines.svg";

function UserManagement() {
  const [page, setPage] = useState(1); // Pagination state
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [filteredListings, setFilteredListings] = useState([]); // Store filtered listings
  const [totalCount, setTotalCount] = useState(0); // Store total count for pagination
  const [nextPageUrl, setNextPageUrl] = useState(null); // For next page URL
  const [previousPageUrl, setPreviousPageUrl] = useState(null); // For previous page URL
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isExporting, setIsExporting] = useState(false);

  const itemsPerPage = 10; // Define how many items per page
  const [exportCsv, setExportCsv] = useState("");
  const { data, isLoading, error , refetch} = useGetAllcalistingsQuery({ page, searchTerm, selectedStatus });
  const { data: exportcsv, refetch: exportrefetchcsv, isSuccess: exportSuccess } = useGetAllcalistingsQuery({ page:"", searchTerm, exportCsv , selectedStatus });


  const convertToCSV = (data) => {
    const ceUsers = data?.results || [];
  
    if (!ceUsers.length) return '';
  
    const headers = ['ID', 'Business name', 'Operational status', '	Location', 'Contact info', 'Added on', 'Status', 'Sub plan'];
    
    const csvRows = [
      headers.join(','), // Header row
      ...ceUsers.map(user => [
        user.id,
        user.business_name,
        user.email,
        user.location,
        user.contact_number,
        user.created_at,
        user.is_active,
        user.subscription_type,
     
      ].join(','))
    ];
  
    return csvRows.join('\n');
  };
  
  useEffect(() => {
    if (exportSuccess && isExporting && exportcsv) {
      try {
        const csvData = convertToCSV(exportcsv);
        const blobData = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blobData);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'cafe_listmanagement.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        setIsExporting(false);
      } catch (error) {
        console.error("Error exporting file:", error);
        setIsExporting(false);
      }
    }
  }, [exportSuccess, exportcsv, isExporting]);

  useEffect(() => {
    if (data) {
      setFilteredListings(data?.results);
      setTotalCount(data?.count);
      setNextPageUrl(data?.next);
      setPreviousPageUrl(data?.previous);
      setTotalPages(Math.ceil(data?.count / itemsPerPage)); // Calculate total pages based on count
    }
  }, [data]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading data?...</p>;
  }

  // Function to export listings to CSV
  const exportToCSV = () => {
    const csvHeaders = [
      "List ID", // Add List ID to the headers
      "Business Name",
      "User ID",
      "Email",
      "Contact Number",
      "Location",
      "Subscription Type",
      "Subscription Status",
      "Registered At",
    ];
    
    const csvRows = filteredListings?.map((listing) => {
      const {
        id, // Get the List ID
        business_name,
        user,
        email,
        contact_number,
        location,
        subscription_type,
        subscription_status,
        created_at,
        operational_status
      } = listing;
  
      const userId = user;
      const registeredAt = new Date(created_at).toLocaleDateString();
  
      return [
        id || "N/A", // Include the List ID
        business_name || "N/A",
        userId || "N/A",
        email || "N/A",
        contact_number || "N/A",
        location || "N/A",
        subscription_type || "N/A",
        subscription_status || "N/A",
        registeredAt,
      ].join(",");
    });
  
    // Join headers and rows for CSV
    const csvContent = [csvHeaders.join(","), ...csvRows].join("\n");
  
    // Create a Blob for CSV content and trigger a download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "business_listings_export.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Pagination controls
  const handleNextPage = () => {
    if (nextPageUrl) {
      setPage(page + 1);
    }
  };
  const handlePreviousPage = () => {
    if (previousPageUrl) {
      setPage(Math.max(page - 1, 1));
    }
  };

  // Helper to handle direct page number clicks
  const handlePageClick = (pageNum) => {
    setPage(pageNum);
  };

  // Helper function to display pagination numbers with dots
  const getPaginationNumbers = () => {
    const paginationNumbers = [];
    const visiblePages = 5; // Limit the number of visible page buttons

    if (totalPages <= visiblePages) {
      // Show all pages if total is less than or equal to 5
      for (let i = 1; i <= totalPages; i++) {
        paginationNumbers.push(i);
      }
    } else {
      // Show pagination with dots
      if (page <= 3) {
        paginationNumbers.push(1, 2, 3, 4, "...", totalPages);
      } else if (page > totalPages - 3) {
        paginationNumbers.push(1, "...", totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        paginationNumbers.push(1, "...", page - 1, page, page + 1, "...", totalPages);
      }
    }

    return paginationNumbers;
  };

  // const handleExportCsv = () => {
  //   setExportCsv("excel");
  //   exportrefetchcsv();
  //   setIsExporting(true);
  // };

  const handleExportCsv = async () => {
    try {
      const token = localStorage.getItem("AdminAccessToken")

      const response = await fetch(`${process.env.REACT_APP_CORE_SERVER}external/ce-branches/?subscription_type=${selectedStatus}&search=${searchTerm}&export=excel`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download the Excel file');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cafe_list.xlsx'); 
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading the Excel file:', error);
    }
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setIsDropdownOpen(false);
    console.log("Selected status:", status);
    refetch();
    exportrefetchcsv();
  };
  const handleresetStatus = () => {
    setSearchTerm("");
    setIsDropdownOpen(false);
  };

  return (
    <div>
      <h3>Listing Management</h3>
      <div className="table-wrapper-div">
        <div className="table-head-with-filter">
          <Row>
            <Col xs={12} md={3} lg={3} sm={12}>
              <h5>Cafe Entrepreneurs</h5>
              <p className="count">{`${totalCount} cafes`}</p>
            </Col>
            <Col xs={12} md={9} lg={9} sm={12}>
              <div className="search-div">
                <div className="form-control-input">
                  <input
                    type="text"
                    placeholder="Email/ Business Name"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setPage(1); 
                    }}
                    
                  />
                  <img src={SearchImg} alt="Search" className="search-img" />
                </div>
                <div className="filter-main-div">
                  <button className="filter-btn" onClick={toggleDropdown}>
                    <img src={FilterImg} alt="Filter" /> Filters
                  </button>
                  {isDropdownOpen && (
                     <div className="dropdown-menu-filter">
                     <p className="reset-filter text-end" onClick={handleresetStatus}>Reset</p>
                     <div onClick={() => handleStatusClick("lite")} className="dropdown-item-status">Lite</div>
                     <div onClick={() => handleStatusClick("pro")} className="dropdown-item-status">Pro</div>
                     <div onClick={() => handleStatusClick("pro&lite")} className="dropdown-item-status">Pro & Lite</div>
                     
                     <div onClick={() => handleStatusClick("unlisted")} className="dropdown-item-status">Unlisted</div>
                   </div>
                  )}
                </div>
                <button className="primary-btn" onClick={handleExportCsv}>
                  Export
                </button>
              </div>
            </Col>
          </Row>
        </div>

        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Business name</th>
                <th scope="col">Operational status</th>
                <th scope="col">Location</th>
                <th scope="col">Contact info</th>
                <th>Added on</th>
                <th>Status</th>
                <th scope="col">Sub plan</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {filteredListings?.map((listing) => (
                <tr key={listing?.id}>
                  <td className="clr-bold">
                    <div className="d-flex">
                      {/* <span className="yellow-span-text">
                        <img src={OrImg} alt="OrImg" />
                      </span>{" "} */}
                      {listing?.business_name || "N/A"}
                    </div>
                  </td>
                  <td>
                    {listing?.operational_status?.length > 0 ? (
                      listing?.operational_status?.map((status) => (
                        <p key={status?.id}>
                          &bull; {status?.name || "N/A"}
                        </p>
                      ))
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>{listing?.location || "N/A"}</td>
                  <td>
                    {listing?.email || "N/A"} <br />
                    {listing?.contact_number || "N/A"}
                  </td>
                  <td>
                    {new Date(listing?.created_at).toLocaleDateString()}
                  </td>
                  <td>
                    <button
                      className={`status-btn ${
                        listing?.subscription_status === "active"
                          ? "approved-status"
                          : "pending-status"
                      }`}
                    >
                      <p
                        className={
                          listing?.subscription_status === "active"
                            ? "green-circle"
                            : "red-circle"
                        }
                      ></p>
                      <span>{listing?.subscription_status || "Inactive"}</span>
                    </button>
                  </td>
                  <td>{listing?.subscription_type || "N/A"}</td>
                  <td className="text-end">
                    <Link to={`/user-cafe-location-details/${listing?.id}`}>
                      <button className="btn-outline">View</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Updated Pagination UI */}
          <div className="prev-next-btn text-end">
            <button
              type="button"
              onClick={handlePreviousPage}
              disabled={page === 1}
              className={page === 1 ? "disabled" : ""}
            >
              Previous
            </button>

            {/* Pagination Numbers with Dots */}
            <div className="pagination-numbers">
              {getPaginationNumbers().map((pageNum, index) => (
                <button
                  key={index}
                  onClick={() => typeof pageNum === "number" && handlePageClick(pageNum)}
                  className={page === pageNum ? "active" : ""}
                  disabled={pageNum === "..."} // Disable the dots buttons
                >
                  {pageNum}
                </button>
              ))}
            </div>

            <button
              type="button"
              onClick={handleNextPage}
              disabled={!nextPageUrl}
              className={!nextPageUrl ? "disabled" : ""}
            >
              Next
            </button>
            <p>{`Page ${page} of ${totalPages}`}</p>
          </div>
          <br /><br />
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
