import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import "../Styles/RoleManagement.css";
import "../Styles/Artcle.css";
import { useAddChatcategoryMutation } from "../Services/CommunityApi";

function RoleManagementRegister({ show, handleCloseCateroryModal,refetch }) {
  const [formData, setFormData] = useState({
    category_name: "",
  });

  const [addChatCategory, { isLoading, isSuccess, isError }] = useAddChatcategoryMutation(); // Mutation hook for adding category

  const handleCloseAddNewModal = () => {
    handleCloseCateroryModal();
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call the mutation to submit the form data
      const result = await addChatCategory({
        category_name: formData.category_name,
      }).unwrap(); // Unwrap is used to handle promise rejection
      refetch();
      handleCloseAddNewModal(); // Close modal on success
    } catch (error) {
      console.error("Failed to add category:", error);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleCloseAddNewModal}
        backdrop="true"
        dialogClassName="add-new-modal"
      >
        <Modal.Body>
          <h4>Add Category</h4>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                    Category name <span className="red-star">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    name="category_name"
                    placeholder="Enter category name"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <div className="btn-action">
                <button
                  type="button"
                  className="btn-outline-black"
                  onClick={handleCloseAddNewModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-primary" disabled={isLoading}>
                  {isLoading ? "Adding..." : "Add"}
                </button>
              </div>
            </Row>
            {isError && <p className="error-message">Failed to add category. Please try again.</p>}
            {isSuccess && <p className="success-message">Category added successfully!</p>}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RoleManagementRegister;
