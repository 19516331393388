import React, { useState } from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import AddArticleModal from "../Components/AddArtcleModal";
import ViewArticleModal from "../Components/ViewArticleModal";
import { useGetAllarticlesQuery, useDeleteArticleMutation } from "../Services/ArticleApi";
import ArticleEditModal from "../Components/ArticleEditModal";
import Swal from 'sweetalert2';

function MediaGallery() {
  const [showAddArticleModal, setAddArticleModal] = useState(false);
  const [showViewArticleModal, setViewArticleModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedArticleId, setSelectedArticleId] = useState(null); // Track selected article ID
  const [deletingArticleId, setDeletingArticleId] = useState(null); // Track the ID of the article being deleted
  const [showArticleEditModal, setShowArticleEditMOdal] = useState(false);
  const { data: articlesResponse, isLoading, isError, refetch } = useGetAllarticlesQuery();
  const [deleteArticle] = useDeleteArticleMutation();

  const handleShowAddArticleModal = () => {
    setAddArticleModal(true);
  };
  const handleCloseArticleAddModal = () => {
    setAddArticleModal(false);
  };


  const handleShowEditArticleModal = (id) => {
    setShowArticleEditMOdal(true);
    setSelectedArticleId(id)
  };
  const handleCloseEditArticleAddModal = () => {
    setShowArticleEditMOdal(false);
  };
  const handleShowViewArticleModal = (articleId) => {
    setSelectedArticleId(articleId); // Set the selected article ID when showing modal
    setViewArticleModal(true);
  };
  const handleCloseArticleViewModal = () => {
    setSelectedArticleId(null);
    setViewArticleModal(false);
  };

  // const handleDelete = async (articleId) => {
  //   if (window.confirm("Are you sure you want to delete this article?")) {
  //     setDeletingArticleId(articleId); // Set the deleting article ID
  //     try {
  //       await deleteArticle(articleId).unwrap();
  //       alert("Article deleted successfully");
  //       refetch();
  //     } catch (error) {
  //       console.error("Failed to delete the article", error);
  //       alert("Failed to delete the article. Please try again later.");
  //     } finally {
  //       setDeletingArticleId(null); // Reset the deleting article ID after deletion
  //     }
  //   }
  // };

  const handleDelete = async (articleId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this article? This action cannot be undone.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingArticleId(articleId); // Set the deleting article ID
  
        try {
          await deleteArticle(articleId).unwrap();
          
          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: 'Article deleted successfully.',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
  
          refetch();
        } catch (error) {
          console.error("Failed to delete the article", error);
          
          Swal.fire({
            icon: 'error',
            title: 'Failed to Delete',
            text: 'Failed to delete the article. Please try again later.',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        } finally {
          setDeletingArticleId(null); // Reset the deleting article ID after deletion
        }
      }
    });
  };

  // Filter articles based on the search query
  const filteredArticles = articlesResponse?.filter((article) =>
    article.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <h3>Article</h3>
      <div className="table-wrapper-div">
        <div className="search-wrapper">
          <div className="search-div">
            <div className="form-control-input">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <img src={SearchImg} alt="Search" className="search-img" />
            </div>
            <button className="primary-btn" onClick={handleShowAddArticleModal}>
              Add Article
            </button>
          </div>
        </div>
        <div className="table-div">
          {isLoading && <p>Loading articles...</p>}
          {isError && <p>Failed to load articles. Please try again later.</p>}
          {!isLoading && !isError && filteredArticles && (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Article Title</th>
                  <th scope="col">Added On</th>
                  <th scope="col">Category</th>
                  <th className="text-center"></th>
                </tr>
              </thead>
              <tbody>
                {filteredArticles.map((article) => (
                  <tr key={article.id}>
                    <td className="clr-bold">{article.title}</td>
                    <td>{article.date}</td>
                    <td className="clr-bold">{article.category_name}</td>
                    <td className="text-center article-action-btn">
                      {/* <button
                        className="btn-outline"
                        onClick={() => handleShowViewArticleModal(article.id)} // Pass the article ID
                        disabled={deletingArticleId === article.id} // Disable View button during delete
                      > */}
                      <button
                        className="btn-outline"
                        onClick={() => handleShowEditArticleModal(article.id)}
                      >
                        Edit
                      </button>
                      <a href={`${process.env.REACT_APP_FRONT_CORE_URL}article-detail/${article.id}`} target="_blank"><button className="btn-outline">
                        View
                      </button></a>
                      <button
                        className="btn-outline"
                        onClick={() => handleDelete(article.id)}
                        disabled={deletingArticleId === article.id} // Disable Delete button if this article is being deleted
                      >
                        {deletingArticleId === article.id ? "Deleting..." : "Delete"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

      </div>
      <AddArticleModal
        show={showAddArticleModal}
        handleCloseArticleAddModal={handleCloseArticleAddModal}
        refetch={refetch}
      />
      <ViewArticleModal
        show={showViewArticleModal}
        handleCloseArticleAddModal={handleCloseArticleViewModal}
        refetch={refetch}
      />
      <ArticleEditModal show={showArticleEditModal} id={selectedArticleId}
        handleCloseArticleAddModal={handleCloseEditArticleAddModal}
        refetch={refetch}
      />
    </div>
  );
}

export default MediaGallery;
