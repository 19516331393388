import React from "react";
import { Row, Col } from "react-bootstrap";
import CardImg from "../Assets/Images/default-placeholder.svg"; // Fallback placeholder
import "../Styles/Userdetail.css";
import { useGetBusinessmaterialsofcsQuery } from "../Services/SecondaryApi";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

function BusinessmaterialDetails({ branchId, userId }) {
  const { id } = useParams();
  const { data, isLoading, error } = useGetBusinessmaterialsofcsQuery({ id });

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>No data available: {error.message}</p>;

  
  return (
    <div className="user-businessmaterial-detail">
      <Row>
        {data?.data?.map((material) => (
          <Col sm={12} md={4} lg={4} key={material.id}>
          <Link
              to={{
                pathname: `/business-material-view/${material.id}/${branchId}/${userId}`}}
            > <div className="card">
              <div className="card-img">
                <img
                  src={material.image || CardImg} 
                  alt={material.title}
                  className="uploaded-images"
                />
              </div>
              <div className="card-body">
                <p>{material.title}</p>
                {material.saved_records?.map((record) => (
                  <div key={record.id}>
                    <p>{record.title}</p>
                    {/* <a href={record.file_path} target="_blank" rel="noopener noreferrer">
                      Download File
                    </a> */}
                  </div>
                ))}
              </div>
            </div></Link>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default BusinessmaterialDetails;
