import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import FileUpload from "../Assets/Images/file-upload.svg";
import "../Styles/RoleManagement.css";
import "../Styles/Artcle.css";
import { useAddBusinessMaterialCategotyMutation } from "../Services/SecondaryApi";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useEffect } from "react";
import Swal from 'sweetalert2';

function RoleManagementRegister({ show, handleCloseAddBusinessCategoryModal, refetch }) {
    const [selectedLogo, setSelectedLogo] = useState(null);
    const [value, setValue] = useState('');

    const [loading, setLoading] = useState(false);
    const [submitLogin, { isLoading, isError, isSuccess, error }] = useAddBusinessMaterialCategotyMutation();

    const handleCloseAddNewModal = () => {
        if (!loading) handleCloseAddBusinessCategoryModal();
    };

    const [formData, setFormData] = useState({
        category_name: '',
        logo: null,
    });
    const [errors, setErrors] = useState({});


  
    useEffect(() => {
        if (isSuccess) {
            refetch();
        }
    }, [isSuccess])

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        setSelectedLogo(file);
        if (file) {
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            
            if (!validTypes.includes(file.type)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    logo: 'Only JPG, PNG, and JPEG files are allowed',
                }));
            } else if (file.size > 1024 * 1024) { // Validate file size (< 1 MB)
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    logo: 'File size must be less than 1 MB',
                }));
            } else {
                // Clear the error if the file is valid
                setErrors((prevErrors) => ({ ...prevErrors, logo: '' }));
                setFormData({ ...formData, logo: file });
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    
        // Clear the error for category_name if there’s an existing error
        if (name === 'category_name' && errors.category_name) {
            setErrors((prevErrors) => ({ ...prevErrors, category_name: '' }));
        }
    };

    const validateForm = () => {
        const errors = {};

        // Category name validation
        if (!formData.category_name) {
            errors.category_name = 'Category name is required';
        } else if (formData.category_name.length > 50) {
            errors.category_name = 'Category name must be less than 50 characters';
        } else if (/[^a-zA-Z0-9 ]/.test(formData.category_name)) {
            errors.category_name = 'No special characters are allowed';
        }

        if (!selectedLogo) {
            errors.logo = 'Please choose file';
        } else {
            // Additional file type and size validations are handled in handleLogoChange
            if (errors.logo) {
                errors.logo = 'Only JPG, PNG, and JPEG files are allowed, and size must be less than 1 MB';
            }
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };


    // const handleLogoChange = (e) => {
    //     const file = e.target.files[0];
    //     if (handleFileValidation(file, ["jpg", "png", "svg"])) {
    //         setSelectedLogo(file);
    //     } else {
    //         alert("Invalid file type. Only .jpg, .png, and .svg files are allowed.");
    //         setSelectedLogo(null);
    //     }
    // };

    const handleFileValidation = (file, allowedTypes) => {
        const fileType = file.type.split("/")[1].toLowerCase();
        return allowedTypes.includes(fileType);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setLoading(true);

        const data = new FormData();
        data.append('title', formData.category_name);
        console.log("selectedLogo", selectedLogo);
        if (selectedLogo) {
            data.append('image', selectedLogo);
        }

        try {
            const response = await submitLogin(data);
            if (response.error) {
                // alert("Submission failed!");
                Swal.fire({
                    icon: 'error',
                    title: 'Submission Failed',
                    text: 'An error occurred. Please try again.',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                  });
            } else {
                // alert("Category added successfully!");
                Swal.fire({
                    icon: 'success',
                    title: 'Submission Successful!',
                    text: 'Category added successfully!',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                  }).then(() => {
                setFormData({
                    category_name: "",
                });
                setSelectedLogo(null);

                handleCloseAddNewModal();
                refetch();
            });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleCloseAddBusinessCategoryModal}
                backdrop="true"
                dialogClassName="add-new-modal"
            >
                <Modal.Body>
                    <h4>Add Category</h4>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className="form-control">
                                    <label>
                                        Category name <span className="red-star">*</span>
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        name="category_name"
                                        placeholder="Enter Category name"
                                        value={formData.category_name}
                                        onChange={handleChange}
                                        disabled={loading}
                                    />
                                    {errors.category_name && <p className="error-text">{errors.category_name}</p>}
                                </div>
                            </Col>

                            <Col xs={12} sm={12} lg={12} md={12}>
                                <label>Upload</label>
                                <div className="file-upload-container d-flex">
                                    <label className="file-upload-label">
                                        <input
                                            type="file"
                                            className="file-upload-input"
                                            onChange={handleLogoChange}
                                            disabled={loading}
                                        />
                                        <div>
                                            <img src={FileUpload} alt="FileUpload" />
                                        </div>
                                        <div>
                                            <span className="file-upload-text">
                                                {formData.logo ? formData.logo.name : 'Please select a file'}
                                            </span>
                                            <span className="format-text">(Only supports .jpeg/.png files)</span>
                                        </div>
                                    </label>
                                </div>
                                {errors.logo && <p className="error-text">{errors.logo}</p>}
                            </Col>
                        </Row>
                        <Row>
                            <div className="btn-action">
                                <button
                                    type="button"
                                    className="btn-outline-black"
                                    onClick={handleCloseAddNewModal}
                                    disabled={loading}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn-primary"
                                    disabled={loading}
                                >
                                    {loading ? 'Submitting...' : 'Add'}
                                </button>
                            </div>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default RoleManagementRegister;
