

import { useState } from 'react';
import BackIcon from "../Assets/Images/back-icon.svg";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import "../Styles/Contact.css";
import ProfileIMg from "../Assets/Images/default-profile-img.png"
import { useGetContactinfodetailsQuery, useContactinforeplyMutation } from "../Services/ArticleApi";
import { Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';

function ContactInfoDetail() {
  const { id } = useParams();
  const { data, isLoading, error, refetch } = useGetContactinfodetailsQuery({ id });
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [submitReply, { isLoading: preloader }] = useContactinforeplyMutation();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Sending the message and id to the mutation
      const response = await submitReply({ message, id });

      if (response?.error) {
        console.error("Submission Error: ", response.error);  // Log the error response
        // alert("Submission failed! Please try again.");
        Swal.fire({
          icon: 'error',
          title: 'Submission failed!',
          text: 'An error occurred. Please try again.',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      } else if (response?.data) {
        // console.log("Successful response: ", response.data);  // Log successful response
        // alert("Comment submitted successfully!");
        Swal.fire({
          icon: 'success',
          title: 'Submission Successful!',
          text: 'Comment submitted successfully!',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
      }).then(() => {
        refetch();
        setMessage(''); // Clear the input field after successful submission
      });
      }
    } catch (error) {
      // console.error("Error submitting comment:", error);  // Catch any runtime errors
      // alert("An error occurred while submitting the comment.");
      Swal.fire({
        icon: 'error',
        title: 'Submission failed!',
        text: 'An error occurred while submitting the comment.',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
  };


  return (
    <div className="contact-info-detail">
      <div className="back-page-icon">
        <p>
          <img src={BackIcon} alt="Back" onClick={handleBackClick} /> {data?.name || "Contact Info"}
        </p>
      </div>

      {isLoading && <p>Loading contact details...</p>}
      {error && <p>Failed to load contact details. Please try again later.</p>}
      {!isLoading && !error && data && (
        <div className="chat-detail">
          <p className="user-name">{data.name}</p>
          <p className="date">{data.date_time}</p>
          {/* <p className="category">{data.interest}</p>
          <p className="content">{data.description}</p> */}

          <Row>
            <Col xs={12} sm={12} md={4} lg={4}>
              <p className='contact-info-head'>Contact Info</p>
              <p className='contact-info-text'>{data?.email}</p>
              <p className='contact-info-text'>{data?.phone_number}</p>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <p className='contact-info-head'>Industry</p>
              <p className='contact-info-text'>{data?.industry_type}</p>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <p className='contact-info-head'>Operational Status</p>
              <p className='contact-info-text'>{data?.operational_status}</p>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <p className='contact-info-head'>Partner Code</p>
              <p className='contact-info-text'>{data?.partnership_code}</p>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <p className='contact-info-head'>What interests you</p>
             {data?.interest?.map((interest)=>(
              <p className='contact-info-text'>{interest}</p>
             ))} 
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <p className='contact-info-head'>How Did You Find Us?</p>
              <p className='contact-info-text'>{data?.found_us}</p>
            </Col>

            <Col xs={12} sm={12} md={4} lg={4}>
              <p className='contact-info-head'>Description</p>
              <p className='contact-info-text mb-40'>{data?.description}</p>
            </Col>
          </Row>

          {/* Display comments */}
          <div className="comments-section">
            {data.comments && data.comments.length > 0 ? (
              data.comments.map((comment) => (
                <div key={comment.comment_id} className="comment">
                 
                 <div className='d-flex'>
                  <img
                    src={comment.admin_profile_image_url || ProfileIMg}
                    alt="Admin"
                    className="admin-profile-image"
                  />
                  <div className='ml-15'>
                  <p className="user-name">Admin</p>
                  <p className="comment-date">{comment.created_at}</p>
                    </div>
                  
                   </div>
                   <p className="comment-text">
                    {comment.comment_text || "No comment text provided"}
                  </p>
                </div>
              ))
            ) : (
              <p>No comments yet.</p>
            )}
          </div>

          {/* Message input form */}
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Type your message..."
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="text-end">
              <button className="btn-outline" onClick={handleCancel}>
                Cancel
              </button>
              <button className="btn-primary" type="submit">
                Comment
              </button>
              {preloader && <div>Loading..</div>}
            </div>
          </form>
          <br /><br />
        </div>
      )}
    </div>
  );
}

export default ContactInfoDetail;


