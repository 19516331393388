import React, { useState , useEffect} from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import { Row, Col } from "react-bootstrap";
import { useGetAlltslistingsQuery } from "../Services/UsersApi";
import OrImg from "../Assets/Images/or-img.svg";
import { Link } from "react-router-dom";
import FilterImg from "../Assets/Images/Filters lines.svg";

function UserManagement() {
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [page, setPage] = useState(1); // Pagination state
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  
  const { data, isLoading, error , refetch} = useGetAlltslistingsQuery({ page: currentPage, searchTerm, selectedStatus}); // Fetch data based on current page
  const [exportCsv, setExportCsv] = useState("");
  const { data: exportcsv, refetch: exportrefetchcsv, isSuccess: exportSuccess } = useGetAlltslistingsQuery({ page:"", searchTerm, exportCsv, selectedStatus });

  const [isExporting, setIsExporting] = useState(false);
  const convertToCSV = (data) => {
    const ceUsers = data?.results || [];
  
    if (!ceUsers.length) return '';
  
    const headers = ['ID', 'Business name', 'Operational status', '	Location', 'Contact info', 'Added on', 'Status', 'Sub plan'];
    
    const csvRows = [
      headers.join(','), // Header row
      ...ceUsers.map(user => [
        user.id,
        user.business_name,
        user.email,
        user.location,
        user.contact_number,
        user.created_at,
        user.is_active,
        user.subscription_type,
     
      ].join(','))
    ];
  
    return csvRows.join('\n');
  };
  
  useEffect(() => {
    if (exportSuccess && isExporting && exportcsv) {
      try {
        const csvData = convertToCSV(exportcsv);
        const blobData = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blobData);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'trade_listmanagement.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        setIsExporting(false);
      } catch (error) {
        console.error("Error exporting file:", error);
        setIsExporting(false);
      }
    }
  }, [exportSuccess, exportcsv, isExporting]);


  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading data...</p>;
  }

  // Extract users and pagination details from the updated response structure
  const users = data.results || [];
  const totalCount = data.count || 0;
  const totalPages = Math.ceil(totalCount / 10); // Assuming 10 users per page

  // Filter users based on search input
  const filteredUsers = users.filter((user) => {
    const userId = user.id.toString(); // Ensure user ID is a string
    const email = user.email?.toLowerCase() || ''; // Handle cases where email may be null
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      email.includes(lowerCaseSearchTerm) || userId.includes(lowerCaseSearchTerm)
    );
  });

  // Function to export users to CSV
  const exportToCSV = () => {
    const csvHeaders = [
      "Business Name",
      "User ID",
      "Email",
      "Contact Number",
      "Location",
      "Subscription Type",
      "Subscription Status",
      "Added On",
    ];

    const csvRows = filteredUsers.map((user) => {
      const businessName = user.business_name || "N/A";
      const userId = user.id;
      const email = user.email || "N/A";
      const contactNumber = user.contact_number || "N/A";
      const location = user.location || "N/A";
      const subscriptionType = user.subscription_type || "N/A";
      const subscriptionStatus = user.subscription_status || "N/A";
      const createdAt = new Date(user.created_at).toLocaleDateString();

      return [
        businessName,
        userId,
        email,
        contactNumber,
        location,
        subscriptionType,
        subscriptionStatus,
        createdAt,
      ].join(",");
    });

    // Join headers and rows for CSV
    const csvContent = [csvHeaders.join(","), ...csvRows].join("\n");

    // Create a Blob for CSV content and trigger a download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ts-users_export.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Pagination handlers
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

    // Helper to handle direct page number clicks
    const handlePageClick = (pageNum) => {
      setPage(pageNum);
    };
  
    // Helper function to display pagination numbers with dots
    const getPaginationNumbers = () => {
      const paginationNumbers = [];
      const visiblePages = 5; // Limit the number of visible page buttons
  
      if (totalPages <= visiblePages) {
        // Show all pages if total is less than or equal to 5
        for (let i = 1; i <= totalPages; i++) {
          paginationNumbers.push(i);
        }
      } else {
        // Show pagination with dots
        if (page <= 3) {
          paginationNumbers.push(1, 2, 3, 4, "...", totalPages);
        } else if (page > totalPages - 3) {
          paginationNumbers.push(1, "...", totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
        } else {
          paginationNumbers.push(1, "...", page - 1, page, page + 1, "...", totalPages);
        }
      }
  
      return paginationNumbers;
    };

    // const handleExportCsv = () => {
    //   setExportCsv("excel");
    //   exportrefetchcsv();
    //   setIsExporting(true);
    // };
    const handleExportCsv = async () => {
      try {
        const token = localStorage.getItem("AdminAccessToken")
  
        const response = await fetch(`${process.env.REACT_APP_CORE_SERVER}external/ts-branches/?subscription_type=${selectedStatus}&search=${searchTerm}&export=excel`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to download the Excel file');
        }
  
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'trade_list.xlsx'); 
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error('Error downloading the Excel file:', error);
      }
    };

    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
  
    const handleStatusClick = (status) => {
      setSelectedStatus(status);
      setIsDropdownOpen(false);
      console.log("Selected status:", status);
      refetch();
      exportrefetchcsv();
    };
    const handleresetStatus = () => {
      setSearchTerm("");
      setIsDropdownOpen(false);
    };

  return (
    <div>
      <h3>Listing Management</h3>
      <div className="table-wrapper-div">
        <div className="table-head-with-filter">
          <Row>
            <Col xs={12} md={3} lg={3} sm={12}>
              <h5>Trades & Services</h5>
              <p className="count">{`${data.count} Trades & Service`}</p>
            </Col>
            <Col xs={12} md={9} lg={9} sm={12}>
              <div className="search-div">
                <div className="form-control-input">
                  <input
                    type="text"
                    placeholder="Email/ User ID"
                    value={searchTerm}
                    onChange={(e) => {setSearchTerm(e.target.value); setPage(1); }} // Update search term
                  />
                  <img src={SearchImg} alt="Search" className="search-img" />
                </div>
                <div className="filter-main-div">
                  <button className="filter-btn" onClick={toggleDropdown}>
                    <img src={FilterImg} alt="Filter" /> Filters
                  </button>
                  {isDropdownOpen && (
                    <div className="dropdown-menu-filter">
                      <p className="reset-filter text-end" onClick={handleresetStatus}>Reset</p>
                    
                      <div onClick={() => handleStatusClick("pro")} className="dropdown-item-status">Pro</div>
                     

                      <div onClick={() => handleStatusClick("unlisted")} className="dropdown-item-status">Unlisted</div>
                    </div>
                  )}
                </div>
                <button className="primary-btn" onClick={handleExportCsv}>
                  Export
                </button>
              </div>
            </Col>
          </Row>
        </div>

        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Business name</th>
                <th scope="col">User ID</th>
                <th scope="col">Location</th>
                <th scope="col">Contact info</th>
                <th>Added on</th>
                <th>Status</th>
                <th>Sub plan</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td className="clr-bold">
                    <div className="d-flex">
                      {/* <span className="yellow-span-text">
                        <img src={OrImg} alt="OrImg" />
                      </span>{" "} */}
                      {user.business_name || "N/A"}
                    </div>
                  </td>
                  <td>{user.id}</td>
                  <td>{user.location || "N/A"}</td>
                  <td>
                    {user.email || "N/A"} <br />
                    {user.contact_number || "N/A"}
                  </td>
                  <td>{new Date(user.created_at).toLocaleDateString()}</td>
                  <td>
                    <button
                      className={`status-btn ${
                        user?.subscription_status === "active"
                          ? "approved-status"
                          : "pending-status"
                      }`}
                    >
                      <p
                        className={
                          user?.subscription_status === "active"
                            ? "green-circle"
                            : "red-circle"
                        }
                      ></p>
                      <span>{user?.subscription_status || "Inactive"}</span>
                    </button>
                  </td>
                  <td>
                    <span className="pro-text">
                      {user.subscription_type || "N/A"}
                    </span>
                  </td>
                  <td className="text-end">
                    <Link to={`/user-trade-location-details/${user.id}`}>
                      <button className="btn-outline">View</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="prev-next-btn text-end">
            <button type="button" onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </button>
            <div className="pagination-numbers">
              {getPaginationNumbers().map((pageNum, index) => (
                <button
                  key={index}
                  onClick={() => typeof pageNum === "number" && handlePageClick(pageNum)}
                  className={page === pageNum ? "active" : ""}
                  disabled={pageNum === "..."} // Disable the dots buttons
                >
                  {pageNum}
                </button>
              ))}
            </div>
            <button type="button" onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next {}
            </button>
            <p>{`Page ${currentPage} of ${totalPages}`}</p>
          </div>
          <br /><br />
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
