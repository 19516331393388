import React, { useState } from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import FilterImg from "../Assets/Images/Filters lines.svg";
import { useGetMediagalleryQuery } from "../Services/UsersApi";
import { Link } from "react-router-dom";
import OrImg from "../Assets/Images/or-img.svg";
import "../Styles/Support.css";

function Support() {
  const { data, isLoading, error } = useGetMediagalleryQuery();
  const [activeButton, setActiveButton] = useState("All");
  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <div>
      <h3>Noticeboard</h3>
      <div className="table-wrapper-div notice-board">
        <div className="search-wrapper">
          <div className="view-user-detail">
            <div className="button-div">
              <p className="support-ticket-table-txt-">
                Ensure all tickets are resolved within one business day or
                sooner for all users.
              </p>
            </div>
          </div>
          <div className="search-div">
            <div className="form-control-input">
              {" "}
              <input type="text" placeholder="Search" />
              <img src={SearchImg} alt={SearchImg} className="search-img" />
            </div>

            <button className="filter-btn">
              <img src={FilterImg} alt={FilterImg} /> Filters
            </button>
          </div>
        </div>

        <div className="table-div">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Ticket no</th>
                <th scope="col">Category</th>
                <th scope="col">Sub category</th>
                <th scope="col">Date & time</th>
                <th scope="col">Assign to</th>
                <th scope="col">Status</th>
                <th cope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>53441</td>
                <td>Technical support</td>
                <td>Platform performance</td>
                <td>22 Jan 2024 <br />09:30 am</td>
                <td>Operations</td>
                <td>
                  <button className="approved-status">
                    <p className="green-circle"></p>
                    <span>Approved</span>
                  </button>
                </td>
                <td>
                  <Link to="/notice-board-view">
                    {" "}
                    <button className="btn-outline">View ticket</button>{" "}
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Support;
