import React, { useState, useEffect } from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import FilterImg from "../Assets/Images/Filters lines.svg";
import { Row, Col } from "react-bootstrap";
import CalenderImg from "../Assets/Images/calender-img.svg";
import { useGetAllfsusersQuery } from "../Services/UsersApi";
import { Link } from "react-router-dom";

function UserManagement() {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportCsv, setExportCsv] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const { data, isLoading, error , refetch} = useGetAllfsusersQuery({ page, searchTerm ,startDate, endDate, selectedStatus});
  const { data:exportcsv, refetch:exportrefetchcsv, isSuccess:exportSuccess } = useGetAllfsusersQuery({ page:"", searchTerm, startDate, endDate, exportCsv, selectedStatus });

  const [isExporting, setIsExporting] = useState(false);
  const convertToCSV = (data) => {
    const ceUsers = data?.results?.fs_users || [];
  
    if (!ceUsers.length) return '';
  
    const headers = ['ID', 'Username', 'Email', 'Contact Number', 'Registered At', 'Is Active', 'First Name', 'Last Name', 'State', 'Post Code', 'Unlisted', 'Lite', 'Pro'];
    
    const csvRows = [
      headers.join(','), // Header row
      ...ceUsers.map(user => [
        user.user.id,
        user.user.username,
        user.user.email,
        user.user.contact_number,
        user.user.user_registered_at,
        user.user.is_active,
        user.profile.first_name,
        user.profile.last_name,
        user.profile.state,
        user.profile.post_code,
        user.branch_counts.unlisted,
        user.branch_counts.lite,
        user.branch_counts.pro,
      ].join(','))
    ];
  
    return csvRows.join('\n');
  };
  
  useEffect(()=>{
    refetch();
      }, [])
  useEffect(() => {
    if (exportSuccess && isExporting && exportcsv) {
      try {
        const csvData = convertToCSV(exportcsv);
        const blobData = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blobData);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'food_users.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        setIsExporting(false);
      } catch (error) {
        console.error("Error exporting file:", error);
        setIsExporting(false);
      }
    }
  }, [exportSuccess, exportcsv, isExporting]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>No data avaialble...</p>;
  }

  const users = data?.results?.fs_users || [];
  const totalCount = data?.count || 0; // Total user count from API response
  const totalPages = Math.ceil(totalCount / 10); // Adjust based on items per page

  // Function to handle pagination
  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };
  const handleStartDate= (e) => {
    setStartDate(e.target.value);
    refetch();                           
  };
  const handleEndDate= (e) => {
    setEndDate(e.target.value);
    refetch();
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setIsDropdownOpen(false); 
    console.log("Selected status:", status); 
    refetch();
  };
const handleresetStatus= () => {
  setSearchTerm("");
  setIsDropdownOpen(false); 
};
const handleExportCsv = async () => {
  try {
    const token = localStorage.getItem("AdminAccessToken")

    const response = await fetch(`${process.env.REACT_APP_CORE_SERVER}external/fs-user/list/branch-type-count/?subscription_type=${selectedStatus}&search=${searchTerm}&start_date=${startDate}&end_date=${endDate}&export=excel`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to download the Excel file');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'food_users.xlsx'); 
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading the Excel file:', error);
  }
};
  return (
    <div>
      <h3>FS Management</h3>
      <div className="table-wrapper-div">
        <div className="table-head-with-filter">
          <Row>
            <Col xs={12} md={3} lg={3} sm={12}>
              <h5>Foodservice Management</h5>
              <p className="count">{`${totalCount} Food Services`}</p>
            </Col>
            <Col xs={12} md={9} lg={9} sm={12}>
              <div className="search-div">
                <div className="form-control-input">
                  <input
                    type="text"
                    placeholder="Email/ User ID"
                   
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setPage(1); // Reset to the first page when searching
                    }}
                  />
                  <img src={SearchImg} alt="Search" className="search-img" />
                </div>
                <div className="filter-main-div">
                <button className="filter-btn" onClick={toggleDropdown}>
                  <img src={FilterImg} alt="Filter" /> Filters
                </button>
                {isDropdownOpen && (
                     <div className="dropdown-menu-filter">
                     <p className="reset-filter text-end" onClick={handleresetStatus}>Reset</p>
                     <div onClick={() => handleStatusClick("pro")} className="dropdown-item-status">Pro</div>
                     <div onClick={() => handleStatusClick("unlisted")} className="dropdown-item-status">Unlisted</div>
                   </div>
                  )}
            </div>
            <button className="primary-btn" onClick={handleExportCsv}>
                  Export
                </button>
              </div>
            </Col>
          </Row>
          <Row className="from-todate">
          <Col xs={12} md={3} lg={3} sm={12}>
          <div className="form-control-input">
            <label>From date</label><br />
                  <input type="date" placeholder="Search" onChange={handleStartDate} name="startDate" />
                  {/* <img src={CalenderImg} alt="Calendar" className="search-img" /> */}
                </div>
                </Col>
                <Col xs={12} md={3} lg={3} sm={12}>
                <div className="form-control-input">
                <label>To date</label><br />
                  <input type="date" placeholder="Search" onChange={handleEndDate}  name="endDate" />
                  {/* <img src={CalenderImg} alt="Calendar" className="search-img" /> */}
                </div>
                </Col>
          </Row>
        </div>

        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">User ID</th>
                <th scope="col">Contact Info</th>
                <th scope="col">Added On</th>
                <th>Status</th>
                <th>No of Listings</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.user.id}>
                  <td className="clr-bold">
                  {`${user.profile.first_name} ${user.profile.last_name}`}
                  </td>
                  <td>{user.user.id}</td>
                  <td>
                    {user.user.email} <br />
                    {user.user.contact_number}
                  </td>
                  <td>{new Date(user.user.user_registered_at).toLocaleDateString()}</td>
                  <td>
                    <button
                      className={`status-btn ${user?.user?.is_active ? "approved-status" : "pending-status"
                        }`}
                    >
                      <p
                        className={
                          user?.user?.is_active ? "green-circle" : "red-circle"
                        }
                      ></p>
                      <span>
                        {user?.user?.is_active ? "Active" : "Inactive"}
                      </span>
                    </button>
                  </td>
                  <td>
                    <span className="clr-bold">{user.branch_counts.pro}</span> <span className="pro-text">Pro</span>
                    <br />
                    <span className="clr-bold">{user.branch_counts.unlisted}</span><span className="unlisted-text">Unlisted</span>
                  </td>
                  <td className="text-end">
                    <Link to={`/user-food-view/${user.user.id}`}>
                      <button className="btn-outline">View</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="prev-next-btn text-end">
            <button
              type="button"
              onClick={handlePreviousPage}
              disabled={page === 1}
              className={page === 1 ? "disabled" : ""}
            >
              Previous
            </button>

            <div className="pagination-numbers">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageClick(index + 1)}
                  className={page === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
            </div>

            <button
              type="button"
              onClick={handleNextPage}
              disabled={page === totalPages}
              className={page === totalPages ? "disabled" : ""}
            >
              Next
            </button>
            <p>{`Page ${page} of ${totalPages}`}</p>
          </div>
          <br /><br />
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
