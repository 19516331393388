import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_COMMUNITY_SERVER;

export const communityapi = createApi({
  reducerPath: 'communityapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState, endpointName }) => {
      const token = localStorage.getItem('AdminAccessToken');
      const noAuthEndpoints = [''];

      // Add token only if it's not a no-auth endpoint
      if (token && !noAuthEndpoints.includes(endpointName)) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    
    getAllcategories: builder.query({
      query: () => `api/admin/list-chatcategories`,
    }),
    addChatcategory: builder.mutation({
        query: ({category_name }) => ({
          url: `api/admin/store-chat-categories`, 
          method: 'POST',  
          body: { category_name },  
        }),
      }), 
      getAlluserchat: builder.query({
        query: (id) => `api/admin/admin-list-all-posts/${id}`,
      }),
      getUserchatdetails: builder.query({
        query: (id) => `api/admin/admin-post-by-categories?chat_category_id=${id}`,
      }),
      deletePost: builder.mutation({
        query: (id) => ({
          url: `api/admin/delete-post?post_id=${id}`,
          method: 'DELETE',
        }),
      }),
      getUserchatcomentslist: builder.query({
        query: (id) => `api/admin/posts-admin/${id}`,
      }),
      addPostcomment: builder.mutation({
        query: (postData ) => ({
          url: `api/admin/admin-replay_post`, 
          method: 'POST',  
          body: postData ,  
        }),
      }), 
      getNoticebaordcategories: builder.query({
        query: () => `api/admin/notice-categories`,
      }),
      getNoticebaordall: builder.query({
        query: ({ selectedCategoryId, selectedStatus }) => {
          const statusQuery = selectedStatus ? `&status=${selectedStatus}` : "";
          console.log("statusQuery",statusQuery)
          return `api/admin/get-notices-admin?noticeCategory=${selectedCategoryId}${statusQuery}`;
      }
      }),
      getNoticebaorddetails: builder.query({
        query: (id) => `api/admin/notice/${id}`,
      }),
      approveNotice: builder.mutation({
        query: ({ id, status } ) => ({
          url: `api/admin/approve-notice/${id}`, 
          method: 'POST',  
          body: {status} ,  
        }),
      }),

      getNoticebaordcategories: builder.query({
        query: () => `api/admin/notice-categories`,
      }),
      getListNotice: builder.query({
        query: ({branchId,userId,categoryId}) => `api/admin/list-notices/${branchId}?noticeCategory=${categoryId}`,
      }),
      getNoticebaordall: builder.query({
        query: ({selectedCategoryId, selectedStatus}) => `api/admin/get-notices-admin?noticeCategory=${selectedCategoryId}&status=${selectedStatus}`,
      }),
      submitChatTopic: builder.mutation({
        query: (postData) => ({
          url: `api/admin/store-chat-post-admin`, 
          method: 'POST',  
          body: postData ,  
        }),
      }),
      deleteUserbyNoticeId: builder.mutation({
        query: (user_id) => ({
          url: `api/delete-notices/${user_id}`,
          method: 'DELETE',
        }),
      }),
  }),
});

export const {
  useGetAllcategoriesQuery,
  useAddChatcategoryMutation,useGetAlluserchatQuery,
  useGetUserchatdetailsQuery,useDeletePostMutation,
  useGetUserchatcomentslistQuery,useAddPostcommentMutation,

  useGetNoticebaorddetailsQuery,useApproveNoticeMutation,
  useGetListNoticeQuery,
  useGetNoticebaordallQuery,
  useGetNoticebaordcategoriesQuery,
  useSubmitChatTopicMutation,
   useDeleteUserbyNoticeIdMutation
} = communityapi;
