import React, { useState } from "react";
import RegisterModal from "../Components/RoleManagementRegister";
import ResetModal from "../Components/RoleManagementResetModal";
import { useGetAlluserroleQuery, useInsertuserbyadminMutation } from "../Services/AdminApi";

function MediaGallery() {
  const { data, isLoading, isError, refetch } = useGetAlluserroleQuery();
  const [showRegisterModal, setRegisterModal] = useState(false);
  const [showResetModal, setResetModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null); // New state to store role and email

  // Show modals
  const handleShowRegisterModal = (roleId, email) => {
    setSelectedRole({ roleId, email });
    setRegisterModal(true);
  };
  
  const handleShowResetModal = (roleId, email) => {
    setSelectedRole({ roleId, email }); // Store the role_id and email when reset is clicked
    setResetModal(true);
  };

  // Close modals
  const handleCloseRegisterModal = () => {
    setRegisterModal(false);
    setSelectedRole(null);
  };
  const handleCloseResetModal = () => {
    setResetModal(false);
    setSelectedRole(null); // Reset the selectedRole when modal is closed
  };

  // Handle loading and error states
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading data.</div>;
  }

  return (
    <div>
      <h3>Role Management</h3>
      <div className="table-wrapper-div">
        <div className="search-wrapper">
          <h5>Roles</h5>
        </div>

        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Role Name</th>
                <th scope="col">User ID</th>
                <th scope="col">Email ID</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((role) => (
                <tr key={role.role_id}>
                  <td className="clr-bold">{role?.role_name}</td>
                  <td>{role?.user?.random_id}</td>
                  <td className="clr-bold">{role?.user?.email}</td>
                  <td className="text-center">
                    {role.user_exists ? (
                      <button
                        className="btn-outline ml-15"
                        onClick={() => handleShowResetModal(role?.role_id, role.user?.email)} // Pass role_id and email to handleShowResetModal
                      >
                        Reset
                      </button>
                    ) : (
                      <button className="btn-outline" onClick={() =>handleShowRegisterModal(role?.role_id, role.user?.email)} >
                        Register
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Register and Reset Modals */}
      <RegisterModal 
      show={showRegisterModal} 
      handleCloseRegisterModal={handleCloseRegisterModal} 
      roleId={selectedRole?.roleId} // Pass the selected role_id to the ResetModal
      email={selectedRole?.email} 
      refetch={refetch}
      />
      <ResetModal
        show={showResetModal}
        handleCloseResetModal={handleCloseResetModal}
        roleId={selectedRole?.roleId} // Pass the selected role_id to the ResetModal
        email={selectedRole?.email} // Pass the selected user email to the ResetModal
      />
    </div>
  );
}

export default MediaGallery;
