import React, { useState, useEffect } from "react";
import "../Styles/Userdetail.css";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import ActionIcon from "../Assets/Images/action_icon.svg"
import { useParams } from 'react-router-dom';
import {
  useGetAlluserchatQuery, useGetNoticebaordcategoriesQuery,
  useGetListNoticeQuery
} from "../Services/CommunityApi";

const App = ({ branchId, userId }) => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("noticeboard");
  const [activeSubTab, setActiveSubTab] = useState(1);
  const { data, isLoading, error } = useGetAlluserchatQuery(id);
  const { data: NoticeCategory } = useGetNoticebaordcategoriesQuery();
  const { data: listNotice, refetch } = useGetListNoticeQuery({ branchId, userId, categoryId: activeSubTab });

  function formatDate(dateString) {
    return moment(dateString).format("DD/MM/YYYY");
  }

  const jobListings = [
    {
      id: "1",
      title: "title",
      category: "category",
      content:
        "Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula. Congue lacus magnis viverra risus aliquam enim. Sed nunc rhoncus nisl interdum augue mattis lacinia adipiscing mauris.",
      location: "Sydney",
      operational_status: "Open for business",
      pin: "456 234",
      created_at: "10/02/2024",
    },
  ];

  const announcements = [
    {
      id: "1",
      title: "title",
      category: "category",
      content:
        "Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula. Congue lacus magnis viverra risus aliquam enim. Sed nunc rhoncus nisl interdum augue mattis lacinia adipiscing mauris.",
      location: "Sydney",
      operational_status: "Open for business",
      pin: "456 234",
      created_at: "10/02/2024",
    },
  ];
  const news = [
    {
      id: "1",
      title: "title",
      category: "category",
      content:
        "Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula. Congue lacus magnis viverra risus aliquam enim. Sed nunc rhoncus nisl interdum augue mattis lacinia adipiscing mauris.",
      location: "Sydney",
      operational_status: "Open for business",
      pin: "456 234",
      created_at: "10/02/2024",
    },
  ];
  const handleNoticeCategoryChange = (id) => {
    setActiveSubTab(id);
    console.log("id", id)
    // refetch();
  }
  useEffect(() => {
    if (activeSubTab) {
      console.log("Refetching data for category:", activeSubTab);
      refetch(); 
    }
  }, [activeSubTab, refetch]);
  const renderContent = () => {
    if (activeTab === "noticeboard") {
      return (
        <div className="notice-board-card-listing">
          {listNotice?.length > 0 ? (
            listNotice?.map((job) => (
              <div key={job.id} className="card">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="d-flex">
                      <div>
                        <h5>{job.title}</h5>
                        <p className="category-name">{job.category}</p>
                      </div>
                    </div>
                    <p className="description">{job.content}</p>
                    <div className="d-flex align-items-space-between">
                      <div>
                        <p className="location">
                          {job.location} . <span>{job.operational_status}</span>
                        </p>
                        <p className="pin">Pin {job.post_code}</p>
                      </div>
                      <p className="publish-date">
                        Added on {formatDate(job?.created_at ?? "")}
                      </p>
                    </div>

                    {/* <hr />
                    <div className="d-flex align-items-space-between">
                      <Link to="/directory-listing">
                        <p className="view-directory-listing">
                          View directory listing
                        </p>
                      </Link>
                    </div> */}
                  </Col>
                </Row>
              </div>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      );

    }
    else {
      return (
        <div className="chat-content">
          {data?.posts?.map((post) => (
            <div className="card">
              <div className="d-flex align-items-space-between">
                <h5 className="title">
                  {post.post_title}
                </h5>
                {/* <img src={ActionIcon} alt="ActionIcon" /> */}
              </div>

              <p className="description">{post.post_content}</p>
              <hr />
              <div className="d-flex align-items-space-between">
                <div>
                  <p className="publish-date">Posted on {new Date(post.post_created_at).toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                  })}</p>
                </div>
                <div>
                  <Link to={`/chatlist-reply/${post.post_id}`}>
                    <p className="comments">Comments</p>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }

  };

  return (
    <div className="community-details">
      <div className="main-tabs">
        <button
          className={activeTab === "noticeboard" ? "active" : ""}
          onClick={() => {
            setActiveTab("noticeboard");
            setActiveSubTab("job-listing");
          }}
        >
          Noticeboard
        </button>
        <button
          className={activeTab === "chat" ? "active" : ""}
          onClick={() => {
            setActiveTab("chat");
          }}
        >
          Chat
        </button>
      </div>

      {activeTab === "noticeboard" && (
        <div className="sub-tabs">
          {NoticeCategory?.data?.map((category) => (
            <button
              key={category.id}
              className={activeSubTab === category.id ? "active" : ""}
              onClick={() => handleNoticeCategoryChange(category.id)}
            >
              {category.name}
            </button>
          ))}
        </div>
      )}

      <div className="tab-content">{renderContent()}</div>
    </div>
  );
};

export default App;
