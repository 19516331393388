import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import FileUpload from "../Assets/Images/file-upload.svg";
import "../Styles/RoleManagement.css";
import "../Styles/Artcle.css";
import { useSubmitArticleMutation } from "../Services/ArticleApi";
import { useGetViewsinglearticleQuery } from "../Services/ArticleApi";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function RoleManagementRegister({ show, handleCloseArticleAddModal, refetch, articleId }) {
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [value, setValue] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    date: '',
    category_name: '',
    description: '',
  });
  const [loading, setLoading] = useState(false); // Loading state
  const [submitLogin] = useSubmitArticleMutation();
//   const { data: articlesResponse, isLoading, isError, refetch } = useGetViewsinglearticleQuery();
   const { data: articleDetails, isLoading: isLoadingArticle } = useGetViewsinglearticleQuery(articleId, {
    skip: !articleId, // Skip query if no articleId is passed
  });

  const handleCloseAddNewModal = () => {
    if (!loading) handleCloseArticleAddModal(); // Only allow closing if not loading
  };

  const handlelogoChange = (e) => {
    const file = e.target.files[0];
    if (handleFileValidation(file, ["jpg", "png", "svg"])) {
      setSelectedLogo(file);
    } else {
      alert("Invalid file type. Only .jpg, .png, and .svg files are allowed.");
      setSelectedLogo(null);
    }
  };

  const handleFileValidation = (file, allowedTypes) => {
    const fileType = file.type.split("/")[1].toLowerCase();
    return allowedTypes.includes(fileType);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submission starts

    // Create a FormData object to handle file uploads
    const data = new FormData();
    data.append('title', formData.title);
    data.append('date', formData.date);
    data.append('category_name', formData.category_name);
    data.append('description', formData.description);
    data.append('content', value); // CKEditor content
    if (selectedLogo) {
      data.append('cover_image', selectedLogo);
    }

    try {
      const response = await submitLogin(data);
      if (response.error) {
        alert("Submission failed!");
      } else {
        alert("Article submitted successfully!");
        handleCloseAddNewModal();
        refetch(); // Call refetch to reload the articles list
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false); // Set loading back to false after the submission is complete
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleCloseAddNewModal}
        backdrop="true"
        dialogClassName="add-new-modal"
      >
        <Modal.Body>
          <h4>Additional Details</h4>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={12} lg={12} md={12}>
                <label>Cover image</label>
                <div className="file-upload-container d-flex">
                  <label className="file-upload-label">
                    <input
                      type="file"
                      className="file-upload-input"
                      onChange={handlelogoChange}
                      disabled={loading} // Disable file input during loading
                    />
                    <div>
                      <img src={FileUpload} alt="FileUpload" />
                    </div>
                    <div>
                      <span className="file-upload-text">
                        {selectedLogo ? selectedLogo.name : "Please select a file"}
                      </span>
                      <span className="format-text">
                        (Only support .jpg, .png and .svg files)
                      </span>
                    </div>
                  </label>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Article title <span className="red-star">*</span></label>
                  <br />
                  <input
                    type="text"
                    name="title"
                    placeholder="Enter article Title"
                    value={formData.title}
                    onChange={handleChange}
                    disabled={loading} // Disable input during loading
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Date <span className="red-star">*</span></label>
                  <br />
                  <input
                    type="date"
                    name="date"
                    placeholder="Date"
                    value={formData.date}
                    onChange={handleChange}
                    disabled={loading} // Disable input during loading
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Category <span className="red-star">*</span></label>
                  <br />
                  <input
                    type="text"
                    name="category_name"
                    placeholder="Enter Category"
                    value={formData.category_name}
                    onChange={handleChange}
                    disabled={loading} // Disable input during loading
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Description</label>
                  <textarea
                    name="description"
                    placeholder="Enter Description"
                    value={formData.description}
                    onChange={handleChange}
                    disabled={loading} // Disable textarea during loading
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Content</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={value}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setValue(data);
                    }}
                    disabled={loading} // Disable CKEditor during loading
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <div className="btn-action">
                <button
                  type="button"
                  className="btn-outline-black"
                  onClick={handleCloseAddNewModal}
                  disabled={loading} // Disable cancel button during loading
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={loading} // Disable submit button during loading
                >
                  {loading ? "Submitting..." : "Save & continue"}
                </button>
              </div>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RoleManagementRegister;
