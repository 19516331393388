import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import FileUpload from "../Assets/Images/file-upload.svg";
import "../Styles/RoleManagement.css";
import "../Styles/Artcle.css";
import { useSaveImportInviteMutation  } from "../Services/SecondaryApi";
import Swal from 'sweetalert2';

function RoleManagementRegister({ show, handleCloseImportMOdal, refetch, sesssionTitle, huddle_session_id }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [formData, setFormData] = useState({
        huddle_session_id: '', 
    });
    const [loading, setLoading] = useState(false);
    const [saveImportInvite, { isLoading, isError, isSuccess, error }] = useSaveImportInviteMutation(); // Mutation for file upload

    const handleCloseAddNewModal = () => {
        if (!loading) handleCloseImportMOdal();
        setSelectedFile("");
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (handleFileValidation(file, ["csv", "xlsx", "xls"])) {
            setSelectedFile(file);
        } else {
            alert("Invalid file type. Only .csv, .xlsx, and .xls files are allowed.");
            setSelectedFile(null);
        }
    };

    const handleFileValidation = (file, allowedTypes) => {
        const fileType = file.name.split(".").pop().toLowerCase();
        return allowedTypes.includes(fileType);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log("huddle_session_id", huddle_session_id)
        const data = new FormData();
        data.append('huddle_session_id', huddle_session_id);
        if (selectedFile) {
            data.append('file', selectedFile); 
        }
        console.log("data", data)
        try {
            const response = await saveImportInvite(data);
            if (response.error) {
                console.log("response.error", response.error)
                if(response?.error?.data?.message == "Some rows could not be processed due to validation errors."){
                    alert("User has already been invited to this session.");
                }
                else{
                    alert(response?.error?.data?.message);
                }
                // setSelectedFile("");
            } else {
                Swal.fire({
                    text: "File uploaded successfully!",
                    icon: "success",
                  });
                handleCloseAddNewModal();
                refetch(); 
                setSelectedFile("");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false); 
        }
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={handleCloseImportMOdal}
                backdrop="true"
                dialogClassName="add-new-modal"
            >
                <Modal.Body>
                    <h4>Import CSV</h4>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} sm={12} lg={12} md={12}>
                                <label>Upload CSV</label>
                                <div className="file-upload-container d-flex">
                                    <label className="file-upload-label">
                                        <input
                                            type="file"
                                            className="file-upload-input"
                                            onChange={handleFileChange}
                                            disabled={loading}
                                        />
                                        <div>
                                            <img src={FileUpload} alt="FileUpload" />
                                        </div>
                                        <div>
                                            <span className="file-upload-text">
                                                {selectedFile ? selectedFile.name : "Please select file"}
                                            </span>
                                            <span className="format-text">
                                                (Only .csv, .xlsx, and .xls  files)
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-40">
                            <Col xs={12} sm={12} lg={12} md={12}>
                                <label>Huddle session title</label>
                                <input
                                    type="text"
                                    name="huddle_session_id"
                                    value={sesssionTitle}
                                    onChange={handleChange}
                                    required
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <div className="btn-action">
                                <button
                                    type="button"
                                    className="btn-outline-black"
                                    onClick={handleCloseAddNewModal}
                                    disabled={loading}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn-primary"
                                    disabled={loading}
                                >
                                    {loading ? "Submitting..." : "Upload"}
                                </button>
                            </div>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default RoleManagementRegister;
