import React, { useState } from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import FilterImg from "../Assets/Images/Filters lines.svg";
import { useGetSupportticketsQuery } from "../Services/SupportApi";
import { Link } from "react-router-dom";

function ContactInfo() {

  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const { data, isLoading, error, refetch } = useGetSupportticketsQuery({selectedStatus, page});

  const totalCount = data?.ticketCount || 0; // Use optional chaining to prevent errors
  const totalPages = Math.ceil(totalCount / 10); 

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // if (error) {
  //   return <div></div>;
  // }

  // Filter the tickets based on the search query
  const filteredTickets = data?.
    ticketData?.filter((ticket) => {
      const ticketNo = ticket.ticket_code?.toLowerCase();
      const category = ticket.category?.name?.toLowerCase();
      const subcategory = ticket.subcategory?.name?.toLowerCase();
      const status = ticket.status?.toLowerCase();

      return (
        ticketNo?.includes(searchQuery.toLowerCase()) ||
        category?.includes(searchQuery.toLowerCase()) ||
        subcategory?.includes(searchQuery.toLowerCase()) ||
        status?.includes(searchQuery.toLowerCase())
      );
    });

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setIsDropdownOpen(false);
    console.log("Selected status:", status);
    refetch();
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };
const handleResetStatus = () =>{
  setSelectedStatus("");
}
  return (
    <div>
      <h3>Support Management</h3>
      <div className="table-wrapper-div">
        <div className="search-wrapper">
          <p>Ensure all tickets are resolved within one business day or sooner for all users</p>
          <div className="search-div">

            <div className="form-control-input">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <img src={SearchImg} alt="Search" className="search-img" />
            </div>

            <div className="filter-main-div">
              <button className="filter-btn" onClick={toggleDropdown}>
                <img src={FilterImg} alt="Filter" /> Filters
              </button>
              {isDropdownOpen && (
                <div className="dropdown-menu-filter">
                  <p className="reset-filter text-end" onClick={handleResetStatus}>Reset</p>
                  <div onClick={() => handleStatusClick("New")} className="dropdown-item-status">New</div>
                  <div onClick={() => handleStatusClick("In Progress")} className="dropdown-item-status">In progress</div>
                  <div onClick={() => handleStatusClick("Resolved")} className="dropdown-item-status">Resolved </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Ticket no</th>
                <th scope="col">Category</th>
                <th scope="col">Sub category</th>
                <th scope="col">Date & time</th>
                <th scope="col">Assign to</th>
                <th scope="col">Status</th>
                <th scope="col">View Image</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {filteredTickets && filteredTickets.length > 0 ? (
                filteredTickets.map((ticket) => (
                  <tr key={ticket.id}>
                    <td>{ticket.ticket_code}</td>
                    <td>{ticket.category?.name}</td>
                    <td>{ticket.subcategory?.name}</td>
                    <td>
                      {ticket.created_at}
                      <br />
                    </td>
                    <td>{ticket.assigned_to}</td>
                    <td>
                      {ticket.status === "Resolved" ? (
                        <button className="approved-status">
                          <p className="green-circle"></p>
                          <span>Resolved</span>
                        </button>
                      ) : ticket.status === "In Progress" ? (
                        <button className="pending-status">
                          <p className="red-circle"></p>
                          <span>In progress</span>
                        </button>
                      ) : (
                        <button className="new-status">
                          <p className="blue-circle"></p>
                          <span>{ticket.status}</span>
                        </button>
                      )}
                    </td>
                    <td>
                   {ticket.file_url && <a href={ticket.file_url} target="_blank" className="ylw-text">View Image</a>}
                    </td>
                    <td>
                      <Link to={`/support-management-view/${ticket.id}`}>
                        <button className="btn-outline">View</button>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No tickets found</td>
                </tr>
              )}
            </tbody>
          </table><br /><br />
        </div>

        <div className="prev-next-btn text-end">
            <button
              type="button"
              onClick={handlePreviousPage}
              disabled={page === 1}
              className={page === 1 ? "disabled" : ""}
            >
              Previous
            </button>

            {/* Pagination Numbers */}
            <div className="pagination-numbers">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageClick(index + 1)}
                  className={page === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
            </div>

            <button
              type="button"
              onClick={handleNextPage}
              disabled={page === totalPages}
              className={page === totalPages ? "disabled" : ""}
            >
              Next
            </button>
            <p>{`Page ${page} of ${totalPages}`}</p>
          </div>

      </div>
    </div>
  );
}

export default ContactInfo;
