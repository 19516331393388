import React, { useState } from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import FilterImg from "../Assets/Images/Filters lines.svg";
import { useGetContactinfoQuery } from "../Services/ArticleApi";
import { Link } from "react-router-dom";

function ContactInfo() {

  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const { data, isLoading, error, refetch } = useGetContactinfoQuery(selectedStatus);
  // Filter the data based on the search term
  const filteredData = data?.filter((contact) =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.contact_info.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.contact_info.phone_number.includes(searchTerm)
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setIsDropdownOpen(false);
    refetch();
  };
  const handleResetStatus = (status) => {
    setSelectedStatus("");
    setIsDropdownOpen(false);
    refetch();
  };

  return (
    <div>
      <h3>Contact info</h3>
      <div className="table-wrapper-div">
        <div className="search-wrapper">
          <div className="search-div">
            <div className="form-control-input">
              <input
                type="text"
                placeholder="Search here"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <img src={SearchImg} alt="Search" className="search-img" />
            </div>

            <div className="filter-main-div">
              <button className="filter-btn" onClick={toggleDropdown}>
                <img src={FilterImg} alt="Filter" /> Filters
              </button>
              {isDropdownOpen && (
                <div className="dropdown-menu-filter-contact">
                  <p className="reset-filter text-end" onClick={handleResetStatus}>Reset</p>
                  <div onClick={() => handleStatusClick("free_menu_reviewing")} className="dropdown-item-status">  Free Menu Review & Listing</div>
                  <div onClick={() => handleStatusClick("education_mentorship")} className="dropdown-item-status">Education & Mentorship</div>
                  <div onClick={() => handleStatusClick("Networking")} className="dropdown-item-status"> Networking & Exposure </div>
                  <div onClick={() => handleStatusClick("business_showcase")} className="dropdown-item-status"> Business Showcase </div>
                  <div onClick={() => handleStatusClick("collaboration_opportunities")} className="dropdown-item-status">  Collaboration Opportunities</div>
                  <div onClick={() => handleStatusClick("event_program")} className="dropdown-item-status">   Event/Program Participation</div>
                  <div onClick={() => handleStatusClick("other")} className="dropdown-item-status">Other </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="table-div">
          {isLoading && <p>Loading contact info...</p>}
          {error && <p>Loading contact info...</p>}
          {!isLoading && !error && filteredData && (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Contact info</th>
                  <th scope="col">Date & time</th>
                  <th scope="col">Interested in</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((contact, index) => (
                    <tr key={index}>
                      <td>{contact.name}</td>
                      <td>
                        {contact.contact_info.email}
                        <br />
                        {contact.contact_info.phone_number}
                      </td>
                      <td>{contact.date_time}</td>
                      <td className="break-word">{contact.interested_in}</td>
                      <td>
                        <Link to={`/contact-info-detail/${contact.id}`}>
                          <button className="btn-outline">View</button>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No matching results found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
