import { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import BackIcon from "../Assets/Images/back-icon.svg";
import { useGetBusinessmaterialsubcategoryQuery } from "../Services/SecondaryApi";
import AddSubCategoryModal from "../Components/AddBusinessMaterialSubCategory";
import { useNavigate } from "react-router-dom";

function BusinessDevelopmentSubcategory() {
    const { id } = useParams();
    const { data, isLoading, error , refetch} = useGetBusinessmaterialsubcategoryQuery(id);
    const [showAddBusinessCategoryModal, setshowAddBusinessCategoryModal] = useState(false);
    

    const handleShowBusinessCategoryModal = ()=>{
        setshowAddBusinessCategoryModal(true);
      }
      const handleCloseAddBusinessCategoryModal=()=>{
        setshowAddBusinessCategoryModal(false)
      }
      const navigate = useNavigate();
      const handleBackClick = () => {
          navigate(-1); // Go back to the previous page
        };
  
    return (
        <div>
            <Link onClick={handleBackClick}><img src={BackIcon} alt="BackIcon" className="back-icon" /></Link>
            <h3>Business Development Materials</h3>
            <div className="table-wrapper-div">
                <div className="sub-haed-table">
                <div className="d-flex justify-content-space-between">
                    <h4>{data?.business_material_title}</h4>
                    <button className="primary-btn"  onClick={handleShowBusinessCategoryModal}>Add Subcategory</button>
                    </div>
                </div>
                {isLoading && <p>Loading ...</p>}
                {error && <p>Failed to load. Please try again later.</p>}
                {!isLoading && !error && data && (
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Category</th>
                                <th scope="col">No of documents</th>
                                <th scope="col">No of saves</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.subcategories.map((subcategory) => (
                                <tr key={subcategory.subcategory_id}>
                                    <td className="clr-bold">{subcategory.subcategory_title}</td>
                                    <td>{subcategory.documents_count}</td>
                                    <td>{subcategory.saved_count}</td>
                                    <td>
                                        <Link to={`/business-developement-materialtype/${subcategory.subcategory_id}/${data.business_material_id}`}>
                                            <button className="btn-outline">View</button>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <AddSubCategoryModal show={showAddBusinessCategoryModal} handleCloseAddBusinessCategoryModal={handleCloseAddBusinessCategoryModal} refetch={refetch}/>
        </div>
    );
}

export default BusinessDevelopmentSubcategory;
