import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "../Components/Layout";
import Dashboard from "../Components/Dashboard"
import MediaGallery from "../Components/MediaGallery";
import MediaGalleryTS from "../Components/MediaGalleryTS";
import BusinessDevelopmentMaterials from "../Components/BusinessDevelopmentMaterials";
import Workbook from "../Components/Workbook";
import UserCafe from "../Components/UserCafe"
import UserFood from "../Components/UserFood"
import UserTrade from "../Components/UserTrade"
import Login from "../Components/Login"
import SuperAdminLogin from "../Components/SuperAdminLogin"
import RoleManagement from "../Components/Rolemanagement"
import Articles from "../Components/Articles"
import BusinessDevelopmentSubcategory from "../Components/BusinessDevelopmentSubcategory"
import BusinessDevelopmentMaterialtype from "../Components/BusinessDevelopmentMaterialtype"
import UserCafeView from "../Components/UserCafeView"
import UserCafeLOcationDetails from "../Components/UserLocationDetails"
import UserFoodLOcationDetails from "../Components/UserFoodLocationDetails"
import UserTradeLOcationDetails from "../Components/UserTradeLocationDetails"
import ListManagementCafe from "../Components/ListManagement-cafe"
import ListManagementTrade from "../Components/ListManagement-trade"
import ListManagementFood from "../Components/ListManagement-food"
import ContactInfo from "../Components/ContactInfo";
import ContactInfoDetail from "../Components/ContactInfoDetail"
import EcosystemStatistics from "../Components/EcosystemStatistics";
import SupportManagement from "../Components/SupportManagement"
import SupportManagementView from "../Components/SupportManagementView"
import NoticeBoard from "../Components/NoticeBoard";
import CommunityChat from "../Components/CommunityChat"
import NoticeboardView from "../Components/NoticeboardView";
import CommunityChatView from "../Components/CommunityChatView"
import ChatListReply from "../Components/CommunityChatlistReply"
import EcosystemNotification from "../Components/EcosystemNotification";
import Support from "../Components/Support";
import Memebership from "../Components/Membership"
import BusinessMentorship from "../Components/BusinessMentorship"
import HuddleSessionView from "../Components/VIewHuddleSession"
import UserFoodView from "../Components/UserFoodview"
import UserTradeView from "../Components/UserTradeView"
import BusinessMaterialDetails from "../Components/BusinessMaterialView"
import Notification from "../Components/Notification"

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/super-admin" element={<SuperAdminLogin />} />
      <Route path="/mediagallery-foodservice" element={<Layout><MediaGallery /></Layout>} />
      <Route path="/mediagallery-tradeservice" element={<Layout><MediaGalleryTS /></Layout>} />
      <Route path="/business-developement-matrials" element={<Layout><BusinessDevelopmentMaterials /></Layout>} />
      <Route path="/workbook/:businessmaterial_id/:subcategory_id/:materialtype_id" element={<Layout><Workbook /></Layout>} />
      <Route path="/user-cafe" element={<Layout><UserCafe /></Layout>} />
      <Route path="/user-cafe-view/:id" element={<Layout><UserCafeView /></Layout>} />
      <Route path="/user-cafe-location-details/:id" element={<Layout><UserCafeLOcationDetails /></Layout>} />
      <Route path="/user-food-location-details/:id" element={<Layout><UserFoodLOcationDetails /></Layout>} />
      <Route path="/user-trade-location-details/:id" element={<Layout><UserTradeLOcationDetails /></Layout>} />

      
      <Route path="/user-food" element={<Layout><UserFood /></Layout>} />
      <Route path="/user-trade" element={<Layout><UserTrade /></Layout>} />
      <Route path="/role-management" element={<Layout><RoleManagement /></Layout>} />
      <Route path="/articles" element={<Layout><Articles /></Layout>} />
      <Route path="/business-developement-subcategory/:id" element={<Layout><BusinessDevelopmentSubcategory /></Layout>} />
      <Route path="/business-developement-materialtype/:id/:mid" element={<Layout><BusinessDevelopmentMaterialtype /></Layout>} />
      <Route path="/list-management-cafe" element={<Layout><ListManagementCafe /></Layout>} />
      <Route path="/list-management-trade" element={<Layout><ListManagementTrade /></Layout>} />
      <Route path="/list-management-food" element={<Layout><ListManagementFood /></Layout>} />
      <Route path="/contact-info" element={<Layout><ContactInfo /></Layout>} />
      <Route path="/contact-info-detail/:id" element={<Layout><ContactInfoDetail /></Layout>} />
      <Route path="/ecosystem-statistics" element={<Layout><EcosystemStatistics /></Layout>} />
      <Route path="/support-management" element={<Layout><SupportManagement /></Layout>} />
      <Route path="/support-management-view/:id" element={<Layout><SupportManagementView /></Layout>} />
      <Route path="/notice-board" element={<Layout><NoticeBoard /></Layout>} />
      <Route path="/notice-board-view/:id" element={<Layout><NoticeboardView /></Layout>} />
      <Route path="/community-chat" element={<Layout><CommunityChat /></Layout>} />
      <Route path="/community-chat-view/:id" element={<Layout><CommunityChatView /></Layout>} />
      <Route path="/chatlist-reply/:id" element={<Layout><ChatListReply /></Layout>} />
      <Route path="/ecosystem-notification" element={<Layout><EcosystemNotification /></Layout>} />
      <Route path="/support" element={<Layout><Support /></Layout>} />
      <Route path="/membership" element={<Layout><Memebership /></Layout>} />
      <Route path="/business-mentorship" element={<Layout><BusinessMentorship /></Layout>} />
      <Route path="/view-huddle-session/:id" element={<Layout><HuddleSessionView /></Layout>} />
      <Route path="/user-food-view/:id" element={<Layout><UserFoodView /></Layout>} />
      <Route path="/user-trade-view/:id" element={<Layout><UserTradeView /></Layout>} />
      <Route path="/business-material-view/:id/:branchid/:userid" element={<Layout><BusinessMaterialDetails /></Layout>} />
      
      <Route path="/notification" element={<Layout><Notification /></Layout>} />

    </Routes>
  );
}

export default function Routers() {
  return (
    <ScrollToTop />
  );
}
