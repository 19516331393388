import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import defaultplaceholder from "../Assets/Images/default-placeholder.svg";
import MUltiSelectAdd from "../Assets/Images/multiselct-Add.svg";
import GoogleImg from "../Assets/Images/Google.svg";
import Trustpilot from "../Assets/Images/Trustpilot_ Reviews & Ratings.svg";
import { useGettradeuserdetailsQuery } from "../Services/UsersApi";
import { useParams } from "react-router-dom";

function CafeDetails() {
  const { id } = useParams();
    const { data, isLoading, error } = useGettradeuserdetailsQuery({ id });
  const featuresData = [
    {
      id: 1,
      name: "feature 1",
    },
    {
      id: 2,
      name: "feature 2",
    },
  ];
  const menuhighlitsData = [
    {
      id: 1,
      name: "feature 1",
    },
    {
      id: 2,
      name: "feature 2",
    },
  ];

  const specialdietryServiceData = [
    {
      id: 1,
      name: "feature 1",
    },
    {
      id: 2,
      name: "feature 2",
    },
  ];

  const ambienceData = [
    {
      id: 1,
      name: "feature 1",
    },
    {
      id: 2,
      name: "feature 2",
    },
  ];

  const techstackData = [
    {
      id: 1,
      name: "feature 1",
    },
    {
      id: 2,
      name: "feature 2",
    },
  ];
  const serviceTypes = [
    {
      id: 1,
      name: "service 1",
    },
    {
      id: 2,
      name: "service 2",
    },
  ];
  const productTypes = [
    {
      id: 1,
      name: "product 1",
    },
    {
      id: 2,
      name: "product 2",
    },
  ];

  const dietryServices = [
    {
      id: 1,
      name: "dietryservice 1",
    },
    {
      id: 2,
      name: "dietryservice 2",
    },
  ];

  const tradeServicesType = [
    {
      id: 1,
      name: "tradeservice 1",
    },
    {
      id: 2,
      name: "tradeservice 2",
    },
  ];

  const events = [
    {
      link: "link1",
      title: "title1",
    },
    {
      link: "link2",
      title: "title2",
    },
  ];
  const reviews = [
    {
      review_link: "link1",
      platform_name: "Google Reviews",
    },
    {
      review_link: "link2",
      platform_name: "title2",
    },
  ];
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedMenuHighlights, setSelectedMenuHighlights] = useState([]);
  const [selectedDietaryServices, setSelectedDietaryServices] = useState([]);
  const [selectedAmbience, setSelectedAmbience] = useState([]);
  const [selectedTechStack, setSelectedTechStack] = useState([]);

  const [selectedFeatureseco, setSelectedFeatureseco] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedDietryService, setSelectedDietryService] = useState([]);
  const [selectedFSStates, setSelectedFsStates] = useState([]);
  const [selectedTSStates, setSelectedTSStates] = useState([]);
  const [selectedTradeServiceType, setSelectedTradeServiceType] = useState([]);

  const handleFeatureChange = (id) => {
    setSelectedFeatures((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle Menu Highlights Change
  const handleMenuHighlightChange = (id) => {
    setSelectedMenuHighlights((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((highlightId) => highlightId !== id)
        : [...prevSelected, id]
    );
  };
  const handleDietaryServiceChange = (id) => {
    setSelectedDietaryServices((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleAmbienceChange = (id) => {
    setSelectedAmbience((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleTechStackChange = (id) => {
    setSelectedTechStack((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleFeatureChangeEco = (id) => {
    setSelectedFeatures((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleProcductChange = (id) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDietryServiceChange = (id) => {
    setSelectedDietryService((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleFsStateChange = (id) => {
    setSelectedFsStates((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleTsStateChange = (id) => {
    setSelectedTSStates((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleChangeTradeServiceType = (id) => {
    setSelectedTradeServiceType((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  return (
    <div className="user-details-tab-content">
      <form>
        <h3>Account holder details</h3>
        <hr />
        <div>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  First name<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={data?.user?.first_name || ' '}
                  name=" firstname"
                  placeholder="First name"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Last name<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={data?.user?.last_name || ' '}
                  name="lastname"
                  placeholder="Last name"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Email<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={data?.user?.email || ' '}
                  name="email"
                  placeholder="Last name"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Contact number<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={data?.user?.contact_number || ' '}
                  name="contact_number"
                  placeholder="Contact number"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  State<span className="red-star">*</span>
                </label>
                <br />
                <select disabled>
                  <option>{data?.user?.state || ' '}</option>
                </select>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Post code<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={data?.user?.post_code || ' '}
                  name="post_code"
                  placeholder="Post code"
                  disabled
                />
              </div>
            </Col>
          </Row>
        </div>

        <h3 className="mt-20">Business details</h3>
        <hr />
        <div>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Business name<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={data?.branch?.business_name || ' '}
                  name="business_name"
                  placeholder="Business name"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>ABN</label>
                <br />
                <input
                 value={data?.branch?.abn || ' '}
                type="text" name="abn" placeholder="ABN" disabled />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Email<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={data?.branch?.email || ' '}
                  name="email"
                  placeholder="Last name"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Contact number<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={data?.branch?.contact_number || ' '}
                  name="contact_number"
                  placeholder="Contact number"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Location<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={data?.branch?.location || ' '}
                  name="location"
                  placeholder="Location"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>
                  Post code<span className="red-star">*</span>
                </label>
                <br />
                <input
                  type="text"
                  value={data?.branch?.post_code || ' '}
                  name="post_code"
                  placeholder="Post code"
                  disabled
                />
              </div>
            </Col>
          </Row>
        </div>

        <h3 className="mt-20">Online Presence</h3>
        <hr />
        <div>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Web URL</label>
                <br />
                <input
                  type="text"
                  value={data?.branch?.weburl || ' '}
                  name="weburl"
                  placeholder="Web url"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Instagram</label>
                <br />
                <input
                  type="text"
                  value={data?.branch?.instagram || ' '}
                  name="Instagram"
                  placeholder="Instagram"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Facebook</label>
                <br />
                <input
                  type="text"
                  value={data?.branch?.facebook || ' '}
                  name="facebook"
                  placeholder="Facebook"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>LinkedIn</label>
                <br />
                <input
                  type="text"
                  value={data?.branch?.linkedin || ' '}
                  name="linkdin"
                  placeholder="LinkedIn"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Twitter</label>
                <br />
                <input
                  type="text"
                  value={data?.branch?.twitter || ' '}
                  name="twitter"
                  placeholder="Twitter"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>TikTok</label>
                <br />
                <input
                  type="text"
                  value={data?.branch?.tiktok || ' '}
                  name="tiktok"
                  placeholder="TikTok"
                  disabled
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>YouTube</label>
                <br />
                <input
                  type="text"
                  value={data?.branch?.youtube || ' '}
                  name="youTube"
                  placeholder="YouTube"
                  disabled
                />
              </div>
            </Col>
          </Row>
        </div>

        <h3 className="mt-20">Details</h3>
        <hr />
        <div>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Logo</label>
                <br />
                <img
                  src={data?.additional_detail?.logo}
                  // src="https://dev-ccc-data.s3.amazonaws.com/media/cafe-entrepreneurship/additional-detail/logo/about-card1-img.png?AWSAccessKeyId=AKIAQ3EGULT25CZSRCW6&Signature=Czdqnk1wBVdu30mrznnsIrheFdo%3D&Expires=1728022682"
                  alt="logo"
                  className="uploaded-images"
                />
              </div>
            </Col>

            <Col sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Images</label>
                <br />
                {data?.additional_detail?.images?.map((option) => (
                <img
                  src={option?.image}
                  alt="defaultplaceholder"
                  className="uploaded-images"
                />
              ))}
              </div>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <div className="form-control">
                <label>Mini Bio</label>
                <br />
                <textarea className="text-area-min-bio" value={data?.additional_detail?.min_bio} disabled />
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <h3 className="mt-20">Additional Details</h3>
          <hr />
          <Row>
            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40 mrt-20">
              <div className="form-control">
                <label className="choose-label">
                 Service category
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                  {data?.additional_detail?.service_categories?.map((option) => (
                    <div key={option.id} className="multi-select-item">
                      <div
                        className="multi-select-container selected"
                        onClick={() => handleFeatureChange(option?.id)}
                      >
                        <input
                          type="checkbox"
                          id={`option${option.id}`}
                          name="services"
                          value={option.id}
                          checked={selectedFeatures.includes(option?.id)}
                          onChange={() => handleFeatureChange(option?.id)}
                          style={{ display: "none" }}
                          disabled
                        />
                        <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                        <label htmlFor={`option${option?.id}`}>
                          {option?.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
            <Col xs={12} sm={12} lg={6} md={6} className="mrt-20">
              <div className="form-control">
                <label className="choose-label">
                 Product types
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                  {data?.additional_detail?.product_types?.map((option) => (
                    <div key={option.id} className="multi-select-item">
                      <div
                        className="multi-select-container selected"
                        onClick={() => handleMenuHighlightChange(option.id)}
                      >
                        <input
                          type="checkbox"
                          id={`option${option.id}`}
                          name="products"
                          value={option.id}
                          checked={selectedMenuHighlights.includes(option.id)}
                          onChange={() => handleMenuHighlightChange(option.id)}
                          style={{ display: "none" }} // Hide the checkbox
                        />
                        <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                        <label htmlFor={`option${option.id}`}>
                          {option.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40 mrt-20">
              <div className="form-control">
                <label className="choose-label">
                  Specialist Dietary Services
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                  {data?.additional_detail?.specialist_dietary_services?.map((option) => (
                    <div key={option.id} className="multi-select-item">
                      <div
                        className="multi-select-container selected"
                        onClick={() => handleDietaryServiceChange(option.id)}
                      >
                        <input
                          type="checkbox"
                          id={`dietaryOption${option.id}`}
                          name="dietaryServices"
                          value={option.id}
                          checked={selectedDietaryServices.includes(option.id)}
                          onChange={() => handleDietaryServiceChange(option.id)}
                          style={{ display: "none" }} // Hide the checkbox
                        />
                        <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                        <label htmlFor={`dietaryOption${option.id}`}>
                          {option.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>

           

          </Row>
        </div>
        <div>
          <h3 className="mt-20">Additional Details</h3>
          <hr />
          <Row className="news-reviews">
            <Col md={6} lg={6} sm={12}>
              <h5>Upcoming events</h5>
              <hr />
              {data?.additional_detail?.events?.map((event, index) => (
              // {events?.map((event, index) => (
                <div
                  key={event.id}
                  className={index % 2 === 0 ? "div1" : "div2"}
                >
                  <p>
                    <a
                      href={event.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {event.title}
                    </a>
                  </p>
                </div>
              ))}
            </Col>

            <Col md={6} lg={6} sm={12}>
              <h5>Online reviews</h5>
              <hr />
              {/* {reviews?.map((review, index) => ( */}
              {data?.additional_detail?.reviews?.map((review, index) => (
                <div
                  key={review.id}
                  className={index % 2 === 0 ? "div1" : "div2"}
                >
                  <p>
                    <img
                      src={
                        review.platform_name === "Google Reviews"
                          ? GoogleImg
                          : Trustpilot
                      }
                      alt={review.platform_name}
                    />
                    <a
                      href={review.review_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {review.platform_name}
                    </a>
                  </p>
                </div>
              ))}
            </Col>
          </Row>
        </div>
        <div>
        <h3 className="mt-20">Ecosystem Criteria</h3>
        <hr />
          <Row>
            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
              <div className="form-control">
                <label className="choose-label">
                 Operational status
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                {data?.ecosystem_criteria?.ce_operational_status?.map((option) => (
                    <div key={option.id} className="multi-select-item">
                      <div
                        className="multi-select-container selected" 
                        onClick={() => handleFeatureChangeEco(option.id)}
                      >
                        <input
                          type="checkbox"
                          id={`option${option.id}`}
                          name="services"
                          value={option.id}
                          checked={selectedFeatures.includes(option.id)}
                          onChange={() => handleFeatureChangeEco(option.id)}
                          style={{ display: "none" }}
                        />
                        <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                        <label
                          htmlFor={`option${option.id}`}
                          title={option.name} // Show full name on hover
                        >
                          {option.name.length > 20
                            ? `${option.name.substring(0, 20)}...`
                            : option.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
              <div className="form-control">
                <label className="choose-label">
                  Features
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                  {data?.ecosystem_criteria?.ce_features?.map((option) => (
                    <div key={option.id} className="multi-select-item">
                      <div
                        className="multi-select-container selected"
                        onClick={() => handleProcductChange(option.id)}
                      >
                        <input
                          type="checkbox"
                          id={`option${option.id}`}
                          name="services"
                          value={option.id}
                          checked={selectedProducts.includes(option.id)}
                          onChange={() => handleProcductChange(option.id)}
                          style={{ display: "none" }}
                        />
                        <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                        <label htmlFor={`option${option.id}`}>
                          {option.name.length > 20
                            ? `${option.name.substring(0, 20)}...`
                            : option.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
            <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
              <div className="form-control">
                <label className="choose-label">
                  Specialist Dietary Services
                  <span className="red-star">*</span>
                </label>
                <br />
                <div className="multi-select-wrapper">
                {data?.ecosystem_criteria?.ce_specialist_dietary_services?.map((option) => (
                    <div key={option.id} className="multi-select-item">
                      <div
                        className="multi-select-container selected"
                        onClick={() => handleDietryServiceChange(option.id)}
                      >
                        <input
                          type="checkbox"
                          id={`option${option.id}`}
                          name="services"
                          value={option.id}
                          checked={selectedDietryService.includes(option.id)}
                          onChange={() => handleDietryServiceChange(option.id)}
                          style={{ display: "none" }}
                        />
                        <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                        <label htmlFor={`option${option.id}`}>
                          {option.name.length > 20
                            ? `${option.name.substring(0, 20)}...`
                            : option.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="see-more">See more..</div> */}
            </Col>
          </Row>
       
        </div>
      </form>
    </div>
  );
}

export default CafeDetails;
