import React, { useState } from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import { Link } from "react-router-dom";
import AddCategoryMOdal from "../Components/AddchatCategory";
import AddChatTopicModal from "../Components/AddChatTopic";
import { useGetAllcategoriesQuery } from "../Services/CommunityApi";

function ContactInfo() {
  const { data, isLoading, error, refetch } = useGetAllcategoriesQuery(); // Fetching data from backend
  const [showCategoryModal, setshowCategoryModal] = useState(false);
  const [showChatTopicModal, setshowChatTopicModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State to store the search term

  const handleCloseCateroryModal = () => {
    setshowCategoryModal(false);
  };

  const handleShowCategoryModal = () => {
    setshowCategoryModal(true);
  };

  const handleCloseTopicModal = () => {
    setshowChatTopicModal(false);
  };

  const handleShowTopicModal = () => {
    setshowChatTopicModal(true);
  };

  // Function to handle search input changes
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Check if data is still loading or there's an error
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Filtered categories based on search input
  const filteredCategories = data?.data.filter((category) =>
    category.category_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h3>Chat</h3>
      <div className="table-wrapper-div notice-board">
        <div className="search-wrapper">
          <div className="view-user-detail">
            <div className="button-div">
              <h5>Categories</h5>
            </div>
          </div>
          <div className="search-div">
            <div className="form-control-input">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm} // Bind input to search term state
                onChange={handleSearch} // Handle search input change
              />
              <img src={SearchImg} alt="Search" className="search-img" />
            </div>
            <button className="btn-primary" onClick={handleShowCategoryModal}>
              Add category
            </button>
            <button className="btn-primary" onClick={handleShowTopicModal}>
              Add your topic
            </button>
          </div>
        </div>

        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Category name</th>
                <th scope="col">No of documents</th>
                <th scope="col">Last comment</th>
                <th scope="col">Author</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {filteredCategories.length > 0 ? (
                filteredCategories.map((category) => (
                  <tr key={category.id}>
                    <td className="clr-bold">{category.category_name}</td>
                    <td>{category.number_of_posts}</td>
                    <td>{category.last_comment || "No comments yet"}</td>
                    <td>{category.last_comment_author || "N/A"}</td>
                    <td>
                    <Link to={`/community-chat-view/${category.id}`}>
                        <button className="btn-outline">View</button>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No categories found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <AddCategoryMOdal show={showCategoryModal} handleCloseCateroryModal={handleCloseCateroryModal} refetch={refetch} />
        <AddChatTopicModal show={showChatTopicModal} handleCloseTopicModal={handleCloseTopicModal} />
      </div>
    </div>
  );
}

export default ContactInfo;
