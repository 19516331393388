
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_ARTICLE_SERVER;

export const articleapi = createApi({
  reducerPath: 'websiteapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('AdminAccessToken');
      // console.log('Access Token:', token); 
      const noAuthEndpoints = ['submitLogin'];

      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitArticle: builder.mutation({
        query: (newPost) => ({
          url: 'api/admin/create-blog',
          method: 'POST',
          body: newPost,
        }),
      }),
      getAllarticles: builder.query({
        query: () => `api/admin/admin-list-articles`,
      }),
      getContactinfo: builder.query({
        query: (selectedStatus) => {
          // Check if selectedStatus is provided
          const baseUrl = `api/admin/list-contacts`;
          const queryParams = selectedStatus ? `?interest=${selectedStatus}` : "";
          
          return `${baseUrl}${queryParams}`;
        },
      }),
      getContactinfodetails: builder.query({
        query: ({id}) => `api/admin/contacts/${id}`,
      }),
      contactinforeply: builder.mutation({
        query: ({ message, id }) => ({
          url: `api/admin/contacts/${id}/replay`,
          method: 'POST',
          body: { message }, // Ensure the body contains the correct field 'message'
        }),
      }),
      deleteArticle: builder.mutation({
        query: (id) => ({
          url: `api/admin/articles-delete/${id}`,
          method: 'DELETE',
        }),
      }),
      getViewsinglearticle: builder.query({
        query: (id) => `api/admin/articles/${id}`,
      }),
      editArticle: builder.mutation({
        query: ({data, id}) => ({
          url: `api/admin/articles-edit/${id}`,
          method: 'POST',
          body: data,
        }),
      }),
  }),
  
});

export const {
//   useSubmitLoginMutation,
  useSubmitArticleMutation,
  useGetAllarticlesQuery,
  useGetContactinfoQuery,
  useGetContactinfodetailsQuery,
  useContactinforeplyMutation, useDeleteArticleMutation,
  useGetViewsinglearticleQuery,
  useEditArticleMutation
} = articleapi;
