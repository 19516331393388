import React from 'react';
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Star from "../Assets/Images/trupoilt.svg"

function Footer() {
  return (
    <footer className="footer-dashboard mt-auto py-3">
      <div className="container-fluid">
      <Row>
      
        <Col sm={12} lg={12} xs={12} md={12}>
        <div className='right-sec'>
       <a href={`${process.env.REACT_APP_FRONT_CORE_URL}article`} target="_blank">Article</a>
       <a href={`${process.env.REACT_APP_FRONT_CORE_URL}contact-us`} target="_blank">Contact Us</a>
        <a href="https://www.trustpilot.com/review/clevercafecompany.com.au" target='_blank'><img src={Star} alt='Star' className='trustpiolit'/>Trustpilot</a>
        </div>
        </Col>
      </Row>
      </div>
    </footer>
  );
}

export default Footer;
