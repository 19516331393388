import React, { useState, useEffect } from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import FilterImg from "../Assets/Images/Filters lines.svg";
import { useGetNoticebaordcategoriesQuery, useGetNoticebaordallQuery } from "../Services/CommunityApi";
import { Link } from "react-router-dom";
import OrImg from "../Assets/Images/or-img.svg";
import Loader from "../Assets/Images/loader.gif"; // Assuming you have a loader image or you can use any spinner component

function ContactInfo() {
  const { data: categories, isLoading: isCategoriesLoading, error } = useGetNoticebaordcategoriesQuery();
  const [activeButton, setActiveButton] = useState("Job Listings");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  // When the categories data is available, find the "Job Listings" category and set its ID
  useEffect(() => {
    if (categories?.data?.length > 0) {
      const jobListingsCategory = categories.data.find((category) => category.name === "Job Listings");
      if (jobListingsCategory) {
        setSelectedCategoryId(jobListingsCategory.id); // Set the ID for "Job Listings"
      }
    }
  }, [categories]);

  // Pass the selectedCategoryId to the useGetNoticebaordallQuery hook
  const { data: alldata, isLoading: isAllDataLoading , refetch} = useGetNoticebaordallQuery({selectedCategoryId, selectedStatus});

  const handleClick = (buttonName, id) => {
    setActiveButton(buttonName);
    setSelectedCategoryId(id); // Set the category ID based on the button clicked
  };

  // Filter data based on search term
  const filteredData = alldata?.filter((details) =>
    (details?.business_name?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
    (details?.name?.toLowerCase() || "").includes(searchTerm.toLowerCase())
  );
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setIsDropdownOpen(false); 
    console.log("Selected status:", status); 
    refetch();
  };

  return (
    <div>
      <h3>Noticeboard</h3>
      <div className="table-wrapper-div notice-board">
        <div className="search-wrapper">
          <div className="view-user-detail">
            <div className="button-div">
              {/* Show a loader while categories are loading */}
              {isCategoriesLoading ? (
                <img src={Loader} alt="Loading..." />
              ) : (
                categories?.data?.map((comment) => (
                  <button
                    key={comment.id} // Ensure a unique key
                    className={`button ${activeButton === comment.name ? "active" : ""}`}
                    onClick={() => handleClick(comment.name, comment.id)}
                  >
                    {comment.name}
                  </button>
                ))
              )}
            </div>
          </div>
          <div className="search-div">
            <div className="form-control-input">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm} // Bind input value to searchTerm state
                onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm state on input change
              />
              <img src={SearchImg} alt={SearchImg} className="search-img" />
            </div>
            <div className="filter-main-div">
                <button className="filter-btn" onClick={toggleDropdown}>
                  <img src={FilterImg} alt="Filter" /> Filters
                </button>
                {isDropdownOpen && (
              <div className="dropdown-menu-filter">
                <p className="reset-filter text-end">Reset</p>
                <div onClick={() => handleStatusClick("pending")} className="dropdown-item-status">Pending</div>
                <div onClick={() => handleStatusClick("approved")} className="dropdown-item-status">Approved</div>
                <div onClick={() => handleStatusClick("rejected")} className="dropdown-item-status">Rejected</div>
              </div>
            )}
            </div>
          </div>
        </div>

        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Business name</th>
                <th scope="col">Name</th>
                <th scope="col">Added on</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {/* Show a loader while data is loading */}
              {isAllDataLoading ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <img src={Loader} alt="Loading..." />
                  </td>
                </tr>
              ) : (
                filteredData?.map((details) => (
                  <tr key={details?.id}>
                    <td className="clr-bold">
                      <span className="yellow-span-text">
                        {/* <img src={OrImg} alt="OrImg" /> */}
                        
                      </span>{" "}
                      {details?.business_name}
                    </td>
                    <td>{details?.name}</td>
                    <td>{details?.created_at}</td>
                    <td>
                      <button className={details?.status === "approved" ? "approved-status" : "pending-status"}>
                        <p className={details?.status === "approved" ? "green-circle" : "red-circle"}></p>
                        <span>{details?.status.charAt(0).toUpperCase() + details?.status.slice(1)}</span>
                      </button>
                    </td>
                    <td>
                      {/* <Link to="/notice-board-view"> */}
                      <Link to={`/notice-board-view/${details.id}`}>
                        <button className="btn-outline">View</button>
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
