import React, { useState } from "react";
import BackIcon from "../Assets/Images/back-icon.svg";
import { useNavigate,useParams } from "react-router-dom";
import "../Styles/Userdetail.css";
import Tab1 from "../Components/UserFoodAccountDetails";
import Tab2 from "../Components/UseFoodMediaGallery";
import Tab4 from "../Components/AccountSettingsDetails";
import Tab5 from "../Components/Support";
import { useGetAllarticlesQuery } from "../Services/ArticleApi";
import { useGetfooduserdetailsQuery } from "../Services/UsersApi";

function UserLocationDetails() {
  const { id } = useParams();
  const { data, isLoading, error } = useGetfooduserdetailsQuery({ id });

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  const [activeTab, setActiveTab] = useState(0);

  // Mapping of tabs to their respective components
  const tabPages = [
    { title: "Details", component: <Tab1 /> },
    { title: "Media Gallery", component: <Tab2 userId={data?.branch?.user} /> },
    { title: "Account Settings", component: <Tab4 userId={data?.branch?.user}/> },
   
  ];

  return (
    <div>
      <div className="back-page-icon">
        <img src={BackIcon} alt="Back" onClick={handleBackClick} />
      </div>
      <div className="content-wrapper-bg">
        <div>
          {/* Tab buttons with circles */}
          <div className="tab-buttons">
            {tabPages.map((tab, index) => (
              <div
                key={index}
                className={`tab ${activeTab === index ? "active" : ""}`}
              >
                <button
                  className={activeTab === index ? "active-tab" : ""}
                  onClick={() => setActiveTab(index)}
                >
                  <span className="circle">{index + 1}</span>
                  {tab.title}
                </button>
              </div>
            ))}
          </div>

          <div className="tab-content">{tabPages[activeTab].component}</div>
        </div>
      </div>
    </div>
  );
}

export default UserLocationDetails;
