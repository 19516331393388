import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import FileUpload from "../Assets/Images/file-upload.svg";
import "../Styles/RoleManagement.css";
import "../Styles/Artcle.css";
import { useSubmitArticleMutation } from "../Services/ArticleApi";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useGetAllmaterialsQuery, useGetSubCategorybyMaterialIdQuery, useGetBusinessmaterialsubcategoryQuery, useUploadBusinessMaterialMutation, useGetMatrialTYpeAdminQuery } from "../Services/SecondaryApi";
import Swal from 'sweetalert2';

function RoleManagementRegister({ show, handleCloseAddBusinessMaterialModal, refetch }) {
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedThumbnailImage, setSelectedThumbnailImage] = useState(null);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitLogin, { isLoading, isError, isSuccess, error }] = useUploadBusinessMaterialMutation();
  const [categoryId, setCategoryId] = useState("")
  const { data: category } = useGetAllmaterialsQuery();
  const { data: subcategory } = useGetSubCategorybyMaterialIdQuery({ id: categoryId });
  const { data: materialTypes } = useGetMatrialTYpeAdminQuery();

  console.log("category", subcategory)
  const handleCloseAddNewModal = () => {
    if (!loading) handleCloseAddBusinessMaterialModal();
  };

  const [formData, setFormData] = useState({
    category_name: '',
    sub_category_name: "",
    material_type: "",
    logo: null,
    thumbnailImage: null,
    title: ""
  });
  const [errors, setErrors] = useState({});



  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess])

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setSelectedLogo(file);

    // Clear any existing validation errors for logo as soon as a new file is selected
    setErrors((prevErrors) => ({ ...prevErrors, logo: '' }));

    if (file) {
      // Validate file type to ensure it's a PDF
      if (file.type !== 'application/pdf') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          logo: 'Only PDF files are allowed',
        }));
      }
      // Validate file size to be less than 1 MB (1 MB = 1048576 bytes)
      else if (file.size > 1048576) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          logo: 'File size must be less than 1 MB',
        }));
      } else {
        // Clear errors if the file is valid and update the form data
        setErrors((prevErrors) => ({ ...prevErrors, logo: '' }));
        setFormData({ ...formData, logo: file });
      }
    }
  };

  const handleChangeThumbnailImage = (e) => {
    const file = e.target.files[0];
    setSelectedThumbnailImage(file);

    // Clear any existing validation errors for the logo as soon as a new file is selected
    setErrors((prevErrors) => ({ ...prevErrors, thumbnailImage: '' }));

    if (file) {
      // Validate file type to ensure it's an image
      const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      if (!validImageTypes.includes(file.type)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          thumbnailImage: 'Only image files (PNG, JPEG) are allowed',
        }));
      }
      // Validate file size to ensure it’s less than 0.3MB
      else if (file.size > 307200) { // 0.3MB = 307200 bytes
        setErrors((prevErrors) => ({
          ...prevErrors,
          thumbnailImage: 'File size must be less than 0.3MB',
        }));
      } else {
        // Clear errors if the file is valid and update the form data
        setErrors((prevErrors) => ({ ...prevErrors, thumbnailImage: '' }));
        setFormData({ ...formData, thumbnailImage: file });
      }
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear the error for category_name if there’s an existing error
    if (name === 'category_name' && errors.category_name) {
      setErrors((prevErrors) => ({ ...prevErrors, category_name: '' }));
    }
    if (name === 'sub_category_name' && errors.sub_category_name) {
      setErrors((prevErrors) => ({ ...prevErrors, sub_category_name: '' }));
    }
    if (name === 'material_type' && errors.material_type) {
      setErrors((prevErrors) => ({ ...prevErrors, material_type: '' }));
    }
    if (name === 'title' && errors.category_name) {
      setErrors((prevErrors) => ({ ...prevErrors, title: '' }));
    }

  };

  const handleChangeCategory = (e) => {
    const { name, value } = e.target;
    setCategoryId(e.target.value);
    setFormData({ ...formData, [name]: value });

    // Clear the error for category_name if there’s an existing error
    if (name === 'category_name' && errors.category_name) {
      setErrors((prevErrors) => ({ ...prevErrors, category_name: '' }));
    }
  };

  const validateForm = () => {
    const errors = {};

    // Category name validation
    if (!formData.category_name) {
      errors.category_name = 'Category name is required';
    } else if (formData.category_name.length > 50) {
      errors.category_name = 'Category name must be less than 50 characters';
    } else if (/[^a-zA-Z0-9 ]/.test(formData.category_name)) {
      errors.category_name = 'No special characters are allowed';
    }
    if (!formData.sub_category_name) {
      errors.sub_category_name = 'Subcategory is required';
    }
    if (!formData.material_type) {
      errors.material_type = 'Material type is required';
    }

    if (!formData.title) {
      errors.title = 'Title is required';
    }


    if (!selectedLogo) {
      errors.logo = 'Please choose file';
    } else {
      // Additional file type and size validations are handled in handleLogoChange
      if (errors.logo) {
        errors.logo = 'Only JPG, PNG, and JPEG files are allowed, and size must be less than 1 MB';
      }
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };


  // const handleLogoChange = (e) => {
  //     const file = e.target.files[0];
  //     if (handleFileValidation(file, ["jpg", "png", "svg"])) {
  //         setSelectedLogo(file);
  //     } else {
  //         alert("Invalid file type. Only .jpg, .png, and .svg files are allowed.");
  //         setSelectedLogo(null);
  //     }
  // };

  const handleFileValidation = (file, allowedTypes) => {
    const fileType = file.type.split("/")[1].toLowerCase();
    return allowedTypes.includes(fileType);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);

    const data = new FormData();
    data.append('title', formData.title);
    data.append('business_material_id', formData.category_name);
    data.append('subcategory_id', formData.sub_category_name);
    data.append('material_type_id', formData.material_type);

    if (selectedLogo) {
      data.append('file', selectedLogo);
    }
    if (selectedThumbnailImage) {
      data.append('thumbnail', selectedThumbnailImage);
    }
    try {
      const response = await submitLogin(data);
      if (response.error) {
        // alert("Submission failed!");
        Swal.fire({
          icon: 'error',
          title: 'Submission Failed',
          text: 'An error occurred. Please try again.',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      } else {
        // alert("Added successfully!");
        Swal.fire({
          icon: 'success',
          title: 'Submission Successful!',
          text: 'Added successfully!',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
        setFormData({
          category_name: "",
        });
        setSelectedLogo(null);

        handleCloseAddNewModal();
        refetch();
      });
      }
    } catch (error) {
      // console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      <Modal
        show={show}
        onHide={handleCloseAddBusinessMaterialModal}
        backdrop="true"
        dialogClassName="add-new-modal"
      >
        <Modal.Body>
          <h4>Add business material</h4>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Category name <span className="red-star">*</span></label>
                  <br />
                  <select name="category_name" id="category_name" value={formData.category_name}
                    onChange={handleChangeCategory}>
                    <option>Select Category name</option>
                    {category?.map((category) => (
                      <option value={category.id}>{category.business_materials}</option>
                    ))}
                  </select>
                  {errors.category_name && <p className="error-text">{errors.category_name}</p>}
                </div>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Subcategory <span className="red-star">*</span></label>
                  <br />
                  <select name="sub_category_name" id="sub_category_name" value={formData.sub_category_name}
                    onChange={handleChange}>
                    <option>Select Subcategory</option>
                    {subcategory?.data?.subcategories?.map((category) => (
                      <option value={category.id}>{category.subcategory_title}</option>
                    ))}
                  </select>
                  {errors.sub_category_name && <p className="error-text">{errors.sub_category_name}</p>}
                </div>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Material type <span className="red-star">*</span></label>
                  <br />
                  <select name="material_type" id="material_type" value={formData.material_type}
                    onChange={handleChange}>
                    <option>Select Material type</option>
                    {materialTypes?.data?.map((category) => (
                      <option value={category.id}>{category.name}</option>
                    ))}
                  </select>
                  {errors.material_type && <p className="error-text">{errors.material_type}</p>}
                </div>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                    Title<span className="red-star">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    name="title"
                    placeholder="Enter Title"
                    value={formData.title}
                    onChange={handleChange}
                    disabled={loading}
                  />
                  {errors.title && <p className="error-text">{errors.title}</p>}
                </div>
              </Col>

              <Col xs={12} sm={12} lg={12} md={12}>
                <label>Upload Thumbnail image</label>
                <div className="file-upload-container d-flex">
                  <label className="file-upload-label">
                    <input
                      type="file"
                      className="file-upload-input"
                      onChange={handleChangeThumbnailImage}
                      disabled={loading}
                    />
                    <div>
                      <img src={FileUpload} alt="FileUpload" />
                    </div>
                    <div>
                      <span className="file-upload-text">
                        {formData.thumbnailImage ? formData.thumbnailImage.name : 'Please select image'}
                      </span>
                      <span className="format-text">(Only supports .png, .jpg, .jpeg, .svg files)</span>
                    </div>
                  </label>
                </div>
                {errors.thumbnailImage && <p className="error-text">{errors.thumbnailImage}</p>}
              </Col>

              <Col xs={12} sm={12} lg={12} md={12}>
                <label>Upload</label>
                <div className="file-upload-container d-flex">
                  <label className="file-upload-label">
                    <input
                      type="file"
                      className="file-upload-input"
                      onChange={handleLogoChange}
                      disabled={loading}
                    />
                    <div>
                      <img src={FileUpload} alt="FileUpload" />
                    </div>
                    <div>
                      <span className="file-upload-text">
                        {formData.logo ? formData.logo.name : 'Please select a file'}
                      </span>
                      <span className="format-text">(Only supports .pdf files)</span>
                    </div>
                  </label>
                </div>
                {errors.logo && <p className="error-text">{errors.logo}</p>}
              </Col>


            </Row>
            <Row>
              <div className="btn-action">
                <button
                  type="button"
                  className="btn-outline-black"
                  onClick={handleCloseAddNewModal}
                  disabled={loading} // Disable cancel button during loading
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={loading} // Disable submit button during loading
                >
                  {loading ? "Submitting..." : "Add"}
                </button>
              </div>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RoleManagementRegister;
