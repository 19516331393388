import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/EcosystemNotification.css";
import Select from "react-select";
import {
  useGetUsertypesQuery,
  useGetUseraccounttypesQuery,
  useGetOperationalstatusQuery,
  useNotificationfromadminMutation,
} from "../Services/UsersApi";
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function EcosystemNotification() {
  const { data: userTypesData } = useGetUsertypesQuery();
  console.log("userTypesData", userTypesData)
  const userTypes = userTypesData?.map((userType) => ({
    label: userType.name,
    value: userType.id,
  })) || [];

  const { data: accountTypesData } = useGetUseraccounttypesQuery();
  const accountTypes = accountTypesData?.map((accountType) => ({
    label: accountType.name,
    value: accountType.id,
  })) || [];

  const { data: opearationalStatusData } = useGetOperationalstatusQuery();
  const opearationalStatus = opearationalStatusData?.map((opearational) => ({
    label: opearational.name,
    value: opearational.id,
  })) || [];

  const categories = [
    { label: "Industry update", value: "Industry update" },
    { label: "Announcements", value: "Announcements" },
    { label: "Mentorship", value: "Mentorship" },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedAccountOptions, setSelectedAccountOptions] = useState([]);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [selectedOperationalStatusOptions, setSelectedOperationalStatusOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState("");
  const [showAdditionalSelect, setShowAdditionalSelect] = useState(false);
  const [showAdditionalSelectIndustry, setShowAdditionalSelectIndustry] = useState(false);
  const [selectedMentorshipOptions, setSelectedMentorshipOptions] = useState([]);
  const [selectedIndustryUpdateOptions, setSelectedIndustryUpdateOptions] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionValue, setDescriptionValue] = useState('');
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);

  const [notificationfromadmin, { isLoading: isSubmitting }] = useNotificationfromadminMutation();

  const handleMultiSelectChange = (options) => {
    setSelectedOptions(options);
    setIsDivVisible(options.some((option) => option.value === 1));
  };

  const handleMultiAccountChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "All")) {
      setSelectedAccountOptions(accountTypes.filter((option) => option.value !== "All"));
    } else {
      setSelectedAccountOptions(selectedOptions);
    }
  };

  const handleMultiOperationalStatusChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "All")) {
      setSelectedOperationalStatusOptions(opearationalStatus.filter((option) => option.value !== "All"));
    } else {
      setSelectedOperationalStatusOptions(selectedOptions);
    }
  };
  const [mentorshipSubcategories, setMentorshipSubcategories] = useState(false);
  const [industrySubcategories, setindustrySubcategories] = useState(false);
  const [ecosystemSubcategories, setEcosystemSubCategories] = useState(false);

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCategories(selectedValue);
    setMentorshipSubcategories(selectedValue === "Mentorship");
    setindustrySubcategories(selectedValue === "Industry");
    setEcosystemSubCategories(selectedValue === "Ecosystem")
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!descriptionValue.trim()) {
      setIsDescriptionValid(false);
      return
    } else {
      setIsDescriptionValid(true);
      try {
        const notificationData = {
          user_type: selectedOptions.map((opt) => opt.value),
          account_type: selectedAccountOptions.map((opt) => opt.value),
          operational_status: selectedOperationalStatusOptions.map((opt) => opt.value),
          category: selectedCategories,
          title,
          description: descriptionValue,
          mentorship_options: selectedMentorshipOptions.map((opt) => opt.value),
          industry_update: selectedIndustryUpdateOptions.map((opt) => opt.value).join(", "),
        };

        const response = await notificationfromadmin(notificationData).unwrap();
        // alert("Notification submitted successfully:", response);
        Swal.fire({
          icon: 'success',
          title: 'Submission Successful!',
          text: 'Notification submitted successfully:', response,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          setSelectedOptions([]);
          setSelectedAccountOptions([]);
          setSelectedOperationalStatusOptions([]);
          setSelectedCategories('');
          setSelectedMentorshipOptions([]);
          setSelectedIndustryUpdateOptions([]);
          setTitle('');
          setDescription('');
          setDescriptionValue('');
        });
      }
      catch (error) {
        console.log("error", error);

        let errorMessage;

        // Check if status is 403 and set the appropriate message
        if (error.status === 403) {
          errorMessage = "Too many attempts. Please try again later.";
        } else {
          errorMessage = error.data?.message || error.data?.detail || error.data?.error || "Failed to submit Ecosystem notification";
        }

        Swal.fire({
          icon: 'error',
          title: 'Submission failed!',
          text: errorMessage,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }

    }


  };

  const handleCancel = () => {
    setSelectedOptions([]);
    setSelectedAccountOptions([]);
    setSelectedOperationalStatusOptions([]);
    setSelectedCategories('');
    setSelectedMentorshipOptions([]);
    setSelectedIndustryUpdateOptions([]);
    setTitle('');
    setDescription('');
    setDescriptionValue('');
  };
  return (
    <div className="ecosystem-notification">
      <h3>Ecosystem Notification</h3>
      <div className="ecosystem-notification-form">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Type of user<span className="red-star">*</span></label>
                <br />
                <Select
                  isMulti
                  value={selectedOptions}
                  onChange={handleMultiSelectChange}
                  options={userTypes}
                  placeholder="Select"
                  name="directory_listing" required
                />
              </div>
            </Col>
            {isDivVisible && (
              <>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <div className="form-control">
                    <label>Type of account<span className="red-star">*</span></label>
                    <br />
                    <Select
                      isMulti
                      value={selectedAccountOptions}
                      onChange={handleMultiAccountChange}
                      options={accountTypes}
                      placeholder="Select"
                      name="directory_listing"
                      required
                    />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <div className="form-control">
                    <label>Operational status<span className="red-star">*</span></label>
                    <br />
                    <Select
                      isMulti
                      value={selectedOperationalStatusOptions}
                      onChange={handleMultiOperationalStatusChange}
                      options={opearationalStatus}
                      placeholder="Select"
                      name="operational_status"
                      required
                    />
                  </div>
                </Col>
              </>
            )}
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className="form-control">
                <label>Category<span className="red-star">*</span></label>
                <br />
                <select name="category" onChange={handleCategoryChange} value={selectedCategories} required>
                  <option value="" selected>Select Category</option>
                  <option value="Mentorship">Mentorship</option>
                  <option value="Industry">Industry</option>
                  <option value="Ecosystem">Ecosystem</option>
                </select>
              </div>
            </Col>

            {mentorshipSubcategories && (
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="form-control">
                  <label>Sub category<span className="red-star">*</span></label>
                  <Select
                    options={[
                      { label: "Upcoming Programs", value: "Upcoming Programs" },
                      { label: "Upcoming Expert Speakers", value: "Upcoming Expert Speakers" },
                      { label: "Menu Reviews", value: "Menu Reviews" },
                    ]}
                    placeholder="Select an option"
                    isMulti
                    value={selectedIndustryUpdateOptions}
                    onChange={(options) => setSelectedIndustryUpdateOptions(options)}
                  />
                </div>
              </Col>
            )}

            {industrySubcategories && (
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="form-control">
                  <label>Sub category<span className="red-star">*</span></label>
                  <Select
                    required
                    options={[
                      { label: "⁠Wages & HR Compliance", value: "⁠Wages & HR Compliance" },
                      { label: "Regulatory Compliance", value: "Regulatory Compliance" },
                      { label: "Government Policies & Funding", value: "Government Policies & Funding" },
                      { label: "Consumer Law & Customer Rights", value: "Consumer Law & Customer Rights" },
                      { label: "Tax Compliance & Reporting", value: "Tax Compliance & Reporting" },
                      { label: "Workplace Safety Updates", value: "Workplace Safety Updates" },
                      { label: "Environmental Compliance", value: "Environmental Compliance" },
                      { label: "End-of-Year Planning", value: "End-of-Year Planning" },
                    ]}
                    placeholder="Select an option"
                    isMulti
                    value={selectedIndustryUpdateOptions}
                    onChange={(options) => setSelectedIndustryUpdateOptions(options)}
                  />
                </div>
              </Col>
            )}

            {ecosystemSubcategories && (
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="form-control">
                  <label>Sub category<span className="red-star">*</span></label>
                  <Select
                    required
                    options={[
                      { label: "Legislative Changes", value: "Legislative Changes" },
                      { label: "Compliance Alerts", value: "Compliance Alerts" },
                      { label: "Benchmarking Reports", value: "Benchmarking Reports" },
                    ]}
                    placeholder="Select an option"
                    isMulti
                    value={selectedIndustryUpdateOptions}
                    onChange={(options) => setSelectedIndustryUpdateOptions(options)}
                  />
                </div>
              </Col>
            )}
            <Col sm={12} md={12} lg={12}>
              <div className="form-control">
                <label>Title<span className="red-star">*</span></label>
                <br />
                <input
                  required
                  type="text"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <div className="form-control">
                <label>Description<span className="red-star">*</span></label>
                <br />
                {/* <textarea
                  rows={3}
                  placeholder="Text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={descriptionValue}
                  config={{
                    removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed'],
                    toolbar: [
                      'bold', 'italic', 'link', 'bulletedList', 'numberedList'
                    ],
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescriptionValue(data);
                    if (!isDescriptionValid && data.trim()) {
                      setIsDescriptionValid(true);
                    }
                  }}
                />
                {!isDescriptionValid && (
                  <p style={{ color: 'red' }}>This field is required.</p>
                )}
              </div>
            </Col>
          </Row>
          <Row className="text-end">
            <div className="btn-action-div">
              <button className="btn-outline-black" type="button" onClick={handleCancel}>Cancel</button>
              <button className="btn-primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default EcosystemNotification;
