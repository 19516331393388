import { useState } from "react";
import BackIcon from "../Assets/Images/back-icon.svg";
import { useNavigate } from "react-router-dom";
import "../Styles/SuportManagement.css";
import DefaultImg from "../Assets/Images/default-profile-img.png";
import { useGetTicketdetailsdisplayQuery } from "../Services/SupportApi";
import { useParams } from 'react-router-dom';

function ContactInfoDetail() {
  const { id } = useParams();
  const { data, isLoading, error } = useGetTicketdetailsdisplayQuery(id);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [showForm, setShowForm] = useState(false);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setMessage("");
  };

  const handleReplyClick = () => {
    setShowForm((prevState) => !prevState);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching ticket details</div>;
  }

  const ticket = data?.ticket;

  return (
    <div className="support-info-detail">
      <div className="back-page-icon">
        <p>
          <img src={BackIcon} alt="Back" onClick={handleBackClick} /> {ticket?.ticket_code}
        </p>
      </div>
      <div className="chat-detail">
        <p className="user-name">{ticket?.category_name}</p>
        <p className="date">{ticket?.subcategory?.subcategory_name}</p>
        <p className="content">{ticket?.description}</p>
        <hr />
        <div className="reply-chat">
          <div className="d-flex">
            <img
              src={ticket?.posted_by?.profile_image || DefaultImg}
              alt="Profile"
              className="profile-img"
            />
            <div className="mrl-15">
              <p className="user-name">{ticket?.posted_by?.name}</p>
              <p className="date">{ticket?.posted_date}</p>
            </div>
          </div>
          <p className="description">{ticket?.description}</p>
          <hr />
          <div className="d-flex align-items-space-between">
            <p className="posted_date">Posted on {ticket?.posted_date}</p>
            <p className="reply-btn" onClick={handleReplyClick}>
              {/* Reply */}
            </p>
          </div>
          {showForm && (
            <form>
              <input
                type="text"
                placeholder="Type your message..."
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className="text-end">
                <button className="btn-outline" onClick={handleCancel}>
                  Cancel
                </button>
                <button className="btn-primary" type="submit">
                  Comment
                </button>
              </div>
            </form>
          )}
        </div>
        <div className="already-added-comments-support">
            {ticket?.comments.map((comment) => (
              <div key={comment.comment_id} className="comments-div">
                <div className="d-flex">
                  <div className="d-flex">
                    <div>
                      <img src={comment.comment_profile_image || DefaultImg} alt={comment.comment_author} width={40} height={40} />
                    </div>
                    <div className="profile">
                      <p className="person-name">{comment.posted_by.name}</p>
                      <p className="date">{comment.comment_date}</p>
                    </div>
                  </div>
                </div>
                <p className="comments pt-10">{comment.comment}</p>
                <hr />
                {comment.replies.length > 0 && (
                  <div className="sub-comments">
                    {comment.replies.map((reply) => (
                      <div key={reply.reply_id} className="br-l">
                        <div className="d-flex">
                          <div>
                            <p className="person-name">{reply.reply_author}</p>
                            <p className="date">{new Date(reply.reply_created_at).toLocaleString()}</p>
                          </div>
                        </div>
                        <p className="comments pt-10">{reply.reply_content}</p>
                        <hr />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
      </div>
    </div>
  );
}

export default ContactInfoDetail;
