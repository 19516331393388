import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import BackIcon from "../Assets/Images/back-icon.svg";
import ActionIcon from "../Assets/Images/action_icon.svg";
import Person1 from "../Assets/Images/default-profile-img.png";
import { useGetUserchatcomentslistQuery, useAddPostcommentMutation } from "../Services/CommunityApi";
import "../Styles/Chat.css";
import Swal from 'sweetalert2';

function Chat() {
  const { id } = useParams();
  const { data, isLoading, error, refetch } = useGetUserchatcomentslistQuery(id);
  const [submitForm] = useAddPostcommentMutation();

  const [formData, setFormData] = useState({
    comment: "",
  });

  const [loading, setLoading] = useState(false); // Loading state

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ensure comment is not empty
    if (!formData.comment.trim()) return;

    let postData = {
      post_id: id,
      comment: formData.comment,
    };

    setLoading(true); // Start loading
    try {
      const response = await submitForm(postData).unwrap();
      setFormData({ comment: "" });
  
      // Show success popup
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Comment added successfully!',
        confirmButtonText: 'OK',
      });
  
      // Refetch comments after adding a new comment
      refetch();
    }catch (err) {
      // console.error("Failed to submit form:", err);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to add the comment. Please try again later.',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoading(false); // End loading
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading comments</div>;

  const { post } = data;

  return (
    <div className="chat-list chat-list-view">
      <div className="chat-list-tab">
        <Row className="category-search">
          <Col xs={12}>
            <h5>
              <Link onClick={goBack}>
                <img src={BackIcon} alt="BackIcon" />
              </Link>
              {post.post_title}
            </h5>
          </Col>
        </Row>
        <div className="chat-list-reply-div">
          <div className="comment-section">
            <div className="d-flex">
              <div className="d-flex">
                <div>
                  <img src={Person1} alt="Person1" width={40} height={40} />
                </div>
                <div className="profile">
                  <p className="person-name">{post.author_name}</p>
                  <p className="date">{new Date(post.post_created_at).toLocaleString()}</p>
                </div>
              </div>
              <img src={ActionIcon} alt="ActionIcon" />
            </div>
            <h5>{post.post_title}</h5>
            <p className="comments">{post.post_content}</p>
            <hr />
            <form onSubmit={handleSubmit}>
              <input 
                type="text" 
                name="comment" 
                placeholder="Type your message here ..." 
                value={formData.comment} 
                onChange={handleChange} 
              />
              <Row className="action-btn">
                <Col xs={12} className="text-end">
                  <button 
                    type="button" 
                    className="btn-outline-black" 
                    onClick={() => setFormData({ comment: "" })}
                    disabled={loading} // Disable when loading
                  >
                    Cancel
                  </button>
                  <button 
                    type="submit" 
                    className="primary-btn" 
                    disabled={loading} // Disable when loading
                  >
                    {loading ? "Sending..." : "Comment"} {/* Show loading text */}
                  </button>
                </Col>
              </Row>
            </form>
          </div>

          <div className="already-added-comments">
            {post.comments.map((comment) => (
              <div key={comment.comment_id} className="comments">
                <div className="d-flex">
                  <div className="d-flex">
                    <div>
                      <img src={comment.comment_profile_image || Person1} alt={comment.comment_author} width={40} height={40} />
                    </div>
                    <div className="profile">
                      <p className="person-name">{comment.comment_author}</p>
                      <p className="date">{new Date(comment.comment_created_at).toLocaleString()}</p>
                    </div>
                  </div>
                  <img src={ActionIcon} alt="ActionIcon" />
                </div>
                <p className="comments pt-10">{comment.comment_content}</p>
                <hr />
                {comment.replies.length > 0 && (
                  <div className="sub-comments">
                    {comment.replies.map((reply) => (
                      <div key={reply.reply_id} className="br-l">
                        <div className="d-flex">
                          <div>
                            <p className="person-name">{reply.reply_author}</p>
                            <p className="date">{new Date(reply.reply_created_at).toLocaleString()}</p>
                          </div>
                          <img src={ActionIcon} alt="ActionIcon" />
                        </div>
                        <p className="comments pt-10">{reply.reply_content}</p>
                        <hr />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
