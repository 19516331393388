import React from "react";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import "../Styles/Navbar.css";
import "../Styles/Footer.css";
import "../Styles/Sidebar.css";
import "../Styles/Dashboard.css";
const Layout = ({ children }) => {
  return (
    <div className="dashboard-container">
    <Navbar />
    <div className="dashboard-content">
      <SideBar />
      <div className="dashboard-main-content">
        <div className="dashboard-content-layout"><div className="dashboard-content-wrapper">{children}</div></div>
      </div>
    </div>
    <Footer />
  </div>
  );
};

export default Layout;
