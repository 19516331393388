import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import "../Styles/RoleManagement.css";
import { useResetpasswordbyadminMutation } from "../Services/AdminApi";
import Swal from 'sweetalert2';

function RoleManagementResetModal({ show, handleCloseResetModal, roleId, email }) {
  const [password, setPassword] = useState(""); // State for password input
  const [resetPassword, { isLoading, isError, isSuccess }] = useResetpasswordbyadminMutation();

  const handleCloseAddNewModal = () => {
    handleCloseResetModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Call the mutation with the form data
      await resetPassword({ role_id: roleId, email, password }).unwrap();
  
      // alert(`Password reset successfull for role ID: ${roleId} and email: ${email}`);
      Swal.fire({
        icon: 'success',
        title: 'Submission Successful!',
        text: `Password reset successfull for role ID: ${roleId} and email: ${email}`,
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
    }).then(() => {
      // refetch();
      // console.log(`Password reset for role ID: ${roleId} and email: ${email}`);
      handleCloseAddNewModal(); 
    });
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error("Failed to reset password:", error);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleCloseAddNewModal}
        backdrop="true"
        dialogClassName="add-new-modal"
      >
        <Modal.Body>
          <h4>Reset Password</h4>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Email <span className="red-star">*</span></label>
                  <br />
                  <input 
                    value={email}
                    type="text" 
                    name="email" 
                    placeholder="Dianne.Bernhard@yahoo.com" 
                    readOnly 
                    required 
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Password <span className="red-star">*</span></label>
                  <br />
                  <input 
                    type="password" 
                    name="password" 
                    placeholder="*******" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} // Update password state
                    required 
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <div className="btn-action">
                <button type="button" className="btn-outline-black" onClick={handleCloseAddNewModal}>Cancel</button>
                <button type="submit" className="btn-primary" disabled={isLoading}>
                  {isLoading ? 'Resetting...' : 'Reset'}
                </button>
              </div>
            </Row>
            {isError && <div className="error-message">Failed to reset password. Please try again.</div>}
            {isSuccess && <div className="success-message">Password reset successfully!</div>}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RoleManagementResetModal;
