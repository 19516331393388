import React, { useState, useEffect } from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import { Row, Col } from "react-bootstrap";
import { useGetAllfslistingsQuery } from "../Services/UsersApi";
import OrImg from "../Assets/Images/or-img.svg";
import { Link } from "react-router-dom";
import FilterImg from "../Assets/Images/Filters lines.svg";

function UserManagement() {
  // State for search input and pagination
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1); // Pagination state
  const [pageSize] = useState(10); // Set page size if needed (optional)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  // Fetch users from the API using the query hook
  const { data, error, isLoading, refetch } = useGetAllfslistingsQuery({
    page: currentPage,
    searchTerm,
    selectedStatus
  });
  const [exportCsv, setExportCsv] = useState("");
  const { data: exportcsv, refetch: exportrefetchcsv, isSuccess: exportSuccess } = useGetAllfslistingsQuery({ page: "", searchTerm, exportCsv ,selectedStatus});

  const [isExporting, setIsExporting] = useState(false);
  const convertToCSV = (data) => {
    const ceUsers = data?.results || [];

    if (!ceUsers.length) return '';

    const headers = ['ID', 'Business name', 'Operational status', '	Location', 'Contact info', 'Added on', 'Status', 'Sub plan'];

    const csvRows = [
      headers.join(','), // Header row
      ...ceUsers.map(user => [
        user.id,
        user.business_name,
        user.email,
        user.location,
        user.contact_number,
        user.created_at,
        user.is_active,
        user.subscription_type,

      ].join(','))
    ];

    return csvRows.join('\n');
  };

  useEffect(() => {
    if (exportSuccess && isExporting && exportcsv) {
      try {
        const csvData = convertToCSV(exportcsv);
        const blobData = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blobData);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'food_listmanagement.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        setIsExporting(false);
      } catch (error) {
        console.error("Error exporting file:", error);
        setIsExporting(false);
      }
    }
  }, [exportSuccess, exportcsv, isExporting]);

  // State for managing data, pagination, and total listings
  const [usersData, setUsersData] = useState([]);
  const [totalListings, setTotalListings] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);

  // Effect to handle data update when the query result changes
  useEffect(() => {
    if (data) {
      setUsersData(data.results);
      setTotalListings(data.count); // Set the total listings count
      setNextPage(data.next);
      setPrevPage(data.previous);
    }
  }, [data]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  // Calculate total pages
  const totalPages = Math.ceil(totalListings / pageSize);

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to page 1 when search term changes
  };

  // Function to export users to CSV (remains unchanged)
  const exportToCSV = () => {
    const csvHeaders = [
      "Business Name",
      "User ID",
      "Email",
      "Contact Number",
      "Location",
      "Subscription Type",
      "Created At",
    ];
    const csvRows = usersData.map((user) => {
      const businessName = user.business_name || "N/A";
      const userId = user.user;
      const email = user.email || "N/A";
      const contactNumber = user.contact_number || "N/A";
      const location = user.location || "N/A";
      const subscriptionType = user.subscription_type || "N/A";
      const createdAt = new Date(user.created_at).toLocaleDateString();

      return [
        businessName,
        userId,
        email,
        contactNumber,
        location,
        subscriptionType,
        createdAt,
      ].join(",");
    });

    const csvContent = [csvHeaders.join(","), ...csvRows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "fs-users_export.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Pagination handlers
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Helper to handle direct page number clicks
  const handlePageClick = (pageNum) => {
    setPage(pageNum);
  };

  // Helper function to display pagination numbers with dots
  const getPaginationNumbers = () => {
    const paginationNumbers = [];
    const visiblePages = 5; // Limit the number of visible page buttons

    if (totalPages <= visiblePages) {
      // Show all pages if total is less than or equal to 5
      for (let i = 1; i <= totalPages; i++) {
        paginationNumbers.push(i);
      }
    } else {
      // Show pagination with dots
      if (page <= 3) {
        paginationNumbers.push(1, 2, 3, 4, "...", totalPages);
      } else if (page > totalPages - 3) {
        paginationNumbers.push(1, "...", totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        paginationNumbers.push(1, "...", page - 1, page, page + 1, "...", totalPages);
      }
    }

    return paginationNumbers;
  };

  // const handleExportCsv = () => {
  //   setExportCsv("excel");
  //   exportrefetchcsv();
  //   setIsExporting(true);
  // };
  const handleExportCsv = async () => {
    try {
      const token = localStorage.getItem("AdminAccessToken")

      const response = await fetch(`${process.env.REACT_APP_CORE_SERVER}external/fs-branches/?subscription_type=${selectedStatus}&search=${searchTerm}&export=excel`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download the Excel file');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'food_list.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading the Excel file:', error);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setIsDropdownOpen(false);
    console.log("Selected status:", status);
    refetch();
    exportrefetchcsv();
  };
  const handleresetStatus = () => {
    setSearchTerm("");
    setIsDropdownOpen(false);
  };

  return (
    <div>
      <h3>Listing Management</h3>
      <div className="table-wrapper-div">
        <div className="table-head-with-filter">
          <Row>
            <Col xs={12} md={3} lg={3} sm={12}>
              <h5>Foodservice</h5>
              <p className="count">{`${totalListings} Listings`}</p>
            </Col>
            <Col xs={12} md={9} lg={9} sm={12}>
              <div className="search-div">
                <div className="form-control-input">
                  <input
                    type="text"
                    placeholder="Email/ User ID"
                    value={searchTerm}
                    onChange={handleSearchChange} // Update search term
                  />
                  <img src={SearchImg} alt="Search" className="search-img" />
                </div>
                <div className="filter-main-div">
                  <button className="filter-btn" onClick={toggleDropdown}>
                    <img src={FilterImg} alt="Filter" /> Filters
                  </button>
                  {isDropdownOpen && (
                    <div className="dropdown-menu-filter">
                      <p className="reset-filter text-end" onClick={handleresetStatus}>Reset</p>
                    
                      <div onClick={() => handleStatusClick("pro")} className="dropdown-item-status">Pro</div>
                     

                      <div onClick={() => handleStatusClick("unlisted")} className="dropdown-item-status">Unlisted</div>
                    </div>
                  )}
                </div>
                <button className="primary-btn" onClick={handleExportCsv}>
                  Export
                </button>
              </div>
            </Col>
          </Row>
        </div>

        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Business Name</th>
                <th scope="col">Location</th>
                <th scope="col">Contact Info</th>
                <th scope="col">Added On</th>
                <th scope="col">Status</th>
                <th scope="col">Subscription</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {usersData.map((user) => (
                <tr key={user.id}>
                  <td className="clr-bold">
                    <div className="d-flex">
                      {/* <span className="yellow-span-text">
                        <img src={OrImg} alt="OrImg" />
                      </span> */}
                      {user.business_name || "N/A"}
                    </div>
                  </td>
                  <td>{user.location || "N/A"}</td>
                  <td>
                    {user.email || "N/A"} <br />
                    {user.contact_number || "N/A"}
                  </td>
                  <td>{new Date(user.created_at).toLocaleDateString()}</td>
                  <td>
                    <button
                      className={`status-btn ${user?.subscription_status === "active"
                          ? "approved-status"
                          : "pending-status"
                        }`}
                    >
                      <p
                        className={
                          user?.subscription_status === "active"
                            ? "green-circle"
                            : "red-circle"
                        }
                      ></p>
                      <span>{user?.subscription_status || "Inactive"}</span>
                    </button>
                  </td>
                  <td>
                    <span className="pro-text">
                      {user.subscription_type || "N/A"}
                    </span>
                  </td>
                  <td className="text-end">
                    <Link to={`/user-food-location-details/${user.id}`}>
                      <button className="btn-outline">View</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination buttons */}
          <div className="prev-next-btn text-end">
            <button
              type="button"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={!prevPage}
            >
              Previous
            </button>
            <div className="pagination-numbers">
              {getPaginationNumbers().map((pageNum, index) => (
                <button
                  key={index}
                  onClick={() => typeof pageNum === "number" && handlePageClick(pageNum)}
                  className={page === pageNum ? "active" : ""}
                  disabled={pageNum === "..."} // Disable the dots buttons
                >
                  {pageNum}
                </button>
              ))}
            </div>

            <button
              type="button"
              onClick={() => setCurrentPage((prev) => prev + 1)}
              disabled={!nextPage}
            >
              Next
            </button>
            <span className="page-count">
              {` Page ${currentPage} of ${totalPages} `}
            </span>
          </div>
          <br /><br />
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
