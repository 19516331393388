import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_SECONDARY_SERVER;

export const secondaryapi = createApi({
  reducerPath: 'articleapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('AdminAccessToken');
      const noAuthEndpoints = ['submitLogin'];

      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  // tagTypes: ['Materials'], // Add tagTypes for cache management
  endpoints: (builder) => ({
    getAllmaterials: builder.query({
      query: () => `api/admin/list-business-materials`,
    }),
    getBusinessmaterialsubcategory: builder.query({
      query: (id) => `api/admin/list-subcategories/${id}/subcategories`,
    }),
    getBusinessmaterialtype: builder.query({
      query: ({ id, mid }) => `api/admin/business-material/${mid}/subcategory/${id}/material-types`,
    }), 
    getBusinessmaterialview: builder.query({
      query: ({ businessmaterial_id, subcategory_id, materialtype_id }) => `api/admin/business-material/${businessmaterial_id}/subcategories/${subcategory_id}/records/materialtype/${materialtype_id}`,
    }),    
    deleteBusinessmaterial: builder.mutation({
      query: ({ businessmaterial_id }) => ({
        url: `api/admin/records/${businessmaterial_id}`,
        method: 'DELETE',
      }),
    }),
    addBusinessmaterials: builder.mutation({
      query: (newPost) => ({
        url: 'api/admin/add-material-records',
        method: 'POST',
        body: newPost,
      }),
    }),
    getBusinessmaterialsofcs: builder.query({
      query: ({id}) => `api/admin/cafe-business-material/with-saved?directory_listing_id=${id}`,
    }), 
    getHuddleStatistics: builder.query({
      query: () => `api/admin/huddle-sessions-and-records/count`,
    }), 
    getMentorship: builder.query({
      query: () => `api/admin/get-huddle-sessions`,
    }), 
    gethuddlesessiondetails: builder.query({
      query: (id) => `api/admin/huddle-session/${id}`,
    }), 
    addHuddlesession: builder.mutation({
      query: (newPost) => ({
        url: 'api/admin/create-huddle-sessions',
        method: 'POST',
        body: newPost,
      }),
    }),
    getinvitedusers: builder.query({
      // query: (id) => `api/admin/invited-users?status=invited`,
      query: ({id,activeButton}) => `api/admin/invited-users/${id}?status=${activeButton}`,
    }),
    sendinvitation: builder.mutation({
      query: (newPost) => ({
        url: 'api/admin/send-invitations',
        method: 'POST',
        body: newPost, // Send newPost directly as the body
      }),
    }),
    getGroupsession: builder.query({
      query: () => `api/admin/list-session`,
    }), 
    gpamountedit: builder.mutation({
      query: (postData) => ({
        url: `api/admin/sessions/1/update-price`, 
        method: 'POST',  
        body:  postData ,  
      }),
    }),

    getAllMaterial: builder.query({
      query: ({userId, branchId}) => `api/cafe-business-materials/with-saved?user_id=${userId}&directory_listing_id=${branchId}`,  
    }),

    getBusinessMaterials: builder.query({
      query: () => `api/business-materials`,
    }),
    getSubCategorybyMaterialId: builder.query({
      query: ({id}) => `api/get-material-subcategory/${id}`,
    }),
    getAllRecordsbyMaterialId: builder.query({
      query: ({businessmaterialid, subcategory_id}) => `api/business-materials/${businessmaterialid}/subcategories/${subcategory_id}/records`,
    }),
    getMaterialType: builder.query({
      query: () => 'api/all-material-types'
    }),
    getMaterialTypebyCategoryId: builder.query({
      query: ({businessmaterialid, subcategory_id}) => `api/material-types/${businessmaterialid}/${subcategory_id}`
    }),
  
    getRecordsById: builder.query({
      query: ({businessmaterialid, subcategory_id,materialtypeid}) => `api/business-materials/${businessmaterialid}/subcategories/${subcategory_id}/records/material-type/${materialtypeid}`
    }),
  
    saveRecords:builder.mutation({
      query: (postData) => ({
        url: `api/user-record/save`,
        method: 'POST',
        body: postData,
      }),
    }),

    listSavedMaterialRecords: builder.query({
      query: ({userId,businessmaterialid, subcategory_id,directoryListingId,subcsriptionType,status}) => `api/list-records/${userId}/${businessmaterialid}/${subcategory_id}/${status}?directory_listing_id=${directoryListingId}`
    }),

    // listSavedMaterialRecords: builder.query({
    //   query: ({userId,businessmaterialid, subcategory_id,directoryListingId,subcsriptionType,status}) => `api/list-records/${userId}/${businessmaterialid}/${subcategory_id}/1?directory_listing_id=${directoryListingId}`
    // }),
    
    listUnSavedMaterialRecords: builder.mutation({
      query: ({ userId, record_id, directory_listing_id, subscription_type }) => ({
        url: `api/unsave-record/${userId}/${record_id}?directory_listing_id=${directory_listing_id}&subscription_type=${subscription_type}`,
        method: 'DELETE',
      }),
    }),

    saveImportInvite:builder.mutation({
      query: (postData) => ({
        url: `api/admin/invitations/import`,
        method: 'POST',
        body: postData,
      }),
    }),

    saveImportInvite:builder.mutation({
      query: (postData) => ({
        url: `api/admin/invitations/import`,
        method: 'POST',
        body: postData,
      }),
    }),

    addBusinessMaterialCategoty :builder.mutation({
      query: (postData) => ({
        url: `api/admin/add-business-materials`,
        method: 'POST',
        body: postData,
      }),
    }),
    addBusinessMaterialSubCategoty :builder.mutation({
      query: (postData) => ({
        url: `api/admin/add-materials-subcategory`,
        method: 'POST',
        body: postData,
      }),
    }),
  addBusinessMaterialTYpe:builder.mutation({
    query: (postData) => ({
      url: `api/admin/add-material-types`,
      method: 'POST',
      body: postData,
    }),
  }),
    uploadBusinessMaterial:builder.mutation({
      query: (postData) => ({
        url: `api/admin/add-material-records`,
        method: 'POST',
        body: postData,
      }),
    }),

    getMatrialTYpeAdmin: builder.query({
      query: () => `api/admin/list-material-types`
    }),
    RemoveInvitationbyId:builder.mutation({
      query: (id) => ({
        url: `api/admin/invitations/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteUserbyRecordId: builder.mutation({
      query: (user_id) => ({
        url: `api/delete-saved-records-invitations/${user_id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { 
  useGetAllmaterialsQuery,useGetBusinessmaterialsubcategoryQuery,
  useGetBusinessmaterialtypeQuery, useGetBusinessmaterialviewQuery,
  useDeleteBusinessmaterialMutation, useAddBusinessmaterialsMutation,
  useGetBusinessmaterialsofcsQuery, useGetHuddleStatisticsQuery,
  useGetMentorshipQuery,useGethuddlesessiondetailsQuery, 
  useAddHuddlesessionMutation,useGetinvitedusersQuery,
  useSendinvitationMutation,useGetGroupsessionQuery,
  useGpamounteditMutation,
  useGetSubCategorybyMaterialIdQuery,
  useListUnSavedMaterialRecordsMutation,
  useListSavedMaterialRecordsQuery,useSaveImportInviteMutation,
  useAddBusinessMaterialCategotyMutation,
  useAddBusinessMaterialSubCategotyMutation,
  useAddBusinessMaterialTYpeMutation,
  useGetBusinessMaterialsQuery,
useUploadBusinessMaterialMutation,
useGetMatrialTYpeAdminQuery,
useRemoveInvitationbyIdMutation,
useDeleteUserbyRecordIdMutation
  } = secondaryapi;
