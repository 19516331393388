import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import "../Styles/RoleManagement.css";
import { useRegisterpasswordbyadminMutation } from "../Services/AdminApi";
import Swal from 'sweetalert2';

function RoleManagementRegister({ show, handleCloseRegisterModal, roleId, email, refetch }) {
  const [formData, setFormData] = useState({
    name: "",
    email: email || "", // Initialize with the email prop if provided
    newpassword: "",
    confirmpassword: ""
  });

  const [registerPassword, { isLoading }] = useRegisterpasswordbyadminMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Construct payload
    const payload = {
      name: formData.name,
      email: formData.email,
      password: formData.newpassword,
      password_confirmation: formData.confirmpassword,
      role_id: roleId
    };

    try {
      // Trigger mutation and await response
      const response = await registerPassword(payload).unwrap();
      
      if (response.success) {
        // Clear the form
        setFormData({
          name: "",
          email: email || "",
          newpassword: "",
          confirmpassword: ""
        });
        // alert("Registered Successfully..!")
        // Refetch data upon successful insertion
        Swal.fire({
          icon: 'success',
          title: 'Submission Successful!',
          text: 'Registered Successfully..!',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
      }).then(() => {
        setFormData({ email: '', newpassword: '', confirmpassword: '', name:'' });  // Reset form fields
        refetch();
        
        // Close modal after submission
        handleCloseRegisterModal();
      });
      }
    } catch (error) {
      // alert("Something went wrong, Please try again later");
      Swal.fire({
        icon: 'error',
        title: 'Submission failed!',
        text: 'Something went wrong, Please try again later',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
  };

  const handleCloseAddNewModal = () => {
    handleCloseRegisterModal();
    setFormData({
      name: "",
      email: email || "",
      newpassword: "",
      confirmpassword: ""
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleCloseAddNewModal}
        backdrop="true"
        dialogClassName="add-new-modal"
      >
        <Modal.Body>
          <h4>Register</h4>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Name <span className="red-star">*</span></label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    placeholder="Name"
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Email ID <span className="red-star">*</span></label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    placeholder="Email ID"
                    onChange={handleChange}
                    required
                    
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>New password <span className="red-star">*</span></label>
                  <input
                    type="password"
                    name="newpassword"
                    value={formData.newpassword}
                    placeholder="New password"
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>Confirm password <span className="red-star">*</span></label>
                  <input
                    type="password"
                    name="confirmpassword"
                    value={formData.confirmpassword}
                    placeholder="Confirm password"
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <div className="btn-action">
                <button type="button" className="btn-outline-black" onClick={handleCloseAddNewModal}>Cancel</button>
                <button type="submit" className="btn-primary" disabled={isLoading}>
                  {isLoading ? "Registering..." : "Register"}
                </button>
              </div>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RoleManagementRegister;
