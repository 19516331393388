import { useState } from "react";
import BackIcon from "../Assets/Images/back-icon.svg";
import { useNavigate, Link } from "react-router-dom";
import "../Styles/CommunityChatView.css";
import DefaultImg from "../Assets/Images/default-profile-img.png";
import ActionImg from "../Assets/Images/action_icon.svg";
import { useGetUserchatdetailsQuery, useDeletePostMutation } from "../Services/CommunityApi";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function CommunityChatView() {
  const { id } = useParams();
  const { data, isLoading, error, refetch } = useGetUserchatdetailsQuery(id); // Fetching data from backend
  const [deletePost, { isLoading: isDeleting }] = useDeletePostMutation(); // Track delete loading state
  const [deletingPostId, setDeletingPostId] = useState(null); // Track which post is being deleted
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const [showDelete, setShowDelete] = useState(null); // Store the ID of the post to delete

  const handleImageClick = (postId) => {
    setShowDelete((prev) => (prev === postId ? null : postId)); // Toggle the delete div for the specific post
  };

  // const handleDeletePost = async (postId) => {
  //   // Confirmation dialog before deletion
  //   const confirmDelete = window.confirm("Are you sure you want to delete this post?");
  //   if (confirmDelete) {
  //     setDeletingPostId(postId); // Set the post ID being deleted
  //     await deletePost(postId); // Call the delete mutation
  //     refetch(); // Refetch data after deletion
  //     setDeletingPostId(null); // Reset the deleting post ID
  //   }
  // };
  const handleDeletePost = async (postId) => {
    // Confirmation dialog before deletion
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this post?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
      setDeletingPostId(postId); // Set the post ID being deleted
  
      try {
        await deletePost(postId); // Call the delete mutation
        await refetch(); // Refetch data after deletion
  
        // Show a success popup
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'post has been deleted.',
          confirmButtonText: 'OK',
        });
      } catch (error) {
        console.error("Failed to delete the post:", error);
        // Show an error popup
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Failed to delete the post. Please try again later.',
          confirmButtonText: 'OK',
        });
      } finally {
        setDeletingPostId(null); // Reset the deleting post ID
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching chat details</div>;
  }

  const posts = data?.posts || [];

  return (
    <div className="comminity-chat-view">
      <div className="back-page-icon">
        <p>
          <img src={BackIcon} alt="Back" onClick={handleBackClick} /> {data?.category_name}
        </p>
        <div>
          {posts.map((post) => (
            <div key={post.post_id} className="chatlist-view-card">
              <div className="d-flex align-items-space-between">
                <h5>{post.post_title}</h5>
                <div className="delete-icon-div">
                  <img
                    src={ActionImg}
                    alt="ActionImg"
                    className="actionimg"
                    onClick={() => handleImageClick(post.post_id)}
                    style={{ cursor: "pointer" }}
                  />
                  {showDelete === post.post_id && (
                    <div className="delete-icon">
                      {deletingPostId === post.post_id ? (
                        <span>Deleting...</span> // Show "Deleting..." text
                      ) : (
                        <img
                          src="/path_to_delete_icon.png"
                          alt="Delete"
                          onClick={() => handleDeletePost(post.post_id)} // Call delete handler
                          style={{ cursor: "pointer" }} // Style for clickable delete icon
                        />
                      )}
                      {/* Replace with your delete icon */}
                    </div>
                  )}
                </div>
              </div>
              <p className="description">{post.post_content}</p>
              <hr />
              <div className="d-flex align-items-space-between">
                <div className="d-flex">
                  <img
                    src={post.author_profile_image || DefaultImg}
                    alt="DefaultImg"
                    width={40}
                    height={40}
                  />
                  <div className="mal-10">
                    <p className="user-name">{post.author_name}</p>
                    <p className="chat-date">{post.time_since_posted}</p>
                  </div>
                </div>
                <div className="d-flex">
                  <p className="reply-btn" onClick={() => handleDeletePost(post.post_id)} disabled={isDeleting}>
                    {deletingPostId === post.post_id ? "Deleting..." : "Delete"}
                  </p>
                  <Link to={`/chatlist-reply/${post.post_id}`}>
                    <p className="reply-btn">Reply</p>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CommunityChatView;
