import { useState, useEffect } from "react";
import BackIcon from "../Assets/Images/back-icon.svg";
import { useNavigate } from "react-router-dom";
import "../Styles/Noticeboard.css";
import { useGetNoticebaorddetailsQuery, useApproveNoticeMutation } from "../Services/CommunityApi";
import { useParams } from "react-router-dom";
import moment from 'moment';
import Swal from 'sweetalert2';

function NoticeboardView() {
  const { id } = useParams();
  const { data, isLoading, error, refetch } = useGetNoticebaorddetailsQuery(id);
  
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY');
  }

  const [approveNotice] = useApproveNoticeMutation();
  const [isApproving, setIsApproving] = useState(false); // To track loading state for approving
  const [isRejecting, setIsRejecting] = useState(false); // To track loading state for rejecting

  // Handle approve action
  const handleApproveClick = async () => {
    setIsApproving(true); // Set loading state to true when button is clicked
    try {
      const response = await approveNotice({ id, status: 'approved' });
      if (response?.data) {
        // alert("Notice approved successfully!");
        Swal.fire({
          icon: 'success',
          title: 'Submission Successful!',
          text: 'Notice approved successfully!',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
        refetch(); // Optionally refetch data if needed
      });
      } else {
        console.error("Error in approving the notice:", response.error);
      }
    } catch (error) {
      console.error("Error in approving the notice:", error);
    } finally {
      setIsApproving(false); // Reset loading state when request is done
    }
  };

  // Handle reject action
  const handleRejectClick = async () => {
    setIsRejecting(true); // Set loading state to true for rejecting
    try {
      const response = await approveNotice({ id, status: 'rejected' }); // Assuming 'rejected' status update
      if (response?.data) {
        // alert("Notice rejected successfully!");
        Swal.fire({
          icon: 'success',
          title: 'Submission Successful!',
          text: 'Notice rejected successfully!',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
        refetch(); 
      });
      } else {
        console.error("Error in rejecting the notice:", response.error);
      }
    } catch (error) {
      console.error("Error in rejecting the notice:", error);
    } finally {
      setIsRejecting(false); // Reset loading state when request is done
    }
  };

  // Conditional rendering based on status
  const renderButtons = () => {
    if (data?.status === "pending") {
      // Show both Approve and Reject buttons
      return (
        <>
          <button 
            className="btn-outline" 
            onClick={handleRejectClick}
            disabled={isRejecting}
          >
            {isRejecting ? 'Rejecting...' : 'Reject'}
          </button>
          <button 
            className="btn-primary" 
            onClick={handleApproveClick}
            disabled={isApproving}
          >
            {isApproving ? 'Approving...' : 'Approve'}
          </button>
        </>
      );
    } else if (data?.status === "approved") {
      // Show only Reject button
      return (
        <button 
          className="btn-outline" 
          onClick={handleRejectClick}
          disabled={isRejecting}
        >
          {isRejecting ? 'Rejecting...' : 'Reject'}
        </button>
      );
    } else if (data?.status === "rejected") {
      // Show only Approve button
      return (
        <button 
          className="btn-primary" 
          onClick={handleApproveClick}
          disabled={isApproving}
        >
          {isApproving ? 'Approving...' : 'Approve'}
        </button>
      );
    }
  };

  return (
    <div className="noticeboard-view">
      <div className="back-page-icon">
        <p>
          <img src={BackIcon} alt="" onClick={handleBackClick} /> 
        </p>
      </div>
      <div className="noticeboard-view-card">
        <h5>{data?.cafe_business_name}</h5>
        <p className="category-name">{data?.category_name}</p>
        <p className="description">{data?.content}</p>
        <div className="d-flex align-items-space-between">
          <div>
            <p className="location">
              {data?.location} - <span>{data?.operational_status}</span>
            </p>
            <p className="pin">Pin {data?.post_code}</p>
          </div>
          <div>
            <p className="added_on">Added on {formatDate(data?.created_at)}</p>
          </div>
        </div>
        <hr />
        <div className="d-flex  flex-end">
          {/* <div>
            <p className="view-directory-listing">View directory listing</p>
          </div> */}
          <div>
            {renderButtons()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoticeboardView;
