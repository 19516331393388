import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import "../Styles/RoleManagement.css";
import "../Styles/Artcle.css";
import { useGpamounteditMutation } from "../Services/SecondaryApi";
import Swal from 'sweetalert2';

function RoleManagementRegister({ show, handleCloseEditMOdalgp, refetch }) {
  const [formData, setFormData] = useState({
    amount: "",
  });

  const [submitgpamount, { isLoading, isSuccess, isError }] = useGpamounteditMutation();

  const handleCloseAddNewModal = () => {
    handleCloseEditMOdalgp();
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  let postData = {
    price_type:"lite",
    price:formData.amount,
    is_free:false,
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formData.amount != ""){
    try {
      // Trigger mutation to submit amount
      const response = await submitgpamount(postData).unwrap();
      // alert("Amount updated successfully!");
      Swal.fire({
        icon: 'success',
        title: 'Submission Successful!',
        text: 'Amount updated successfully.',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() => {
        handleCloseEditMOdalgp();
        refetch(); // Call refetch to reload the articles list
      });
      refetch();
      handleCloseEditMOdalgp(); // Close modal after successful submission
    } catch (response) {
      // alert("Amount updation failed");
      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Submission Successful!',
          text: 'Amount updated successfully.',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          handleCloseEditMOdalgp();
          refetch(); // Call refetch to reload the articles list
        });
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Submission Failed',
          text: 'Please enter the amount',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          handleCloseEditMOdalgp();
      refetch();
    });
      }
    }
  }

  else{
    Swal.fire({
      icon: 'error',
      title: 'Submission Failed',
      text: 'Please enter the amount',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
  }
  
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleCloseAddNewModal}
        backdrop="true"
        dialogClassName="add-new-modal memebrship-edit-modal"
      >
        <Modal.Body>
          <h4>Group session</h4>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                    Lite members pricing <span className="red-star">*</span>
                  </label>
                  <br />
                  <input
                    type="number"
                    name="amount"
                    placeholder="Enter your price"
                    value={formData.amount}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className="free-checkbox">
                {/* Optional checkbox for additional options */}
              </Col>
            </Row>
            <Row>
              <div className="btn-action">
                <button
                  type="button"
                  className="btn-outline-black"
                  onClick={handleCloseAddNewModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-primary" disabled={isLoading}>
                  {isLoading ? "Submitting..." : "Confirm"}
                </button>
              </div>
            </Row>
          </form>
          
          {isError && <p className="error-message">Failed to update the price.</p>}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RoleManagementRegister;
