import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import ProfileIMg from "../Assets/Images/default-profile-img.png"
import Notification from "../Assets/Images/notification.svg"
import NotificationText from "../Assets/Images/notification-text.svg"
import SupportImg from "../Assets/Images/support_nav.svg"
import DropdownImg from "../Assets/Images/Dropdown.svg"
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useNotificationMarkAllReadMutation } from "../Services/UsersApi"
const AppNavbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(''); // State to hold selected option

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setShowDropdown(false); // Close dropdown after selecting an option
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/");
    localStorage.clear();
  };

  const [notifications, setNotifications] = useState([]);
  const token = localStorage.getItem('AdminAccessToken');
  const websocketUrl = `wss:/${process.env.REACT_APP_CORE_SERVER_WEBSOCKET}ws/external/custom_normal_admin/portal-notifications/unread-count/?token=${token}`;

  useEffect(() => {
    // Create a new WebSocket connection
    const socket = new WebSocket(websocketUrl);

    // Handle connection open
    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    // Handle incoming messages
    socket.onmessage = (event) => {
      const notification = JSON.parse(event.data);
      console.log("Received notification:", notification);
      setNotifications(notification);
    };

    // Handle connection close
    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Clean up the socket connection on unmount
    return () => {
      socket.close();
    };
  }, [websocketUrl]);
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useNotificationMarkAllReadMutation();

  const handleMarkAllRead = async (e) => {
    try {
      const response = await submitForm().unwrap();
    } catch (err) {
      console.error("Failed to submit form:", err);
    }
  }

  return (
    <Navbar variant="dark" expand="lg" className="dashboard-nav">
      <Navbar.Brand href="#home">Navbar</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Link to="/notification">
            <div className="ecosystem-notification-div">
              <img src={Notification} alt="Notification" className='notification-img' onClick={handleMarkAllRead} />
              {notifications?.unread_count > 0 && <div className="notification-count-cafe">
                {notifications?.unread_count}
              </div>}
            </div>
          </Link>
          {/* <Nav.Link href="#">
            <img src={ProfileIMg} alt="ProfileIMg"/><span>Admin</span>
          </Nav.Link> */}
          <div className="profile-dropdown">
            <Dropdown align="end">
              <Dropdown.Toggle
                as={Nav.Link}
                id="profile-dropdown"
                className="d-flex align-items-center"
              >
                <img
                  src={ProfileIMg}
                  alt="ProfileIMg"
                  width={30}
                  height={30}
                  className="nav-profile-img"
                />
                <span className="ms-2">Admin</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* <Dropdown.Item onClick={handleAccountSettings}>
                  Account Settings
                </Dropdown.Item> */}
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavbar;
