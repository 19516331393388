import React from "react";
import { Row, Col } from "react-bootstrap";
import CardImg from "../Assets/Images/default-placeholder.svg";
import "../Styles/Userdetail.css";
import { useGetMediagalleryfsQuery, useGetMediaGallerybyUserIdfsQuery } from "../Services/UsersApi";
import { useParams, Link, useNavigate } from "react-router-dom";

function BusinessmaterialDetails({ branchId, userId }) {
  const { id } = useParams();
  const { data, isLoading, error, refetch } = useGetMediaGallerybyUserIdfsQuery(userId);

  console.log("userId", userId)
const navigate = useNavigate()
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>No data available: {error.message}</p>;

  return (
    <div className="user-businessmaterial-detail">
  <Row>
  {data && data.length > 0 ? (
    data.map((material) => (
      <Col sm={12} md={4} lg={4} key={material.id}>
        <a href={material.file} target="_blank" rel="noopener noreferrer">
          <div className="card">
            <div className="card-img">
              <img
                src={CardImg}
                alt={material.title}
                className="uploaded-images"
              />
            </div>
            <div className="card-body">
              <p>{material.file_name}</p>
            </div>
          </div>
        </a>
      </Col>
    ))
  ) : (
    <Col xs={12}>
      <p>No Data Found</p>
    </Col>
  )}
</Row>

    </div>
  );
}

export default BusinessmaterialDetails;
