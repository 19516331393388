import React, { useEffect, useState, useRef } from "react";
import "../Styles/EcosystemNotification.css";
import { Row, Col } from "react-bootstrap";
import moment from 'moment';

function Ecosystemnotification() {
  const [notifications, setNotifications] = useState([]);
  const token = localStorage.getItem('AdminAccessToken');
  const websocketUrl = `wss:/${process.env.REACT_APP_CORE_SERVER_WEBSOCKET}ws/external/custom_normal_admin/portal-notifications/?token=${token}`; // Pass the token in the URL


  const [notificationsR, setNotificationsR] = useState([]);
  const socketRefR = useRef(null); // Ref to store WebSocket instance
  const tokenR = localStorage.getItem("AdminAccessToken");
  const websocketUrlR = `wss:/${process.env.REACT_APP_CORE_SERVER_WEBSOCKET}ws/external/custom_normal_admin/portal-notifications/?token=${tokenR}`;

  // Establish WebSocket connection
  useEffect(() => {
    // Create a new WebSocket connection
    socketRefR.current = new WebSocket(websocketUrlR);

    // Handle connection open
    socketRefR.current.onopen = () => {
      console.log("WebSocket connection established");
    };

    // Handle incoming messages
    socketRefR.current.onmessage = (event) => {
      const notificationR = JSON.parse(event.data);

      // Update or append notifications while keeping the correct order
      setNotificationsR((prevNotificationsR) => {
        const existingIndex = prevNotificationsR.findIndex(
          (notif) => notif.id === notificationR.id
        );

        if (existingIndex !== -1) {
          // Update existing notification
          const updatedNotifications = [...prevNotificationsR];
          updatedNotifications[existingIndex] = notificationR;
          return updatedNotifications;
        }

        // Add new notification
        return [...prevNotificationsR, notificationR];
      });
    };

    // Handle connection close
    socketRefR.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Clean up the socket connection on unmount
    return () => {
      if (socketRefR.current) {
        socketRefR.current.close();
      }
    };
  }, [websocketUrlR]); // Reconnect if websocketUrlR changes

  const markAsRead = (notificationId) => {
    const data = {
      action: "mark_as_read",
      notification_id: notificationId,
    };

    if (socketRefR.current && socketRefR.current.readyState === WebSocket.OPEN) {
      socketRefR.current.send(JSON.stringify(data)); // Send action to WebSocket server
      console.log(`Notification ${notificationId} marked as read`);

      // Update `is_read` status locally
      setNotificationsR((prevNotifications) =>
        prevNotifications.map((notif) =>
          notif.id === notificationId ? { ...notif, is_read: true } : notif
        )
      );
    } else {
      console.error("WebSocket is not open. Cannot mark notification as read.");
    }
  };

  useEffect(() => {
    // Mark notifications as read if they are unread
    notificationsR.forEach((notif) => {
      if (!notif.is_read) {
        markAsRead(notif.id);
      }
    });
  }, [notificationsR]); // Run when notifications array changes

  // #########################################################################

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY');
  }

  useEffect(() => {
    // Create a new WebSocket connection
    const socket = new WebSocket(websocketUrl);

    // Handle connection open
    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    // Handle incoming messages
    socket.onmessage = (event) => {
      const notification = JSON.parse(event.data);
      console.log("Received notification:", notification); // Log the received notification
      setNotifications((prevNotifications) => [notification, ...prevNotifications]); // Prepend new notifications
    };

    // Handle connection close
    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Clean up the socket connection on unmount
    return () => {
      socket.close();
    };
  }, [websocketUrl]);
  return (
    <div className="ecosystem-notification">
      <h4>Notifications</h4>
      <p className="sub-head">Stay updated with the latest alerts and messages.</p>
      {notifications.map((notification) => (<div className="notification-list">
        <div className="card">
          <h6>
            {notification.title}
          </h6>
          <p>
            {notification.message}
          </p>
          <div className="text-end">
            <p className="time">({formatDate(notification.created_at ?? "")})</p>
          </div>
        </div>
      </div>))}
    </div>
  );
}

export default Ecosystemnotification;
