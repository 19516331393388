import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_ADMIN_SERVER;

export const adminapi = createApi({
  reducerPath: 'adminapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState, endpointName }) => {
      const token = localStorage.getItem('AdminAccessToken');
      const noAuthEndpoints = ['submitLogin','superAdminLogin'];

      // Add token only if it's not a no-auth endpoint
      if (token && !noAuthEndpoints.includes(endpointName)) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitLogin: builder.mutation({
      query: (newPost) => ({
        url: 'api/admin/login',
        method: 'POST',
        body: newPost,
      }),
    }),
    superAdminLogin: builder.mutation({
      query: (newPost) => ({
        url: 'api/superadmin/superlogin',
        method: 'POST',
        body: newPost,
      }),
    }),
    insertuserbyadmin: builder.mutation({
      query: (postData) => ({
        url: `api/superadmin/user-register`, 
        method: 'POST',  
        body: postData,  
      }),
    }),
    registerpasswordbyadmin: builder.mutation({
      query: (postData) => ({
        url: `api/superadmin/user-register`, 
        method: 'POST',  
        body: postData,  
      }),
    }),
    resetpasswordbyadmin: builder.mutation({
      query: (postData) => ({
        url: `api/superadmin/users-reset-password`, 
        method: 'POST',  
        body: postData,  
      }),
    }),
    refreshtoken: builder.mutation({
      query: (postData) => ({
        url: `api/admin/refresh`, 
        method: 'POST',  
        body: postData,  
      }),
    }),
    getAllcausers: builder.query({
      query: () => `external/ce-user/list`,
    }),
    getAllfsusers: builder.query({
      query: () => `external/fs-user/list/`,
    }),
    getAlltsusers: builder.query({
      query: () => `external/ts-user/list/`,
    }),
    getAlluserrole: builder.query({
      query: () => `api/superadmin/list-roles`,
    }),
  }),
});

export const {
  useSubmitLoginMutation,
  useGetAllcausersQuery,
  useGetAllfsusersQuery,
  useGetAlltsusersQuery,
  useSuperAdminLoginMutation,
  useGetAlluserroleQuery,
  useInsertuserbyadminMutation,
  useResetpasswordbyadminMutation,
  useRegisterpasswordbyadminMutation,
  useRefreshtokenMutation
} = adminapi;
