import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import BackIcon from "../Assets/Images/back-icon.svg";
import "../Styles/HuddleSessionView.css";
import { Row, Col } from "react-bootstrap";
import CalenderIcon from "../Assets/Images/calender-img.svg";
import TimeIcon from "../Assets/Images/time-icon.svg";
import LinkIcon from "../Assets/Images/link-icon.svg";
import { Tabs, Tab } from "react-bootstrap";
import SearchImg from "../Assets/Images/search-img.svg";
import { useParams } from 'react-router-dom';
import { useGethuddlesessiondetailsQuery,useGetinvitedusersQuery, useSendinvitationMutation, useRemoveInvitationbyIdMutation } from "../Services/SecondaryApi";
import { useGetAllcalistingsQuery, useGetSelectAllcalistingsQuery } from "../Services/UsersApi";
import ImportModal from "../Components/ImportHuddleSessionDataModal"
import Swal from 'sweetalert2';

function VIewHuddleSession() {
  const { id } = useParams();
  const { data: sessionDetails, error, refetch } = useGethuddlesessiondetailsQuery(id);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [page, setPage] = useState(1); // Pagination state
  const [activeButton, setActiveButton] = useState("");
  const [filteredListings, setFilteredListings] = useState([]); // Store filtered listings
  const [totalCount, setTotalCount] = useState(0); // Store total count for pagination
  const [nextPageUrl, setNextPageUrl] = useState(null); // For next page URL
  const [previousPageUrl, setPreviousPageUrl] = useState(null); // For previous page URL
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const itemsPerPage = 10; // Define how many items per page
  const { data, refetch: refechAllInvitation } = useGetAllcalistingsQuery({ page, searchTerm });
  const { data:SelectAllData, refetch: refechSelectAllData } = useGetSelectAllcalistingsQuery();
  useEffect(() => {
    if (data) {
      setFilteredListings(data.results);
      setTotalCount(data.count);
      setNextPageUrl(data.next);
      setPreviousPageUrl(data.previous);
      setTotalPages(Math.ceil(data.count / itemsPerPage)); // Calculate total pages based on count
    }
  }, [data]);
  const [removeInvitation, { }] =
    useRemoveInvitationbyIdMutation();

  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }

  // if (error) {
  //   return <p>Error loading data...</p>;
  // }

  const { data: invitedusers, refetch: refetchIvitedUsers } = useGetinvitedusersQuery({ id, activeButton });
  const navigate = useNavigate();
  const [sendInvitation, { isLoading, isSuccess, isError }] = useSendinvitationMutation();

  // Handle checkbox changes
  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  // Handle select all
  const handleSelectAllChange = () => {
    refechSelectAllData();
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(SelectAllData?.map((listing) => listing?.id));
    }
    setSelectAll(!selectAll);
  };

  const handleInviteClick = async (listings) => {
    const invitationData = {
      huddle_session_id: id,
      invitations: listings.map((listing) => ({
        user_id: listing.user,
        business_name: listing.business_name,
        location: listing.location,
        email: listing.email,
        mobile: listing.contact_number,
        plan: listing.subscription_type,
        operational_status: listing.operational_status?.map((status) => status.name).join(" & "),
        directory_listing_id: listing.id,
      }))
    };

    try {
      const response = await sendInvitation(invitationData);
      // alert("Invitation sent successfully:", response);
      Swal.fire({
        text: "Invitation sent successfully",
        icon: "success",
      });
      refechAllInvitation();
      // window.location.reload();
    } catch (error) {
      alert("Error sending invitation:", error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      refechAllInvitation();
    }
  }, [isSuccess])
  // Handle individual invite click for a single listing
  const handleIndividualInvite = (listing) => {
    handleInviteClick([listing]); // Wrap in array to match the batch format
  };

  // Handle multiple invites based on selected rows
  const handleBatchInvite = () => {
    console.log("SelectAllData", SelectAllData)
    const selectedListings = SelectAllData?.filter((listing) => selectedRows.includes(listing.id));
    if (selectedListings.length > 0) {
      handleInviteClick(selectedListings);
    } else {
      alert("No rows selected for invitation.");
    }
  };

  // 

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const handleShowImportMOdal = () => {
    setShowImportModal(true);
  }
  const handleCloseImportMOdal = () => {
    setShowImportModal(false);
  }
  const businesses = [
    {
      id: 1,
      name: "Cafe Startup",
      location: "Gerlachcester",
      email: "Dianne.Bernhard@yahoo.com",
      phone: "+45 1234 5678",
      subPlan: "Pro",
      operationalStatus: "Planning to open",
      status: "Active",
    },
    {
      id: 2,
      name: "Cafe Startup",
      location: "Gerlachcester",
      email: "Dianne.Bernhard@yahoo.com",
      phone: "+45 1234 5678",
      subPlan: "Pro",
      operationalStatus: "Planning to open",
      status: "Active",
    },
    {
      id: 3,
      name: "Cafe Startup",
      location: "Gerlachcester",
      email: "Dianne.Bernhard@yahoo.com",
      phone: "+45 1234 5678",
      subPlan: "Pro",
      operationalStatus: "Planning to open",
      status: "Active",
    },
  ];

  // const handleCheckboxChange = (id) => {
  //   if (selectedRows.includes(id)) {
  //     setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
  //   } else {
  //     setSelectedRows([...selectedRows, id]);
  //   }
  // };

  // // Function to handle select-all checkbox
  // const handleSelectAllChange = () => {
  //   if (selectAll) {
  //     setSelectedRows([]); // Unselect all rows
  //   } else {
  //     const allRowIds = businesses.map((business) => business.id);
  //     setSelectedRows(allRowIds); // Select all rows
  //   }
  //   setSelectAll(!selectAll);
  // };
  const handleCancel = () => {
    setSelectedRows([]); // Unselect all rows
    setSelectAll(false); // Reset the select all checkbox
  };

  // Pagination controls
  const handleNextPage = () => {
    if (nextPageUrl) {
      setPage(page + 1);
    }
  };
  const handlePreviousPage = () => {
    if (previousPageUrl) {
      setPage(Math.max(page - 1, 1));
    }
  };

  // Helper to handle direct page number clicks
  const handlePageClick = (pageNum) => {
    setPage(pageNum);
  };

  // Helper function to display pagination numbers with dots
  const getPaginationNumbers = () => {
    const paginationNumbers = [];
    const visiblePages = 5; // Limit the number of visible page buttons

    if (totalPages <= visiblePages) {
      // Show all pages if total is less than or equal to 5
      for (let i = 1; i <= totalPages; i++) {
        paginationNumbers.push(i);
      }
    } else {
      // Show pagination with dots
      if (page <= 3) {
        paginationNumbers.push(1, 2, 3, 4, "...", totalPages);
      } else if (page > totalPages - 3) {
        paginationNumbers.push(1, "...", totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        paginationNumbers.push(1, "...", page - 1, page, page + 1, "...", totalPages);
      }
    }

    return paginationNumbers;
  };
  const handleRemoveFromInvitation = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure you want to Remove the invitation",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        const response = await removeInvitation(id).unwrap();
        Swal.fire({
          title: "Invitation removed",
          icon: "success",
        });
        // window.location.reload();
      } catch (err) {
        Swal.fire({
          title: "Failed",
          icon: "error",
        });
      }
    }
  }
  return (
    <div>
      <div>
        <p>
          <img src={BackIcon} alt="Back" onClick={handleBackClick} />
        </p>
        <div className="huddle-session-view-details">
          <Row>
            <Col xs={12} sm={12} md={10}>
              <h5>{sessionDetails?.huddle_session?.session_title}</h5>
              <p className="host-name">{sessionDetails?.huddle_session?.host_name}</p>
              <p className="huddlesession-description">
                {sessionDetails?.huddle_session?.description}
              </p>
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
              <button className="btn-primary" onClick={handleShowImportMOdal}>Import</button>
            </Col>
          </Row>
          <div className="d-flex">
            <p className="time-text">
              <img src={CalenderIcon} />
              {sessionDetails?.huddle_session?.formatted_date_time}
            </p>
            <p className="time-text">
              <img src={TimeIcon} />
              {sessionDetails?.huddle_session?.formatted_date_time}
            </p>
            <p className="time-text">
              <img src={LinkIcon} />
              {sessionDetails?.huddle_session?.meeting_link}
            </p>
          </div>
          <div className="huddlesession-tabs">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Invited listings">
                <div>
                  <div className="table-head-with-filter">
                    <Row>
                      <Col xs={12} md={8} lg={8} sm={12}>
                        <div className="view-user-detail">
                          <div className="button-div">
                            <button
                              className={`button ${activeButton === "" ? "active" : ""
                                }`}
                              onClick={() => handleClick("")}
                            >
                              All ({invitedusers?.invitations?.length})
                            </button>
                            <button
                              className={`button ${activeButton === "approved" ? "active" : ""
                                }`}
                              onClick={() => handleClick("approved")}
                            >
                              Approved
                            </button>
                            <button
                              className={`button ${activeButton === "invited" ? "active" : ""
                                }`}
                              onClick={() => handleClick("invited")}
                            >
                              Invited
                            </button>

                            <button
                              className={`button ${activeButton === "declined" ? "active" : ""
                                }`}
                              onClick={() => handleClick("declined")}
                            >
                              Declined
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={4} lg={4} sm={12}>
                        <div className="search-div">
                          <div className="form-control-input">
                            <input
                              type="text"
                              placeholder="Email/ Business Name"
                            />
                            <img
                              src={SearchImg}
                              alt="Search"
                              className="search-img"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="table-div">

                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Business name</th>
                          <th scope="col">Location</th>
                          <th scope="col">Contact info</th>
                          <th>Sub plan</th>
                          <th>Operational status</th>
                          <th>Status</th>
                          <th className="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {invitedusers?.invitations?.map((invited) => (
                          <tr key={invited.id}>
                            <td className="clr-bold">{invited.business_name}</td>
                            <td>{invited.location}</td>
                            <td>
                              {invited.email} <br />
                              {invited.mobile}
                            </td>
                            <td>
                              <span className="pro-text">{invited.plan}</span>
                            </td>
                            <td>{invited.operational_status}</td>
                            <td>
                              <button className="approved-status">
                                <p className={`status-circle ${invited.status === "invited" ? "yellow-circle" : "green-circle"}`}></p>
                                <span>
                                  {invited.status === "invited"
                                    ? "Invited"
                                    : invited.status === "approved"
                                      ? "Approved"
                                      : invited.status === "declined"
                                        ? "Declined"
                                        : ""}
                                </span>
                              </button>
                            </td>
                            <td className="text-center">
                              <button className="btn-outline" onClick={() => handleRemoveFromInvitation(invited.id)}>Remove</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="All listings">
                <div>
                  {/* Table and other UI elements */}
                  <div className="table-div">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAllChange}
                            />
                          </th>
                          <th scope="col">Business name</th>
                          <th scope="col">Location</th>
                          <th scope="col">Contact info</th>
                          <th>Sub plan</th>
                          <th>Operational status</th>
                          <th>Status</th>
                          <th className="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.results?.map((listing) => (
                          <tr key={listing?.id}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(listing?.id)}
                                onChange={() => handleCheckboxChange(listing?.id)}
                              />
                            </td>
                            <td className="clr-bold">{listing?.business_name || "N/A"}</td>
                            <td>{listing?.location || "N/A"}</td>
                            <td>
                              {listing?.email || "N/A"} <br />
                              {listing?.contact_number || "N/A"}
                            </td>
                            <td>
                              <span className="pro-text">{listing?.subscription_type}</span>
                            </td>
                            <td>
                              {listing?.operational_status.map((status) => (
                                <p key={status.name}>&bull; {status.name || "N/A"}</p>
                              ))}
                            </td>
                            {/* <td>
                              <button
                                className={`status-btn ${listing?.subscription_status === "active" ? "approved-status" : "pending-status"}`}
                              >
                                <p className={listing?.subscription_status === "active" ? "green-circle" : "gray-circle"}></p>
                                <span>{listing?.subscription_status || "Inactive"}</span>
                              </button>
                            </td> */}

                            <td>
                              <button
                                className={`status-btn ${listing?.is_active ? "approved-status" : "pending-status"}`}
                              >
                                <p className={listing?.is_active ? "green-circle" : "red-circle"}></p>
                                {/* <span>{listing?.is_active || "Inactive"}</span> */}
                              {listing?.is_active ?(<span>Active</span>):(<span>Inactive</span>)}
                              </button>
                            </td>

                            <td className="text-center">
                              <button className="btn-outline" onClick={() => handleIndividualInvite(listing)}>
                                Invite
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="prev-next-btn text-end">
                      <button
                        type="button"
                        onClick={handlePreviousPage}
                        disabled={page === 1}
                        className={page === 1 ? "disabled" : ""}
                      >
                        Previous
                      </button>

                      {/* Pagination Numbers with Dots */}
                      <div className="pagination-numbers">
                        {getPaginationNumbers().map((pageNum, index) => (
                          <button
                            key={index}
                            onClick={() => typeof pageNum === "number" && handlePageClick(pageNum)}
                            className={page === pageNum ? "active" : ""}
                            disabled={pageNum === "..."} // Disable the dots buttons
                          >
                            {pageNum}
                          </button>
                        ))}
                      </div>

                      <button
                        type="button"
                        onClick={handleNextPage}
                        disabled={!nextPageUrl}
                        className={!nextPageUrl ? "disabled" : ""}
                      >
                        Next
                      </button>
                      <p>{`Page ${page} of ${totalPages}`}</p>
                    </div>
                    <br /><br />
                  </div>
                </div>
                {/* Selected count and bulk invite button */}
                <div className="selected-count">
                  <Row>
                    <Col sm={12} lg={6} md={6}>
                      {selectedRows.length > 0 ? (
                        <>
                          <p>Selected</p>
                          <p>{selectedRows.length}</p>
                        </>
                      ) : (
                        <p>No rows selected</p>
                      )}
                    </Col>
                    <Col sm={12} lg={6} md={6} className="text-end">
                      <div className="invite-buttons">
                        <button className="btn-outline-black" onClick={handleCancel}>Cancel</button>
                        <button className="btn-primary" onClick={handleBatchInvite}>{isLoading ? "Submitting" : "Invite"}</button>
                      </div>
                    </Col>
                  </Row>
                </div>

              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <ImportModal show={showImportModal} handleCloseImportMOdal={handleCloseImportMOdal} refetch={refetchIvitedUsers} sesssionTitle={sessionDetails?.huddle_session?.session_title} huddle_session_id={sessionDetails?.huddle_session?.id} />
    </div>
  );
}

export default VIewHuddleSession;
