import React, { useState } from "react";
import SearchImg from "../Assets/Images/search-img.svg";
import FilterImg from "../Assets/Images/Filters lines.svg";
import { useGetMediagalleryfsQuery, useApproveFsmediaGalleryMutation } from "../Services/UsersApi";
import "../Styles/MediaGallery.css";
import Swal from 'sweetalert2';

function MediaGallery() {
  const [approveFsmediaGallery] = useApproveFsmediaGalleryMutation(); 
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
    // State to keep track of search term
    const [searchTerm, setSearchTerm] = useState("");
  // State to keep track of which item is loading
  const [loadingItem, setLoadingItem] = useState(null);
  const { data, isLoading, error, refetch } = useGetMediagalleryfsQuery({selectedStatus, searchTerm});



  const handleApprove = async (id) => {
    try {
      setLoadingItem(id); // Set the current item as loading
      await approveFsmediaGallery({ id, status: "approved" }).unwrap();
      refetch();
      // alert("Media approved successfully!");
      Swal.fire({
        icon: 'success',
        title: 'Submission Successful!',
        text: 'Media approved successfully!',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
    });
    } catch (err) {
      console.error("Failed to approve the media:", err);
    } finally {
      setLoadingItem(null); // Reset loading state after action completes
    }
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleReject = async (id) => {
    try {
      setLoadingItem(id); // Set the current item as loading
      await approveFsmediaGallery({ id, status: "rejected" }).unwrap();
      refetch();
      // alert("Media rejected successfully!");
      Swal.fire({
        icon: 'success',
        title: 'Submission Successful!',
        text: 'Media rejected successfully!',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
    })
    } catch (err) {
      console.error("Failed to reject the media:", err);
    } finally {
      setLoadingItem(null); // Reset loading state after action completes
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    refetch();
  };

  // Filter media items based on the search term
  // const filteredData = data?.filter((item) =>
  //   item.user.username.toLowerCase().includes(searchTerm) ||
  //   item.branch.branchname.toLowerCase().includes(searchTerm) ||
  //   item.file_type.toLowerCase().includes(searchTerm)
  // );

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>No data available: {error.message}</p>;


  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setIsDropdownOpen(false); 
    console.log("Selected status:", status); 
    refetch();
  };

  return (
    <div>
      <h3>Media Gallery Foodservice</h3>
      <div className="table-wrapper-div">
        <div className="search-wrapper">
          <div className="search-div">
            <div className="form-control-input">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange} // Update search term
              />
              <img src={SearchImg} alt="search-icon" className="search-img" />
            </div>
            {/* <button className="filter-btn">
              <img src={FilterImg} alt="filter-icon" /> Filters
            </button> */}
               <div className="filter-main-div">
                <button className="filter-btn" onClick={toggleDropdown}>
                  <img src={FilterImg} alt="Filter" /> Filters
                </button>
                {isDropdownOpen && (
              <div className="dropdown-menu-filter">
                <p className="reset-filter text-end">Reset</p>
                <div onClick={() => handleStatusClick("pending")} className="dropdown-item-status">Pending</div>
                <div onClick={() => handleStatusClick("approved")} className="dropdown-item-status">Approved</div>
                <div onClick={() => handleStatusClick("rejected")} className="dropdown-item-status">Rejected</div>
              </div>
            )}
            </div>
          </div>
        </div>

        <div className="table-div">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Name</th>
                <th scope="col">Business name</th>
                <th scope="col">Location</th>
                <th scope="col">User type</th>
                <th scope="col">Added on</th>
                <th>Status</th>
                <th className="text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={item.id}>
                  <td className="clr-bold">{item.file_type?.toUpperCase()}</td>
                  <td>{item.user.username}</td>
                  <td className="clr-bold">{item.branch?.business_name}</td>
                  <td>{item.branch?.location}</td>
                  <td>{item.user.is_food_service_user ? "Foodservice" : "Other"}</td>
                  <td>{new Date(item.created_at).toLocaleDateString()}</td>
                  <td className="d-flex">
                    <button className={item.status === "approved" ? "approved-status" : "pending-status"}>
                      <p className={item.status === "approved" ? "green-circle" : "yellow-circle"}></p>
                      <span>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</span>
                    </button>
                  </td>
                  <td className="text-end">
                    <a href={item.file} target="_blank" rel="noopener noreferrer">
                      <button className="btn-outline">View</button>
                    </a>
                    {item.status === "pending" || item.status === "rejected" ? (
                      <button
                        className="btn-outline ml-15"
                        onClick={() => handleApprove(item.id)} // Approve action
                        disabled={loadingItem === item.id} // Disable when loading
                      >
                        {loadingItem === item.id ? "Approving..." : "Approve"}
                      </button>
                    ) : null}
                    {item.status === "pending" || item.status === "approved" ? (
                      <button
                        className="btn-outline ml-15"
                        onClick={() => handleReject(item.id)} // Reject action
                        disabled={loadingItem === item.id} // Disable when loading
                      >
                        {loadingItem === item.id ? "Rejecting..." : "Reject"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MediaGallery;
